import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ControlSize } from '@common/classes/control-size';

const noop = () => { };

@Component({
    selector: 'app-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: CheckboxComponent, multi: true }
    ]
})
export class CheckboxComponent implements ControlValueAccessor {
    @Input() size: ControlSize = ControlSize.large;
    @Input() color = 'primary';
    @Input() disabled = false;
    @Input() label;
    @Input() click;

    private _value = false;
    private _disabled = false;
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    internalClick = () => {
        if (!this.disabled) {
            if (this.click) {
                this.value = this.click();
            } else {
                this.value = this.value === true ? false : true;
            }
        }
    };

    get value(): any {
        return this._value;
    }

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this.onChangeCallback(v);
        }
    }

    constructor() {

    }

    onBlur() {
        this.onTouchedCallback();
    }

    writeValue(value: boolean) {
        if (value !== this._value) {
            this._value = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this._disabled = isDisabled;
    }
}
