import { CqrsService } from '@common/services/cqrs.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-warehouse-documents-tab',
    templateUrl: './warehouse-documents-tab.component.html',
    styleUrls: ['./warehouse-documents-tab.component.css']
})
export class WarehouseDocumentsTabComponent implements OnInit {

    @Input() serviceRequestId: number;
    warehouseDocuments = [];
    constructor(private cqrs: CqrsService) { }

    async ngOnInit() {
        this.warehouseDocuments = await this.cqrs.query('ServiceRequestWarehouseDocuments', { serviceRequestId: this.serviceRequestId });
    }

}
