<div class="modal-header">
    <h4 class="modal-title" translate>Change password</h4>
    <button type="button" class="close" aria-label="Cancel" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row mt-1 mb-2">
        <div class="col">
            <app-control label='Old password' type="password"
                [(ngModel)]="model.oldPassword"></app-control>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <app-control label='New password' type="password"
                [(ngModel)]="model.newPassword"></app-control>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <app-control label='Repeat new password' type="password"
                [(ngModel)]="model.repeatNewPassword"></app-control>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="cancel()" translate>Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!canConfirm()" (click)="confirm()" translate>Confirm</button>
</div>
