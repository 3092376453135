<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Warehouse Documents" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-2">
            <app-control label='Number' type="number" [(ngModel)]="filter.id"></app-control>
        </div>
        <div class="col-2">
            <app-control label='ServiceRequest' type="number" [(ngModel)]="filter.serviceRequestId"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Type' [(ngModel)]="filter.typeId" type="codelist"
                codelist="WarehouseDocumentType"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Terminal Operator' [(ngModel)]="filter.terminalOperatorId" type="codelist"
                codelist="PortFacility"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Warehouse' [(ngModel)]="filter.warehouseId" type="codelist"
                codelist="Warehouse"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col-2">
            <app-control label='Status' [(ngModel)]="filter.statusId" type="codelist"
                codelist="WarehouseDocumentStatus"></app-control>
        </div>
        <div class="col-2">
            <app-control type="datetime" label='Date From' [(ngModel)]="filter.dateFrom"></app-control>
        </div>
        <div class="col-2">
            <app-control type="datetime" label='Date To' [(ngModel)]="filter.dateTo"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <app-grid [data]="gridData" (pageChange)="search()">

            <kendo-grid-column title="{{'Number'|translate}}" field="id">
                <ng-template kendoGridCellTemplate let-item>
                    <div [routerLink]="['/warehouse-document', defaultViewMode, item.id]">
                        <a class="text-primary link-box"> {{item.yearNumber}} </a>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{'Type'|translate}}" field="typeName">
            </kendo-grid-column>
            <kendo-grid-column title="{{'Service Request'|translate}}">
                <ng-template kendoGridCellTemplate let-item>
                    <div [routerLink]="['/service-request', defaultViewMode, item.serviceRequestId]">
                        <a class="text-primary link-box"> {{item.serviceRequestNumber}} </a>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{'Terminal'|translate}}" field="terminalOperatorName">
            </kendo-grid-column>
            <kendo-grid-column title="{{'Date'|translate}}" field="createdDate">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.createdDate | date}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{'Warehouse'|translate}}" field="warehouseName">
            </kendo-grid-column>
            <kendo-grid-column title="{{'Quantity'|translate}}" field="quantity">
            </kendo-grid-column>
            <kendo-grid-column title="{{'Gross Weight'|translate}}" field="grossWeight">
            </kendo-grid-column>
            <kendo-grid-column title="{{'Net Weight'|translate}}" field="netWeight">
            </kendo-grid-column>
            <kendo-grid-column title="{{'Status'|translate}}" field="statusId">
                <ng-template kendoGridCellTemplate let-item>
                    <span [pill]="{ status: item.statusId, module: 'WarehouseDocument' }">
                        {{item.statusName}}
                    </span>
                </ng-template>
            </kendo-grid-column>

        </app-grid>
    </div>
</div>
