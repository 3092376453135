<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Organizations" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col">
            <app-control label="Code" [(ngModel)]="filter.code"></app-control>
        </div>
        <div class="col">
            <app-control label="Name" [(ngModel)]="filter.name"></app-control>
        </div>
        <div class="col">
            <app-control label='Type' [(ngModel)]="filter.typeId" type="codelist"
                codelist="OrganizationType"></app-control>
        </div>
    </div>
</app-filter>
<div class="card mt-3">
    <div class="card-body">
        <app-grid [data]="gridData" (pageChange)="search()">
            <kendo-grid-column title="{{ 'Code' | translate }}" field="code" width="100"></kendo-grid-column>
            <kendo-grid-column title="{{ 'Name' | translate }}" field="name" width="150">
                <ng-template kendoGridCellTemplate let-row>
                    <div [routerLink]="['/administration/organization/view', row.id]">
                        <a class="text-primary link-box"> {{ row.name }} </a>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{ 'Type' | translate }}" field="typeId" width="150">
                <ng-template kendoGridCellTemplate let-row>
                    <span> {{ row.typeId | codelist:'OrganizationType' | async }} </span>
                </ng-template>
            </kendo-grid-column>
        </app-grid>
    </div>
</div>
