import { Injectable } from '@angular/core';
import { Workbook, WorkbookSheetColumn, WorkbookSheetRow } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { CodelistPipe } from '@common/pipes/codelist.pipe';
import { DatePipe } from '@angular/common';
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
    deps: [CodelistPipe]
})
export class ExportService {

    constructor(private codelist: CodelistPipe, private datePipe: DatePipe) { }

    public async transformExcelExport(workbook, data, exportFileName = 'Export', detailData: string = null, detailParams: string[] = null) {
        const { rows, columns } = workbook.sheets[0] as { rows: WorkbookSheetRow[], columns: WorkbookSheetColumn[] };
        const headerOptions = rows[0].cells[0];

        const dtoGridRows = [];
        data.forEach(element => dtoGridRows.push(element));

        if (!detailData) {
            for (let i = 1; i < rows.length; i++) {
                for (let j = 0; j < rows[1].cells.length; j++) {
                    const cell = rows[i].cells[j];
                    this.formatCell(cell);
                }
            }
        }
        else {
            for (let i = rows.length - 1; i >= 1; i--) {
                const detailItems = [];
                data[i - 1][detailData].forEach(el => detailItems.push(el));

                if (detailItems.length <= 0) rows.splice(i + 1, 0, {});
                for (let j = detailItems.length - 1; j >= 0; j--) {
                    let items = detailParams.reduce((obj, key) => {
                        obj[key] = detailItems[j][key];
                        return obj;
                    }, {});

                    items = await Promise.all(Object.keys(items).map(async key => {
                        const codelistKey = this.getCodelist(key);
                        const item = ({ value: items[key] });
                        await this.formatCell(item, codelistKey);
                        return item;
                    }));

                    rows.splice(i + 1, 0, {
                        type: 'data',
                        cells: items as any[]
                    });
                }

                const headerKeys = detailParams.map(key => {
                    key = _.upperFirst(key).replace(/([A-Z])/g, ' $1').trim();
                    if (key.substring(key.length - 2) === 'Id') return key.substring(0, key.length - 2);
                    return key;
                });

                rows.splice(i + 1, 0, {
                    type: 'header',
                    cells: headerKeys.map(item => ({ ...headerOptions, value: item }))
                });

                for (let k = rows[1].cells.length; k >= 0; k--) {
                    const cell = rows[i].cells[k];
                    this.formatCell(cell);
                }

                if (i > 1) {
                    rows.splice(i, 0, {});
                    rows.splice(i + 1, 0, {
                        type: 'header',
                        cells: rows[0].cells
                    });
                }
            }
        }

        // Override default autosize width
        if (rows.length > 1) this.setAutoColumnWidths(rows, columns);

        new Workbook(workbook).toDataURL().then((dataUrl: string) => saveAs(dataUrl, `${exportFileName} ${this.datePipe.transform(new Date(), 'dd-MM-yyyy')}.xlsx`));
    }

    private async formatCell(cell: any, codelistKey: string = null, entityName: string = '') {
        if ([undefined, null].includes(cell)) return;
        // Format codelists
        if (['number', 'string'].includes(typeof cell.value) && codelistKey) {
            let codelistValue = await this.codelist.transform(cell.value, `${entityName}${codelistKey}`);
            if (codelistValue === '-') codelistValue = await this.codelist.transform(cell.value, codelistKey);
            if (codelistValue !== '-') cell.value = codelistValue;
        }
        // Format date
        if (typeof cell.value === 'string' && !!cell.value && !isNaN(Date.parse(cell.value.replace(/\s/g, '')))) {
            cell.value = this.datePipe.transform(cell.value, 'dd-MM-yyyy HH:mm');
        }
        // Format empty array
        else if (typeof cell.value === 'object' && cell.value?.length === 0) {
            cell.value = '';
        }
        // Format boolean
        else if (typeof cell.value === 'boolean') {
            cell.value = cell.value ? '  ✓  ' : '';
        }
    }

    private setColumnWidths(columns: WorkbookSheetColumn[], width: number): void {
        columns.forEach(column => {
            column.autoWidth = false;
            column.width = width;
        });
    }

    private setAutoColumnWidths(rows: WorkbookSheetRow[], columns: WorkbookSheetColumn[]): void {
        const allLengths = [];
        for (let i = 0; i < rows[1].cells.length; i++) {
            const lengths = [];
            for (const row of rows) {
                const cellValue = row.cells ? row.cells[i]?.value : {};
                if (typeof cellValue === 'string') {
                    lengths.push(cellValue.length);
                }
                else {
                    lengths.push(5);
                }
            }
            allLengths.push(lengths);
        }

        const maxLengths = [];
        allLengths.forEach(col => maxLengths.push(Math.max(...col)));

        for (const col in columns) {
            if (!col) continue;
            columns[col].autoWidth = false;
            columns[col].width = maxLengths[col] * 9;
        }
    }

    getCodelist(key: string): string {
        if (key.substring(key.length - 2) === 'Id' && key.length > 2)
            return _.upperFirst(key).substring(0, key.length - 2);

        return null;
    }
}
