import { Component, ViewEncapsulation } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { VesselPermissions } from '@common/classes/permissions';
import { CommonService } from '@common/services/common.service';
import { RowClassArgs } from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-vessel-list',
    templateUrl: './vessel-list.component.html',
    styleUrls: ['./vessel-list.component.scss'],
    providers: [CommonService],
    encapsulation: ViewEncapsulation.None,
})
export class VesselListComponent extends BaseListComponent {
    queryName = 'Vessels';
    createPermission = VesselPermissions.Action.Create;

    breadcrumb = [
        {
            title: 'Vessels',
            icon: 'ship'
        },
        {
            title: 'Vessel List',
            icon: 'ship'
        }
    ];

    constructor(common: CommonService) { super(common); }

    notApprovedRow = (context: RowClassArgs) => ({ error: !context.dataItem.approved });
}
