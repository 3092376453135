import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { CommonService } from '@common/services/common.service';
import { format } from 'date-fns';

@Component({
    selector: 'app-indirect-processing-report',
    templateUrl: './indirect-processing-report.component.html',
    styleUrls: ['./indirect-processing-report.component.scss'],
    providers: [CommonService]
})
export class IndirectProcessingReportComponent extends BaseListComponent {
    queryName = 'IndirectProcessingReport';
    title = `${this.translateService.instant('Report for indirect processing with stevedore equipment')} (${format(new Date(), 'dd.MM.yyyy')})`;
    exportExcelCommand = 'ExportIndirectProcessingReport';

    breadcrumb = [
        {
            title: 'Reporting',
            icon: 'file-lines'
        },
        {
            title: 'Indirect Processing Report',
            icon: 'ship'
        }
    ];

    canCreateNew = () => false;
    constructor(common: CommonService) { super(common); }
}
