import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { CommonService } from '@common/services/common.service';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { format } from 'date-fns';

@Component({
    selector: 'app-ships-in-port',
    templateUrl: './ships-in-port.component.html',
    styleUrls: ['./ships-in-port.component.scss'],
    providers: [CommonService]
})
export class ShipsInPortComponent extends BaseListComponent {
    queryName = 'ShipsInPort';
    activeTab = 0;
    persistFilter = false;
    title = `${this.translateService.instant('Ships and Yachts in port')} (${format(new Date(), 'dd.MM.yyyy')})`;
    exportExcelCommand = 'ExportShipsInPort';

    breadcrumb = [
        {
            title: 'Reporting',
            icon: 'file-lines'
        },
        {
            icon: 'ship',
            title: 'Ships and Yachts in port'
        }
    ];

    canCreateNew = () => false;
    constructor(common: CommonService) { super(common); }

    onTabSelect(select: SelectEvent) {
        this.filter.activeTab = this.activeTab = select.index;
        this.filter.positionId = null;
        this.search();
    }

    override getDefaultFilter = () => ({
        activeTab: this.activeTab,
        atd: new Date(),
        isYacht: this.activeTab
    });

    getDateLabel = () => this.activeTab ? 'Docking' : 'Mooring';

    getShipLabel = () => {
        let columnName = '';

        switch (this.activeTab) {
            case 0:
                columnName = 'Vessel';
                break;
            case 1:
                columnName = 'Yacht';
                break;
            case 2:
                columnName = 'Cruises';
                break;
            default:
                break;
        }

        return `${columnName} Name`;
    };
}
