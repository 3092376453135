import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    template: `
        <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-auto text-danger">
                    <fa-icon [icon]="['fas', 'exclamation-circle']" [size]="'3x'"></fa-icon>
                </div>
                <div class="col" [innerHTML]="message"></div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="activeModal.close()" translate>Ok</button>
        </div>
    `,
})
export class WarningDialogComponent {
    public title: string;
    public message: string;

    constructor(public activeModal: NgbActiveModal) {

    }
}
