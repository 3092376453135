<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <app-grid [data]="gridData" [sortable]="true" (pageChange)="pageChange.emit($event)" [sort]="[{ field: 'id', dir: 'desc' }]">
            <kendo-grid-column [title]="'Vessel Visit nbr' | translate" field="id">
                <ng-template kendoGridCellTemplate let-row>
                    <div [routerLink]="['/vessel-visit/view', row.id]">
                        <a class="text-primary link-box"> {{row.yearNumber}} </a>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Vessel' | translate" field="vesselName">
                <ng-template kendoGridCellTemplate let-row>
                    <div [routerLink]="['/vessel/view', row.vesselId]">
                        <a class="text-primary link-box" target="_blank"> {{row.vesselName}} </a>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'IMO Number' | translate" field="vesselImo"></kendo-grid-column>
            <kendo-grid-column [title]="'ETA' | translate" field="eta">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.eta | date}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'ETD' | translate" field="etd">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.etd | date}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Status' | translate" field="statusName">
                <ng-template kendoGridCellTemplate let-row>
                    <span [pill]="{ status: row.statusId, module: 'VesselVisit' }">
                        {{row.statusId | codelist:'VesselVisitStatus' | async}}
                    </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Terminal Operator' | translate" field="terminalOperatorName">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.terminalOperatorId | codelist:'PortFacility' | async}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Berth' | translate" field="berthNames">
            </kendo-grid-column>
            <kendo-grid-column [title]="'Flag' | translate" field="countryName">
                <ng-template kendoGridCellTemplate let-item>
                    <app-flag-icon [code]="item.countryId" codelist="Country"></app-flag-icon>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Approved by Harbourmaster' | translate" field="approved">
                <ng-template kendoGridCellTemplate let-item>
                    <fa-icon *ngIf="item.approved" class="green-icon" icon="check-circle"></fa-icon>
                    <fa-icon *ngIf="!item.approved" class="red-icon" icon="times-circle"></fa-icon>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Documentation Approval' | translate">
                <ng-template kendoGridCellTemplate let-item>
                    <fa-icon *ngIf="item.allDocumentsApproved" class="green-icon" icon="check-circle"></fa-icon>
                    <fa-icon *ngIf="!item.allDocumentsApproved && !item.documentRejected" class="orange-icon" icon="clock"></fa-icon>
                    <fa-icon *ngIf="item.documentRejected" class="red-icon" icon="times-circle"></fa-icon>
                </ng-template>
            </kendo-grid-column>
        </app-grid>
    </div>
</div>
