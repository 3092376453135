import { Component, OnInit } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { StockRecordPermissions } from '@common/classes/permissions';
import { CommonService } from '@common/services/common.service';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'app-stock-record-list',
    templateUrl: './stock-record-list.component.html',
    styleUrls: ['./stock-record-list.component.css'],
    providers: [CommonService]
})
export class StockRecordListComponent extends BaseListComponent implements OnInit {
    queryName = 'StockRecords';
    breadcrumb = [
        {
            title: 'Cargo',
            icon: 'boxes-stacked',
        },
        {
            icon: 'boxes-stacked',
            title: 'Stock Records List'
        }
    ];

    createPermission = StockRecordPermissions.Action.Create;
    freightForwarderOptions: any[] = [];

    constructor(protected common: CommonService) { super(common); }

    canCreateNew() {
        return false;
    }

    override async ngOnInit() {
        super.ngOnInit();
        const { results } = await this.common.cqrs.query('Organizations', { typeId: 'FFW', take: 1000 }) as any;
        this.freightForwarderOptions = results.map(({ id: value, name: label }) => ({ value, label }));
    }
}
