import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

export enum MimeType {
    Excel = 'application/vnd.ms-excel',
    Pdf = 'application/pdf',
    Image = 'image/png',
    Text = 'text/plain'
}

@Component({
    selector: 'app-upload-action-buttons',
    template: `
    <strong *ngIf="!multiple" class="k-upload-status">
        <button *ngIf="canPreview(file)" class="btn btn-sm btn-outline-secondary mr-2" type="button" (click)="preview(file)">
            <fa-icon icon="eye"></fa-icon>
        </button>
        <button *ngIf="!disabled && canDownload(file)" class="btn btn-sm btn-outline-secondary" type="button" (click)="download(file)">
            <fa-icon icon="download"></fa-icon>
        </button>
        <button type="button"
            *ngIf="canRemove()"
            class="k-button k-upload-action"
            [attr.tabIndex]="-1"
            (click)="remove()">
        </button>
    </strong>

    <span *ngIf="multiple">
        <button class="btn btn-default btn-xs"
            [attr.aria-label]="'View' | translate"
            [attr.title]="'View' | translate"
            *ngIf="canPreview(file)"
            (click)="preview(file)">
            <fa-icon icon="eye"></fa-icon>
        </button>
        <button class="btn btn-default btn-xs"
            [attr.aria-label]="'Download' | translate"
            [attr.title]="'Download' | translate"
            *ngIf="canDownload(file)"
            (click)="download(file)">
            <fa-icon icon="download"></fa-icon>
        </button>
        <button class="btn btn-danger btn-xs"
            [attr.aria-label]="'Remove' | translate"
            [attr.title]="'Remove' | translate"
            *ngIf="canRemove()"
            (click)="remove()">
            <fa-icon icon="trash"></fa-icon>
        </button>
    </span>
    `
})
export class UploadActionButtonsComponent {

    static previewableExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'gif', 'bmp', 'tcs', 'ccs'];
    @Input() file: any;
    @Input() disabled: boolean;
    @Input() multiple: boolean;
    @Output() public removeFile = new EventEmitter<any>();

    public static download(name: string, content: string) {
        this.downloadBlob(name, this.createBlob(content, undefined));
    }

    public static preview(name: string, content: string) {
        if (!this.isFilePreviewable(name)) {
            this.download(name, content);
            return;
        }
        const type = name.toLowerCase().endsWith('pdf') ? MimeType.Pdf : name.toLowerCase().endsWith('tcs') ? MimeType.Text : MimeType.Image;
        const link = this.createBlobLink(name, this.createBlob(content, type));
        window.open(link.href, '_blank');
    }

    public static downloadBlob(name: string, blob: Blob) {
        this.createBlobLink(name, blob).click();
    }

    private static createBlobLink(name: string, blob: Blob) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        return link;
    }

    private static createBlob(content: string, type: string) {
        const binaryString = window.atob(content);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return new Blob([bytes], { type });
    }

    public static isFilePreviewable(fileName): boolean {
        return this.previewableExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
    }

    public static canPreview(file): boolean {
        return (file?.id > 0 || !!file?.content) && this.isFilePreviewable(file.name);
    }

    constructor(private http: HttpClient) {
    }

    canRemove(): boolean {
        return !this.disabled;
    }

    remove(): void {
        this.removeFile.emit(this.file);
    }

    canDownload(file): boolean {
        return file?.id > 0 || !!file?.content;
    }

    canPreview(file): boolean {
        return UploadActionButtonsComponent.canPreview(file);
    }

    async download(file) {
        const attachment: any = file.id > 0
            ? await firstValueFrom(this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.id}`))
            : file;
        UploadActionButtonsComponent.download(attachment.name, attachment.content);
    }

    async preview(file) {
        const attachment: any = file.id > 0
            ? await firstValueFrom(this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.id}`))
            : file;
        UploadActionButtonsComponent.preview(attachment.name, attachment.content);
    }
}
