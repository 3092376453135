import { WarehouseDocumentsTabComponent } from './components/warehouse-documents-tab/warehouse-documents-tab.component';
import { ServiceRequestCreateComponent } from './components/service-request-create/service-request-create.component';
import { ServiceRequestViewComponent } from './components/service-request-view/service-request-view.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ViewMode } from '@common/models/view-mode';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { CommonModule } from '@common/common.module';
import { ServiceRequestListComponent } from './components/service-request-list/service-request-list.component';

export const SERVICE_REQUEST_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: ServiceRequestListComponent
    },
    {
        path: 'view/:id',
        component: ServiceRequestViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'edit/:id',
        component: ServiceRequestViewComponent,
        data: {
            mode: ViewMode.edit
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'create',
        component: ServiceRequestViewComponent,
        data: {
            mode: ViewMode.create
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(SERVICE_REQUEST_ROUTES)],
    declarations: [ServiceRequestViewComponent, ServiceRequestListComponent, ServiceRequestCreateComponent, WarehouseDocumentsTabComponent],
    providers: [CanDeactivateView]
})
export class ServiceRequestsModule { }
