import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { environment } from '@environments/environment';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-upload-multiple-files',
    template: `
        <app-editable-grid
            [data]="files"
            entityName="Attachment"
            [editMode]="editMode"
            (rowAction)="rowAction($event)">
            <kendo-grid-column [title]="'Name' | translate" field="name">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span style="color: #007bff; cursor: pointer" (click)="download(dataItem)">{{ dataItem.name }}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Type' | translate" field="typeId">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [(ngModel)]="dataItem.typeId" type="codelist" codelist="AttachmentType"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.typeId | codelist: 'AttachmentType' | async }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Created By' | translate" field="createdById">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.createdById | codelist:'User' | async }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Created Date' | translate" field="createdDate" format="g"></kendo-grid-column>
            <kendo-grid-column [title]="'Last Modified By' | translate" field="lastModifiedById">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.lastModifiedById | codelist:'User' | async }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Last Modified Date' | translate" field="lastModifiedDate" format="g"></kendo-grid-column>
            <kendo-grid-column [title]="'Internal' | translate" field="internal">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [(ngModel)]="dataItem.internal" type="boolean"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <app-control [(ngModel)]="dataItem.internal" type="boolean" [isDisabled]="true"></app-control>
                </ng-template>
            </kendo-grid-column>
        </app-editable-grid>
    `
})
export class UploadMultipleFilesComponent {
    @Input() files: any[];
    @Input() disabled: boolean;
    @Input() isInternalDisabled;
    @Input() allowToEditFile = (file) => true;
    @Input() allowToDeleteFile = (file) => true;
    @Input() editMode: any;
    @Output() public removeFile: EventEmitter<any> = new EventEmitter<any>();
    @Output() public add: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient) {}

    removeAction(file) {
        if (!this.allowToDeleteFile(file)) return;
        this.removeFile.emit(file);
    }

    rowAction(event): void {
        if (event?.action === 'add') this.add.emit();
        if (event?.action === 'remove') this.removeAction(event);
        if (event?.action === 'cancel') this.removeAction(event);
    }

    async download(file) {
        if (!this.canDownload(file)) {
            return;
        }

        if (file.id > 0) {
            const attachment: any = await firstValueFrom(this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.id}`));
            UploadActionButtonsComponent.preview(attachment.name, attachment.content);
        } else {
            UploadActionButtonsComponent.preview(file.name, file.content);
        }
    }

    canDownload(file) {
        return file && (file.id > 0 || !!file.content);
    }

    isDisabled(file) {
        return this.disabled || !this.allowToEditFile(file);
    }
}
