import { AdministrationPermissions } from '@common/classes/permissions';
import { BaseListComponent } from '@common/classes/base-list';
import { Component } from '@angular/core';
import { CommonService } from '@common/services/common.service';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    providers: [CommonService]
})
export class UserListComponent extends BaseListComponent {
    queryName = 'Users';

    breadcrumb = [
        {
            title: 'Administration',
            icon: 'cog'
        },
        {
            icon: 'users',
            title: 'Users'
        }
    ];

    createPermission = AdministrationPermissions.User.Create;

    gridSort: SortDescriptor[] = [
        {
            field: 'userName',
            dir: 'asc'
        }
    ];

    constructor(common: CommonService) { super(common); }
}
