import { Component, Input, ChangeDetectorRef, OnDestroy, OnChanges, Output, EventEmitter, ViewEncapsulation, ViewChildren } from '@angular/core';
import { AddEvent, CancelEvent, ColumnBase, EditEvent, GridComponent, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import _ from 'lodash';
import { AppGridComponent } from '../app-grid/app-grid.component';
import { environment } from '@environments/environment';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridEditService } from '@common/services/grid-edit.service';
import { Router } from '@angular/router';
import { AppControlComponent } from '../app-control/app-control.component';
import { ExportService } from '@common/services/export.service';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-editable-grid',
    templateUrl: './editable-grid.component.html',
    styleUrls: ['./editable-grid.component.scss'],
    providers: [GridEditService],
    encapsulation: ViewEncapsulation.None
})
export class EditableGridComponent extends AppGridComponent implements OnDestroy, OnChanges {
    @Input() entityName: string;
    @Input() editMode: boolean;
    @Input() canAddNew = true;
    @Input() canDelete = true;
    @Input() canCancelEdit = true;
    @Input() canEdit = true;
    @Input() canEditRow: (row: any) => boolean = () => true;
    @Input() canExport = false;
    @Input() initialData = {};
    @Input() sort = [...environment.settings.grid.sort] as SortDescriptor[];
    @Input() validateRow: (row: any) => boolean = () => true;

    @Output() rowAction = new EventEmitter();
    @Output() saveEvent = new EventEmitter<SaveEvent>();
    @Output() removeEvent = new EventEmitter<RemoveEvent>();
    @Output() exportEvent = new EventEmitter<any>();

    @ViewChildren(AppControlComponent) appControls: AppControlComponent[];

    isEditing = false;

    constructor(changeDetectorRef: ChangeDetectorRef,
        commonService: CommonService,
        router: Router,
        public gridEdit: GridEditService,
        exportService: ExportService) {
        super(changeDetectorRef, commonService, router, exportService);
    }

    override updateColumns(columns: ColumnBase[] = this.columns?.toArray() || []) {
        if (this.editMode) columns.push(...this.commandColumns);
        super.updateColumns(columns);
    }

    exportToExcel(grid: GridComponent): void {
        grid.saveAsExcel();
    }

    // Opens a new row for adding
    onAdd(event: AddEvent, form) {
        this.rowAction.emit({ action: 'add' });
        this.gridEdit.addHandler(event, form, this.initialData);
        this.isEditing = true;
    }

    // Edits the selected row
    onEdit(event: EditEvent) {
        this.rowAction.emit({ action: 'edit' });
        this.gridEdit.editHandler(event);
        this.isEditing = true;
    }

    // Cancels the edit action
    onCancel(event: CancelEvent) {
        this.rowAction.emit({ action: 'cancel' });
        this.gridEdit.cancelHandler(event);
        this.isEditing = false;
    }

    onSave(event: SaveEvent) {
        // Check if valid
        if (!this.validateRow(event.dataItem) || this.appControls.some(x => !!x.error)) {
            this.commonService.toastrService.error(this.commonService.translateService.instant('Some required fields are empty'));
            return;
        }

        // If event.dataItem doesn't have an id, it's a new row
        const isNew = !event.dataItem.id;
        if (isNew) {
            // Adding new
            this.gridData.total++;
        } else {
            // Editing existing
            const editedRowIndex = this.gridData.data.findIndex((item) => item.id === event.dataItem.id);
            this.gridData.data.find[editedRowIndex] = event.dataItem;
        }
        this.rowAction.emit({ action: 'save' });
        this.saveEvent.emit({ ...event, isNew });

        // Validate if the add is valid
        this.gridEdit.saveHandler(event);
        this.isEditing = false;
    }

    onRemove(event: RemoveEvent) {
        this.gridData.total--;
        this.rowAction.emit({ action: 'remove' });
        this.removeEvent.emit(event);
        this.gridEdit.removeHandler(event);
    }

    onExcelExport(args: any): void {
        args.preventDefault();
        this.exportEvent.emit(args);
    }
}
