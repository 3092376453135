<div class="jumbotron mt-3">

    <div>
        <h2 class="display-5"><img src="/assets/img/actual-logo.png" style="width: 5%"></h2>
    </div>
    <br>
    <p><span translate>Port Community Lincensed To</span>: <b translate>Durres Port Authority</b></p>
    <p></p>
    <p> <span translate>License Number</span>: <b>A-PCS24102022</b></p>
    <p><img src="/assets/img/licences/license1.jpg" style="width: 60%"></p>
    <p><span translate>License Number</span>: <b>A-PCS24102023</b></p>
    <p><img src="/assets/img/licences/license2.png" style="width: 60%"></p>



</div>
