<app-grid [data]="errors" [pageable]="false">
    <kendo-grid-column title="{{'Entity'|translate}}" headerClass="bg-danger text-white">
        <ng-template kendoGridCellTemplate let-item>
            <span>{{trimmedName(item.entityTypeName) | translate}}</span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{'Property'|translate}}" field="propertyName" headerClass="bg-danger text-white">
        <ng-template kendoGridCellTemplate let-item>
            <span>{{trimmedName(item.propertyName) | translate}}</span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{'Error'|translate}}" headerClass="bg-danger text-white">
        <ng-template kendoGridCellTemplate let-item>
            <span>{{item.errorMessage | translate}}</span>
        </ng-template>
    </kendo-grid-column>
</app-grid>
