import { DocumentsGridComponent } from './components/documents-grid/documents-grid.component';
import { PillDirective } from './directives/pill.directive';
import { DialogFormComponent } from './components/dialog-form/dialog-form.component';
import { AppFilterComponent } from './components/app-filter/app-filter.component';
import { DatePipe, CommonModule as NgCommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChangesDialogComponent } from '@common/components/changes-dialog.component';
import { WarningDialogComponent } from '@common/components/warning-dialog.component';
import { CodelistService } from '@common/services/codelist.service';
import { WebsocketService } from '@common/services/websocket.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { AppControlComponent } from './components/app-control/app-control.component';
import { AppFormComponent } from './components/app-form/app-form.component';
import { AppGridComponent } from './components/app-grid/app-grid.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { ErrorDialogComponent } from './components/error-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/login/logout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UploadActionButtonsComponent } from './components/upload-action-buttons.component';
import { UploadMultipleFilesComponent } from './components/upload-multiple-files.component';
import { UploadSingleFileComponent } from './components/upload-single-file.component';
import { AuthGuard } from './guards/auth.guard';
import { AppHttpInterceptor } from './interceptors/http-interceptor';
import { ActivePipe } from './pipes/active.pipe';
import { CodelistPipe } from './pipes/codelist.pipe';
import { PadPipe } from './pipes/pad.pipe';
import { CqrsService } from './services/cqrs.service';
import { DialogService } from './services/dialog.service';
import { EditableGridComponent } from './components/editable-grid/editable-grid.component';
import { FlagIconComponent } from './components/flag-icon.component';
import { CheckIconComponent } from './components/check-icon.component';
import { InfoButtonComponent } from './components/info-button.component';
import { AccordionHeaderComponent } from './components/accordion-header.component';
import { ToggleableFilterComponent } from './components/toggleable-filter/toggleable-filter.component';
import { EntityErrorsComponent } from './entity-errors/entity-errors.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from './services/common.service';

@NgModule({
    imports: [
        NgCommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NgbModule,
        TranslateModule.forChild({ extend: true }),
        IntlModule,
        GridModule,
        ExcelModule,
        InputsModule,
        ButtonModule,
        DropDownsModule,
        DateInputsModule,
        DatePickerModule,
        LayoutModule,
        DialogModule,
        FileSelectModule,
        IndicatorsModule,
        NavigationModule,
        NgbPopoverModule
    ],
    exports: [
        NgCommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslateModule,
        FontAwesomeModule,
        IntlModule,
        GridModule,
        ExcelModule,
        InputsModule,
        ButtonModule,
        DropDownsModule,
        DateInputsModule,
        DatePickerModule,
        LayoutModule,
        DialogModule,
        IndicatorsModule,
        NavigationModule,
        SidebarComponent,
        ActionBarComponent,
        HeaderComponent,
        FooterComponent,
        AppControlComponent,
        AppFormComponent,
        CheckboxComponent,
        LoaderComponent,
        ContentHeaderComponent,
        CodelistPipe,
        PadPipe,
        ActivePipe,
        AppGridComponent,
        EditableGridComponent,
        ConfirmDialogComponent,
        WarningDialogComponent,
        ChangesDialogComponent,
        EntityErrorsComponent,
        ErrorDialogComponent,
        FlagIconComponent,
        CheckIconComponent,
        InfoButtonComponent,
        AccordionHeaderComponent,
        ToggleableFilterComponent,
        AppFilterComponent,
        DialogFormComponent,
        PillDirective,
        DocumentsGridComponent
    ],
    declarations: [
        LoginComponent,
        LogoutComponent,
        UnauthorizedComponent,
        SidebarComponent,
        ActionBarComponent,
        HeaderComponent,
        FooterComponent,
        AppControlComponent,
        CheckboxComponent,
        LoaderComponent,
        ConfirmDialogComponent,
        ChangesDialogComponent,
        WarningDialogComponent,
        ContentHeaderComponent,
        AppGridComponent,
        EntityErrorsComponent,
        ErrorDialogComponent,
        UploadSingleFileComponent,
        UploadMultipleFilesComponent,
        UploadActionButtonsComponent,
        UnauthorizedComponent,
        CodelistPipe,
        PadPipe,
        ActivePipe,
        AppFormComponent,
        EditableGridComponent,
        ConfirmDialogComponent,
        WarningDialogComponent,
        ChangesDialogComponent,
        EntityErrorsComponent,
        ErrorDialogComponent,
        FlagIconComponent,
        InfoButtonComponent,
        CheckIconComponent,
        AccordionHeaderComponent,
        ToggleableFilterComponent,
        AppFilterComponent,
        DialogFormComponent,
        PillDirective,
        DocumentsGridComponent
    ],
    providers: [
        DialogService,
        AuthGuard,
        CodelistPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true,
        },
        CommonService,
        CodelistService,
        WebsocketService,
        CqrsService,
        DatePipe
    ]
})
export class CommonModule {

}
