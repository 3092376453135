import { VesselVisitGridComponent } from './../vessel-visit-grid/vessel-visit-grid.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { VesselVisitPermissions } from '@common/classes/permissions';
import { CommonService } from '@common/services/common.service';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';

@Component({
    selector: 'app-vessel-visit-list',
    templateUrl: './vessel-visit-list.component.html',
    styleUrls: ['./vessel-visit-list.component.scss'],
    providers: [CommonService]
})
export class VesselVisitListComponent extends BaseListComponent {
    queryName = 'VesselVisits';
    createPermission = VesselVisitPermissions.Action.Create;

    breadcrumb = [
        {
            icon: 'ship',
            title: 'Vessel Visits'
        },
        {
            icon: 'ship',
            title: 'Vessel Visit List'
        }
    ];

    @ViewChild(VesselVisitGridComponent) visitGrid: VesselVisitGridComponent;

    constructor(common: CommonService) { super(common); }

    override search() {
        return super.search(this.visitGrid.appGrid.grid);
    }
}
