import { Component } from '@angular/core';
import { User } from '@common/models/User';
import { CqrsService } from '@common/services/cqrs.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent {
    model: any = {};
    user: User;

    constructor(public activeModal: NgbActiveModal, private cqrs: CqrsService) { }

    cancel() {
        this.activeModal.close(false);
    }

    canConfirm() {
        return this.model.oldPassword && this.model.newPassword && this.model.repeatNewPassword;
    }

    confirm() {
        this.cqrs.command('ChangePassword', {
            userId: this.user.id,
            userName: this.user.userName,
            ...this.model
        }).then((res) => {
            if (!res) return;
            this.activeModal.close(true);
        });
    }
}
