<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Users" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col">
            <app-control label='Username' [(ngModel)]="filter.userName"></app-control>
        </div>
        <div class="col">
            <app-control label='Full Name' [(ngModel)]="filter.fullName"></app-control>
        </div>
        <div class="col">
            <app-control label='Email' [(ngModel)]="filter.email"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <app-grid [data]="gridData" [sortable]="true" [sort]="gridSort" (pageChange)="search()">
            <kendo-grid-column [title]="'Username' | translate" field="userName">
                <ng-template kendoGridCellTemplate let-row>
                    <div [routerLink]="['/administration/user', defaultViewMode, row.id]">
                        <a class="text-primary link-box"> {{row.userName}} </a>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Full Name' | translate" field="fullName"></kendo-grid-column>
            <kendo-grid-column [title]="'Email' | translate" field="email"></kendo-grid-column>
            <kendo-grid-column [title]="'Organization' | translate" field="organization">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.organizationId | codelist:'Organization' | async}}</span>
                </ng-template>
            </kendo-grid-column>
        </app-grid>
    </div>
</div>
