<form novalidate #form="ngForm">
    <button *ngIf="canExport" class="btn btn-primary mb-2" type="button" icon="file-excel" (click)="exportToExcel(grid)"
        translate><fa-icon icon="file-excel"></fa-icon>&nbsp;Export to Excel</button>
    <kendo-grid class="inline-editable"
        [data]="gridData"
        [skip]="state.skip"
        [pageable]="pageable"
        [pageSize]="pageSize || state.take"
        [loading]="isBusy"
        [sort]="sort"
        [rowClass]="rowClass"
        (dataStateChange)="arrayDataStateChanged(data, $event)"
        (add)="onAdd($event, form)"
        (edit)="onEdit($event)"
        (cancel)="onCancel($event)"
        (save)="onSave($event)"
        (remove)="onRemove($event)"
        (excelExport)="onExcelExport($event)">
        <kendo-grid-excel *ngIf="canExport"></kendo-grid-excel>
        <kendo-grid-command-column [width]="50" *ngIf="editMode && canEdit">
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-row>
                <button kendoGridEditCommand [primary]="true" class="btn btn-lg btn-primary" *ngIf="canEditRow(row)">
                    <fa-icon icon="edit"></fa-icon>
                </button>
                <button kendoGridSaveCommand class="btn btn-lg btn-primary" *ngIf="canEditRow(row)">
                    <fa-icon *ngIf="isNew !== undefined" icon="check"></fa-icon>
                </button>
            </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-command-column [width]="45" *ngIf="editMode && canEdit && (canDelete || canCancelEdit)">
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-row>
                <button kendoGridRemoveCommand class="btn btn-lg btn-danger" *ngIf="canEditRow(row) && canDelete">
                    <fa-icon icon="trash"></fa-icon>
                </button>
                <button kendoGridCancelCommand class="btn btn-lg btn-danger" *ngIf="canEditRow(row) && canCancelEdit">
                    <fa-icon icon="ban"></fa-icon>
                </button>
            </ng-template>
        </kendo-grid-command-column>
        <ng-template kendoGridToolbarTemplate>
            <button *ngIf="editMode && canEdit && canAddNew" kendoGridAddCommand type="button" [disabled]="isEditing"
                class="btn btn-primary float-right">{{'Add' | translate}}</button>
        </ng-template>
        <kendo-grid-messages pagerItems="{{'items' | translate}}" pagerItemsPerPage="{{'items per page' | translate}}"
            noRecords="{{'No records available' | translate}}" pagerOf="{{'of' | translate}}">
        </kendo-grid-messages>
    </kendo-grid>
</form>
