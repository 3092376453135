<div class="modal-header">
    <h4 class="modal-title" translate>New Service Request</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <app-control type="codelist" [(ngModel)]="model.typeId" codelist="ServiceRequestType"
                (ngModelChange)="serviceRequestTypeChange($event)" label="Service Request Type" [isDisabled]="isExport"></app-control>
        </div>
        <div class="col-12">
            <app-control type="select" [options]="typeTransitions" [isDisabled]="!model.typeId"
                [(ngModel)]="model.sourceDestination" label="Service Request Type Transition"></app-control>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="activeModal.close()" translate>Cancel</button>
    <button class="btn btn-primary" (click)="confirm()" translate [disabled]="!model.typeId || !model.sourceDestination">Confirm</button>
</div>
