import { AdministrationModule } from '@administration/administration.module';
import { Routes } from '@angular/router';
import { UnauthorizedComponent } from '@common/components/unauthorized/unauthorized.component';
import { CodelistModule } from './codelist/codelist.module';
import { LoginComponent } from './common/components/login/login.component';
import { LogoutComponent } from './common/components/login/logout.component';
import { AuthGuard } from './common/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { LicenseComponent } from './home/license/license.component';
import { ReportingModule } from './reporting/reporting.module';
import { ServiceRequestsModule } from './service-requests/service-requests.module';
import { StockRecordsModule } from './stock-records/stock-records.module';
import { UserAccountModule } from './user-account/user-account.module';
import { VesselVisitModule } from './vessel-visit/vessel-visit.module';
import { VesselModule } from './vessel/vessel.module';
import { WarehouseDocumentsModule } from './warehouse-documents/warehouse-documents.module';

export const ROUTES: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: '401', component: UnauthorizedComponent },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            { path: 'home', component: HomeComponent },
            { path: 'license', component: LicenseComponent },
            {
                path: 'administration',
                loadChildren: () => AdministrationModule,
                data: { title: 'Administration' }
            },
            {
                path: 'vessel',
                loadChildren: () => VesselModule,
                data: { title: 'Vessels' }
            },
            {
                path: 'vessel-visit',
                loadChildren: () => VesselVisitModule,
                data: { title: 'Vessel Visits' }
            },
            {
                path: 'service-request',
                loadChildren: () => ServiceRequestsModule,
                data: { title: 'Service Requests' }
            },
            {
                path: 'warehouse-document',
                loadChildren: () => WarehouseDocumentsModule,
                data: { title: 'Warehouse Documents' }
            },
            {
                path: 'stock-record',
                loadChildren: () => StockRecordsModule,
                data: { title: 'Stock Records' }
            },
            {
                path: 'reporting',
                loadChildren: () => ReportingModule,
                data: { title: 'Reporting' }
            },
            {
                path: 'codelist',
                loadChildren: () => CodelistModule,
                data: { title: 'Codelists' }
            },
            {
                path: 'user-account',
                loadChildren: () => UserAccountModule,
                data: { title: 'Account' }
            },
            { path: '**', redirectTo: '/home' }
        ]
    }
];
