import { environment as base } from './environment.base';
import * as _ from 'lodash';

const baseEnv = _.cloneDeep(base);

export const environment = _.merge(baseEnv, {
    production: false,
    name: 'local',
    title: 'PCS Durres Local',

    settings: {
        header: {
            environmentColor: 'green'
        }
    }
});
