import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { firstValueFrom, isObservable, Observable, Subject, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { RememberStateService } from '@common/services/remember-state.service';
import { PermissionType, User } from '@common/models/User';
import { CommonService } from '@common/services/common.service';

type PropertyFunction<T> = () => T;

export interface ActionBarOptions {
    collapsible: boolean;
    showTooltips: boolean;
    hasPermissions?: (item: IActionBarItem | IActionBarGroup) => boolean;
    noPermissions?: 'disable' | 'hide';
    tooltipPlacement: PlacementArray;
    tooltipOpenDelay: number;
}

export class ActionBarConfig implements Required<ActionBarOptions> {
    collapsible: boolean;
    showTooltips: boolean;
    noPermissions: 'disable' | 'hide';
    tooltipPlacement: PlacementArray;
    tooltipOpenDelay: number;
    hasPermissions: (item: IActionBarItem | IActionBarGroup) => boolean;
}

export interface IActionBarItem {
    label?: string | PropertyFunction<string>;
    icon?: string | PropertyFunction<string>;
    isDisabled?: boolean | PropertyFunction<boolean>;
    isSplit?: boolean;
    isVisible?: boolean | PropertyFunction<boolean>;
    onClick?: () => Promise<any> | Observable<any> | void;
    smallItems?: IActionBarItem[];
    options?: IActionBarItem[];
    backgroundColor?: string;
    permissions?: PermissionType[];
}

export interface IActionBarGroup {
    label: string;
    items: IActionBarItem[];
    isDisabled?: boolean | PropertyFunction<boolean>;
    isVisible?: boolean | PropertyFunction<boolean>;
    permissions?: PermissionType[];
}

/*
 * A component that makes it easy to create actionbar (toolbar)
 */
@Component({
    selector: 'app-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss'],
    providers: [CommonService]
})
export class ActionBarComponent implements OnInit, OnDestroy {
    @Input() items: IActionBarGroup[];
    @Input() collapsed = false;
    @Input() collapsible = true;
    @Input() tooltipPlacement: PlacementArray = 'bottom';
    @Input() tooltipOpenDelay = 1000;

    private actionBarStatus = {
        collapsed: this.collapsed,
        empty: false
    };
    private user: User;

    protected destroy$ = new Subject();

    constructor(private rememberStateService: RememberStateService, private common: CommonService) {
        common.userService.currentUserSubject.pipe(takeUntil(this.destroy$)).subscribe((user) => {
            this.user = user;
        });
    }

    ngOnInit(): void {
        if (!this.hasAnyVisibleGroup()) this.actionBarStatus.empty = true;
        this.collapsed = this.rememberStateService.initialize('actionBar');
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    openActionGroupDropdown(dropdown) {
        if (this.collapsed) dropdown.open();
    }

    async onClick(item: IActionBarItem, group: IActionBarGroup) {
        if (item.onClick && !this.isDisabled(item, group)) {
            let result = item.onClick();

            if (result) {
                try {
                    (item as any)._isBusy = true;

                    if (isObservable(result)) {
                        result = firstValueFrom(result);
                    }

                    await result;
                } catch (e) { }

                (item as any)._isBusy = false;
            }
        }
    }

    label(item: IActionBarItem) {
        if (_.isFunction(item.label)) {
            return item.label();
        }

        return item.label;
    }

    icon(item: IActionBarItem) {
        if (_.isFunction(item.icon)) {
            return item.icon();
        }

        return item.icon;
    }

    isDisabled(item: IActionBarItem, group: IActionBarGroup) {
        if (
            _.isBoolean(group.isDisabled) && group.isDisabled ||
            _.isFunction(group.isDisabled) && group.isDisabled() ||
            _.isBoolean(item.isDisabled) && item.isDisabled ||
            _.isFunction(item.isDisabled) && item.isDisabled()
        ) {
            return true;
        }

        return (item as any)._isBusy === true;
    }

    isVisible(item: IActionBarItem | IActionBarGroup) {
        if (_.isFunction(item.isVisible) && !item.isVisible()) {
            return false;
        }

        if (_.isBoolean(item.isVisible) && !item.isVisible) {
            return false;
        }

        if ((<IActionBarGroup>item).items) {
            return _.some((<IActionBarGroup>item).items, (x) => this.isVisible(x));
        }

        if (item.permissions) {
            return this.user?.hasPermission(...item.permissions);
        }

        return true;
    }

    toggleActionBarVisibility() {
        this.collapsed = this.actionBarStatus.collapsed = !this.collapsed;
        this.rememberStateService.setState('actionBar', this.collapsed);
    }

    hasAnyVisibleGroup() {
        return this.items && _.some(this.items, (x) => this.isVisible(x));
    }
}
