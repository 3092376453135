import { CodeList, CodelistService } from '@common/services/codelist.service';
import { Component, OnInit } from '@angular/core';
import { AdministrationPermissions } from '@common/classes/permissions';
import _ from 'lodash';
import { CommonService } from '@common/services/common.service';
import { BaseViewComponent } from '@common/classes/base-view';

@Component({
    selector: 'app-organization-edit',
    templateUrl: './organization-view.component.html',
    styleUrls: ['./organization-view.component.scss'],
    providers: [CommonService]
})
export class OrganizationViewComponent extends BaseViewComponent implements OnInit {
    entityName = 'Organization';
    organizationId: number;
    breadcrumb = [
        {
            title: 'Administration',
            icon: 'cog'
        },
        {
            icon: 'building',
            title: 'Organizations',
            route: '/administration/organization/list'
        },
        {
            icon: 'building',
            title: 'Organization View'
        }
    ];

    editPermission = AdministrationPermissions.Organization.Edit;
    createPermission = AdministrationPermissions.Organization.Create;
    override arrayProperties = ['roles'];
    roles: CodeList[];
    users: any[];

    constructor(commonService: CommonService, private codelistService: CodelistService) {
        super(commonService);
    }

    async ngOnInit() {
        super.ngOnInit();
        this.roles = await this.codelistService.getCodelist({ name: 'Role' });
    }

    override getIdentifier = () => this.model.name;

    override async modelLoaded() {
        super.modelLoaded();
        const { results } = await this.cqrs.query('Users', { skip: 0, take: 1000, organizationId: this.model.id }) as any;
        this.users = results;
    }

    addRole(roleId: number) {
        this.model.roles = [
            { roleId, role: { name: this.roles.find(p => p.id === roleId).name, id: roleId } },
            ...this.model.roles
        ];
    }

    roleExists(roleId: number) {
        return this.model.roles.some(role => role.roleId === roleId);
    }

    removeRole(roleId: number) {
        this.model.roles = this.model.roles.filter(modelRole => modelRole.roleId !== roleId);
    }

    get permissions() {
        const permissions = this.model.roles.flatMap(r => r.role.permissions).map(r => r?.permission);
        return _.uniq(permissions.filter(Boolean).map(p => `${p.module}.${p.namespace}.${p.name}`));
    }

}
