<app-grid [data]="data" [isBusy]="!collectionName">
    <kendo-grid-column field="fileName" title="{{'File Name' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <a class="text-primary link-box" (click)="downloadAttachment(row)">
                {{row.name}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="isVesselVisit" field="documentTypeId" title="{{'Document Type' | translate}}" [width]="200">
        <ng-template kendoGridCellTemplate let-row>
            <span>{{row.documentTypeId | codelist:'DocumentType' | async}} </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="showVersionColumn" field="version" title="{{'Version' | translate}}"
        [width]="250"></kendo-grid-column>
    <kendo-grid-column *ngIf="editMode" field="id" title="" [width]="50">
        <ng-template kendoGridCellTemplate let-row>
            <button class="btn btn-danger btn-xs" (click)="removeDocument(row.id)">
                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
            </button>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="!editMode && isVesselVisit" field="id" title="{{'Status' | translate}}" [width]="200">
        <ng-template kendoGridCellTemplate let-row>
            <span [pill]="{ status: row.statusId, module: 'VesselVisitDocumentStatus' }">
                {{row.status?.name}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="!editMode && isVesselVisit" field="statusChangeRemark" title="{{'Remarks' | translate}}">
    </kendo-grid-column>
    <kendo-grid-column *ngIf="canChangeDocumentStatus()" field="id" title="{{'Approve' | translate}}" [width]="100">
        <ng-template kendoGridCellTemplate let-row>
            <button *ngIf="row.statusId === 'WAP'" class="btn btn-success btn-xs"
                (click)="documentStatusChanged.emit({ documentId: row.id, action: 'approve' })">
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
            </button>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="canChangeDocumentStatus()" field="id" title="{{'Reject' | translate}}" [width]="100">
        <ng-template kendoGridCellTemplate let-row>
            <button *ngIf="row.statusId === 'WAP'" class="btn btn-danger btn-xs"
                (click)="documentStatusChanged.emit({ documentId: row.id, action: 'reject' })">
                <fa-icon [icon]="['fas', 'ban']"></fa-icon>
            </button>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridToolbarTemplate>
        <button *ngIf="editMode" class="btn btn-sm btn-primary float-right" (click)="addDocuments()" translate>
            <fa-icon icon="paperclip"></fa-icon>
            Add attachments
        </button>
    </ng-template>
</app-grid>
