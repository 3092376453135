<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Pilots" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-3">
            <app-control label='Code' [(ngModel)]="filter.code"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Name' [(ngModel)]="filter.name"></app-control>
        </div>
    </div>
</app-filter>
<div class="card mt-3">
    <div class="card-body">
        <app-editable-grid [data]="gridData" [editMode]="true" (save)="rowAction($event)" (remove)="rowAction($event)">
            <kendo-grid-column title="{{ 'ID' | translate }}" field="id" [width]="100">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-isNew="isNew">
                    <app-control [required]="true" [(ngModel)]="dataItem.id" [isDisabled]="!isNew"></app-control>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{ 'Code EN' | translate }}" field="codeEN" [width]="250">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [required]="true" [(ngModel)]="dataItem.codeEN"></app-control>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{ 'Name EN' | translate }}" field="nameEN" [width]="250">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [required]="true" [(ngModel)]="dataItem.nameEN"></app-control>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{ 'Code AL' | translate }}" field="codeAL" [width]="250">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [required]="true" [(ngModel)]="dataItem.codeAL"></app-control>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{ 'Name AL' | translate }}" field="nameAL" [width]="250">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [required]="true" [(ngModel)]="dataItem.nameAL"></app-control>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{ 'Active' | translate }}" field="active" [width]="100">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [required]="true" [(ngModel)]="dataItem.active" type="boolean"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <app-control [required]="true" [(ngModel)]="dataItem.active" type="boolean" [isDisabled]="true"></app-control>
                </ng-template>
            </kendo-grid-column>
        </app-editable-grid>
    </div>
</div>
