import { CommonService } from '@common/services/common.service';
import { BaseListComponent } from '@common/classes/base-list';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-stock-record-items-grid',
    templateUrl: './stock-record-items-grid.component.html',
    styleUrls: ['./stock-record-items-grid.component.css'],
    providers: [CommonService]
})
export class StockRecordItemsGridComponent extends BaseListComponent {
    queryName = 'StockRecordItems';
    persistFilter = false;

    @Input() items = [];
    @Input() queryAll = false;
    @Input() sourceDestination: string;
    @Input() serviceRequest;
    @Input() selection = [];
    @Output() selectionChange = new EventEmitter<any[]>();


    constructor(protected common: CommonService) { super(common); }

    override search() {
        if (!this.queryAll) {
            this.gridData = this.items;
            return;
        }
        if (this.sourceDestination) this.filter.sourceId = this.sourceDestination.split('-')[0];
        return super.search();
    }

    getLastInspectionRecord(row) {
        return row.inspectionRecords.find(i => i.active);
    }

    get isSilos() {
        return this.serviceRequest?.destinationId === 'SILOS';
    }

    get isYard() {
        return this.serviceRequest?.destinationId === 'YARD';
    }
}
