import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { User } from '@common/models/User';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    private currentUser: User;

    constructor(private userService: UserService, private router: Router) {
        userService.currentUserSubject.subscribe((user) => this.currentUser = user);
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = this.userService.isAuthenticatedSubject.getValue();
        if (!isAuthenticated) {
            this.router.navigate(['/logout']);
            return false;
        }

        if (isAuthenticated && !this.currentUser) {
            await this.userService.getCurrentUser();
        }

        return isAuthenticated;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(childRoute, state);
    }
}
