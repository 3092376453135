import { Injectable } from '@angular/core';
import { AddEvent, EditEvent } from '@progress/kendo-angular-grid';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class GridEditService {
    editedRowIndex: number;
    editedRow: any;

    addHandler({ sender }, form, initialData: object) {
        form.reset();
        this.closeEditor(sender);
        sender.data.data.unshift(structuredClone(initialData));
        sender.editRow(0);
        sender.expandRow(0);
    }

    editHandler({ sender, rowIndex, dataItem }: EditEvent) {
        this.closeEditor(sender);
        this.editedRowIndex = rowIndex;
        this.editedRow = _.clone(dataItem);
        sender.editRow(rowIndex);
        sender.expandRow(rowIndex);
    }

    saveHandler({ sender, rowIndex }) {
        this.closeEditor(sender, rowIndex);
    }

    closeEditor(grid, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        grid.collapseRow(rowIndex);
        this.editedRowIndex = this.editedRow = null;
    }

    cancelHandler({ sender, rowIndex, dataItem }) {
        if (!('id' in dataItem)) sender.data.data.splice(rowIndex, 1);
        else sender.data.data[rowIndex] = this.editedRow;
        this.closeEditor(sender, rowIndex);
    }

    removeHandler({ sender, rowIndex }) {
        sender.data.data.splice(rowIndex, 1);
    }
}
