import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-flag-icon',
    template: `
        <span *ngIf="code && !!codelist">
            <span class="fi fi-{{getCountry()}} {{square ? 'fis' : ''}}" [ngClass]="{ 'box-shadow tight': floating }"></span> {{code | codelist:'' + codelist | async}}
        </span>
        <span *ngIf="code && !codelist">
            <span class="fi fi-{{getCountry()}} {{square ? 'fis' : ''}}" [ngClass]="{ 'box-shadow tight': floating }">
        <span *ngIf="!code">□</span>
    `,
    styles: []
})
export class FlagIconComponent {
    @Input() code: string;
    @Input() codelist: string;
    @Input() square = false;
    @Input() floating = false;

    getCountry() {
        return this.code ? _.toLower(this.code.substring(0, 2)) : 'xx';
    }
}
