<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Vessel Schedule" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col">
            <app-control label="{{'Vessel Name' | translate}}" type="codelist" codelist="Vessel"
                [(ngModel)]="filter.vesselId"></app-control>
        </div>
        <div class="col">
            <app-control label="{{'IMO Number'|translate}}" type="number" format="'#'" [maxlength]="7"
                [(ngModel)]="filter.imoNumber"></app-control>
        </div>
        <div class="col">
            <app-control label="{{'Terminal Operator'|translate}}" type="codelist" codelist="PortFacility"
                [(ngModel)]="filter.terminalOperatorId"></app-control>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col text-center">
            <kendo-rangeslider [(ngModel)]="dateRange" [title]="title" [min]="getDate(-15)" [max]="getDate(15)"
                [smallStep]="dayMilliSec" [fixedTickWidth]="25" (valueChange)="searchDebounced()"
                [disabled]="!scheduleData"></kendo-rangeslider>
            <h6 class="mt-2">Selected range: {{dateRange[0] | date:'dd/MM/yyyy'}} - {{dateRange[1] | date:'dd/MM/yyyy'}}
            </h6>
        </div>
    </div>
</app-filter>
<div class="card mt-3">
    <div class="row">
        <div [ngClass]="{'active-tab': activeTab === 0}" (click)="activateTab(0)"
            class="col mt-2 mb-2 text-center border-right pointer">
            <h3>{{scheduleData?.announcedCount ?? 0}}</h3>
            <br />
            <h3 translate>VESSELS IN ARRIVAL</h3>
        </div>
        <div [ngClass]="{'active-tab': activeTab === 1}" (click)="activateTab(1)"
            class="col mt-2 mb-2 text-center border-right pointer">
            <h3>{{scheduleData?.anchorageCount ?? 0}}</h3>
            <br />
            <h3 translate>VESSELS ON ANCHORAGE</h3>
        </div>
        <div [ngClass]="{'active-tab': activeTab === 2}" (click)="activateTab(2)"
            class="col mt-2 mb-2 text-center border-right pointer">
            <h3>{{scheduleData?.arrivedCount ?? 0}}</h3>
            <br />
            <h3 translate>VESSELS IN PORT</h3>
        </div>
        <div [ngClass]="{'active-tab': activeTab === 3}" (click)="activateTab(3)"
            class="col mt-2 mb-2 text-center border-right pointer">
            <h3>{{scheduleData?.departedCount ?? 0}}</h3>
            <br />
            <h3 translate>DEPARTED VESSELS</h3>
        </div>
    </div>
</div>

<app-vessel-visit-grid [gridData]="gridData" (pageChange)="search()"></app-vessel-visit-grid>
