<app-loader [isBusy]="!model || isBusy">
    <ng-template>
        <app-action-bar [items]="actionBar" [collapsed]="false"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
        <div class="row">
            <div class="col-8">
                <div class="card card-border-top">
                    <h4 class="card-title"><fa-icon icon="ship"></fa-icon> {{'GENERAL DATA' | translate}}</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4">
                                <app-control label='Vessel' [(ngModel)]="model.vesselId" type="codelist"
                                    codelist="ApprovedVessel" [required]="true" *ngIf="editMode" [useCache]="false"
                                    (ngModelChange)="onVesselChange($event)">
                                </app-control>
                                <app-control label="Vessel" type="string" [ngModel]="model.vessel?.name"
                                    *ngIf="viewMode">
                                    <div class="textbox-suffix">
                                        <span class="input-group-text">
                                            <a href="https://www.marinetraffic.com/en/ais/details/ships/imo:{{model.vessel?.imo}}"
                                                target="_blank">
                                                <fa-icon icon="link"></fa-icon>
                                            </a>
                                        </span>
                                    </div>
                                </app-control>
                            </div>
                            <div class="col-4">
                                <label class="col-form-label mb-2" translate>
                                    Visit Status
                                </label>
                                <span [pill]="{ status: model.statusId, module: 'VesselVisit' }">
                                    {{model.statusId | codelist:'VesselVisitStatus' | async}}
                                </span>
                            </div>
                            <div class="col-4">
                                <app-control label='Terminal Operator' type="codelist" codelist="PortFacility"
                                    [(ngModel)]="model.terminalOperatorId" [required]="true">
                                </app-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <app-control label='Berth' type="codelist" codelist="Berth" [multi]="true"
                                    [(ngModel)]="model.berths" [isDisabled]="createMode || !editMode" #berthControl>
                                </app-control>
                            </div>
                            <div class="col-4">
                                <app-control label='Arrival Agent' [isDisabled]="true"
                                    [ngModel]="model.createdBy?.organization?.name"></app-control>
                            </div>
                            <div class="col-4">
                                <app-control label='Departure Agent' [(ngModel)]="model.departureAgentId" type="select"
                                    [options]="departureAgentOptions" [required]="true"></app-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <app-control label='Call Purpose' type="codelist" codelist="CallPurpose"
                                    [(ngModel)]="model.callPurposeId" [required]="true">
                                </app-control>
                            </div>
                            <div class="col-4">
                                <app-control label='Pilot' type="codelist" codelist="Pilot" [(ngModel)]="model.pilotId"
                                    [required]="true"></app-control>
                            </div>
                            <div class="col-4">
                                <app-control label='Position' type="codelist" codelist="Position"
                                    [(ngModel)]="model.positionId" [isDisabled]="createMode || !editMode"></app-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <app-control label='Approved by Harbourmaster' type="boolean"
                                    [(ngModel)]="model.approved" [isDisabled]="true"></app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card card-border-top">
                    <h4 class="card-title"><fa-icon icon="calendar"></fa-icon> {{'ARRIVAL AND DEPARTURE DATA' |
                        translate}}
                    </h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <app-control label='ETA' type="datetime" [(ngModel)]="model.eta" [required]="true">
                                </app-control>
                            </div>
                            <div class="col-6">
                                <app-control label='ATA' type="datetime" [(ngModel)]="model.ata"
                                    [isDisabled]="true"></app-control>
                            </div>
                            <div class="col-6">
                                <app-control label='ETD' [(ngModel)]="model.etd" type="datetime" [required]="true"
                                    [disabledDates]="etdDisabledDates">
                                </app-control>
                            </div>
                            <div class="col-6">
                                <app-control label='ATD' [(ngModel)]="model.atd" type="datetime"
                                    [isDisabled]="true"></app-control>
                            </div>
                            <div class="col-6">
                                <app-control label='Anchorage Time' [(ngModel)]="model.anchorageTime" type="datetime"
                                    [isDisabled]="true"></app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-4">
                <div class="card card-border-top">
                    <h4 class="card-title"><fa-icon icon="ruler"></fa-icon> {{'DRAFT DATA' | translate}}</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <app-control label='Arrival Draft' type="number" [(ngModel)]="model.arrivalDraft"
                                    format="n2">
                                </app-control>
                            </div>
                            <div class="col-6">
                                <app-control label='Departure Draft' type="number" [(ngModel)]="model.departureDraft"
                                    format="n2">
                                </app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card card-border-top">
                    <h4 class="card-title"><fa-icon icon="anchor"></fa-icon> {{'PORTS DATA' | translate}}</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <app-control label='Last Port Of Departure' type="codelist" codelist="Port"
                                    [(ngModel)]="model.lastPortOfDepartureId">
                                </app-control>
                            </div>
                            <div class="col-6">
                                <app-control label='Port Of Departure' type="codelist" codelist="Port"
                                    [(ngModel)]="model.portOfDepartureId" [isDisabled]="true">
                                </app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card card-border-top">
                    <h4 class="card-title"><fa-icon icon="calendar"></fa-icon> {{'OPERATIONS DATA' | translate}}</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <app-control label='Operations Start' type="datetime"
                                    [(ngModel)]="model.operationsStart" [isDisabled]="true">
                                </app-control>
                            </div>
                            <div class="col-6">
                                <app-control label='Operations End' type="datetime" [(ngModel)]="model.operationsEnd"
                                    [isDisabled]="true">
                                </app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                    <kendo-tabstrip-tab title="{{'Cargo Agents' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="cargoAgentsContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Cargo' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="cargoContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Freight Forwarders' | translate}}"
                        [cssClass]="{'hidden-tab': vesselCategoryId === 'PASS'}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="freightForwardersContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Operations Data' | translate}}"
                        [cssClass]="{'hidden-tab': createMode || !user.hasPermission(VesselVisitPermissions.Operations) || vesselCategoryId === 'PASS'}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="operationsDataContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Standstills' | translate}}"
                        [cssClass]="{'hidden-tab': createMode || !user.hasPermission(VesselVisitPermissions.Standstills.View)}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="standstillsContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Vessel Visit Documents' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="visitDocumentsContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Vessel Documents' | translate}}"
                        [cssClass]="{'hidden-tab': createMode}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="vesselDocumentsContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Pilot Details' | translate}}" [cssClass]="{'hidden-tab': createMode}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="pilotDetailsContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Movements' | translate}}" [cssClass]="{'hidden-tab': createMode}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="movementsContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Vessel Details' | translate}}"
                        [cssClass]="{'hidden-tab': createMode}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="vesselDetailsContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Remarks' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="remarksContent"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>
    </ng-template>
</app-loader>

<ng-template #cargoAgentsContent>
    <div class="row mt-3">
        <div class="col">
            <app-editable-grid [data]="model.agents" [editMode]="editMode" (save)="onRowSave($event)"
                [canEdit]="canEditCargoAgents()" [canEditRow]="canEditRow" [validateRow]="validateCargoAgentRow">
                <kendo-grid-column field="directionId" title="{{'Direction' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="codelist" codelist="DirectionOfGoods"
                            [(ngModel)]="dataItem.directionId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.directionId | codelist:'DirectionOfGoods' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="organizationId" title="{{'Agent Name' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" name="organizationId" type="select" [options]="cargoAgentOptions"
                            [(ngModel)]="dataItem.organizationId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.organizationId | codelist:'Organization' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="container" title="{{'Container' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        <app-control type="boolean" [(ngModel)]="row.container" [isDisabled]="true"></app-control>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control type="boolean" [initialValue]="vesselCategoryId === 'CNT'"
                            [isDisabled]="vesselCategoryId !== 'CNT'" [(ngModel)]="dataItem.container"></app-control>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="generalCargo" title="{{'General Cargo' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        <app-control type="boolean" [(ngModel)]="row.generalCargo" [isDisabled]="true"></app-control>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control type="boolean" [initialValue]="vesselCategoryId === 'GEN'"
                        [isDisabled]="vesselCategoryId !== 'GEN'" [(ngModel)]="dataItem.generalCargo"></app-control>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="passengers" title="{{'Passengers' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        <app-control type="boolean" [(ngModel)]="row.passengers" [isDisabled]="true"></app-control>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control type="boolean" [initialValue]="vesselCategoryId === 'PASS'"
                        [isDisabled]="vesselCategoryId !== 'PASS'" [(ngModel)]="dataItem.passengers"></app-control>
                    </ng-template>
                </kendo-grid-column>
            </app-editable-grid>
        </div>
    </div>
</ng-template>

<ng-template #cargoContent>
    <div class="row mt-3">
        <div class="col">
            <app-editable-grid [data]="model.cargo" [editMode]="editMode" (save)="onRowSave($event)"
                [canEditRow]="canEditRow" [validateRow]="validateCargoRow">
                <kendo-grid-column field="operationTypeId" title="{{'Operation Type' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="codelist" codelist="OperationType"
                            [(ngModel)]="dataItem.operationTypeId"
                            [filter]="operationTypeFilter.bind(this)"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.operationTypeId | codelist:'OperationType' | async}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="cargoTypeId" title="{{getCargoTypeLabel() | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="select" [options]="cargoTypes"
                            [(ngModel)]="dataItem.cargoTypeId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.cargoTypeId | codelist:'CargoType' | async}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="weight" title="{{'Weight' | translate}}" [width]="100">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="vesselCategoryId === 'GEN'" [(ngModel)]="dataItem.weight" type="number"
                            format="n2"></app-control>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}" [width]="100">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="vesselCategoryId !== 'GEN'" [(ngModel)]="dataItem.quantity" type="number"
                            format="n2"></app-control>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="packagingTypeId" title="{{getpackagingTypeLabel() | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="select" [options]="packagingTypes"
                            [(ngModel)]="dataItem.packagingTypeId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.packagingTypeId | codelist:'PackagingType' | async}}</span>
                    </ng-template>
                </kendo-grid-column>

                <ng-template kendoGridDetailTemplate let-dataItem>
                    <div class="row">
                        <div class="col-6">
                            <app-control label="Port Of Loading" [required]="true" type="codelist" codelist="Port"
                                [(ngModel)]="dataItem.portOfLoadingId"></app-control>
                        </div>
                        <div class="col-6">
                            <app-control label="Port Of Discharge" type="codelist" codelist="Port"
                                [(ngModel)]="dataItem.portOfDischargeId"></app-control>
                        </div>
                        <div class="col-6">
                            <app-control label="Cargo Description" type="textarea"
                                [(ngModel)]="dataItem.cargoDescription"></app-control>
                        </div>
                        <div class="col-6">
                            <app-control label="IMDG" type="codelist" codelist="Imdg"
                                [(ngModel)]="dataItem.imdgId"></app-control>
                        </div>
                    </div>
                </ng-template>
            </app-editable-grid>
        </div>
    </div>
</ng-template>

<ng-template #freightForwardersContent>
    <div class="row mt-3">
        <div class="col">
            <app-editable-grid [data]="model.freightForwarders" [editMode]="editMode" (save)="onRowSave($event)"
                [canEdit]="canEditFreightForwarders()" [canEditRow]="canEditRow" [validateRow]="validateFreightForwardRow">
                <kendo-grid-column field="organizationId" title="{{'Organization' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" name="organizationId" type="select"
                            [options]="freightForwarderOptions" [(ngModel)]="dataItem.organizationId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.organizationId | codelist:'Organization' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
            </app-editable-grid>
        </div>
    </div>
</ng-template>

<ng-template #operationsDataContent>
    <div class="row mt-3">
        <div class="col">
            <app-control label='Docking Time' type="datetime" [(ngModel)]="model.dockingTime"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col">
            <app-control label='Number of Cranes' type="number" [(ngModel)]="model.numberOfCranes"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col">
            <app-control label='Processing Method' type="codelist" codelist="ProcessingMethod"
                [(ngModel)]="model.processingMethodId" [isDisabled]="true"></app-control>
        </div>
        <div class="col">
            <app-control label='Processing Rate' type="number" [(ngModel)]="model.processingRate"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col-4">
            <app-control label='Processing Remark' [(ngModel)]="model.processingRemark"
                [isDisabled]="true"></app-control>
        </div>
    </div>
    <app-operations-grid [operations]="model.operations" [canEdit]="true" [editMode]="editMode"></app-operations-grid>
</ng-template>

<ng-template #standstillsContent>
    <div class="row">
        <div class="col">
            <app-grid [data]="model.standstills">
                <kendo-grid-column field="from" title="{{'From' | translate}}" [width]="200">
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.from | date}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="to" title="{{'To' | translate}}" [width]="200">
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.to | date}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="remarks" title="{{'Remarks' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="createdDate" title="{{'Created Date' | translate}}" [width]="200">
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.createdDate | date}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="createdById" title="{{'Created By' | translate}}" [width]="200">
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.createdById | codelist:'User' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
            </app-grid>
        </div>
    </div>
</ng-template>

<ng-template #visitDocumentsContent>
    <app-documents-grid [model]="model" [editMode]="editMode" [isVesselVisit]="true" [user]="user"
        (documentStatusChanged)="changeDocumentStatus($event)"
        [documentTypeFilter]="documentTypeFilter.bind(this)"></app-documents-grid>
</ng-template>

<ng-template #vesselDocumentsContent>
    <app-documents-grid [model]="model.vessel" [editMode]="false"></app-documents-grid>
</ng-template>

<ng-template #movementsContent>
    <div class="row mt-3">
        <div class="col">
            <app-editable-grid [data]="model.movements" [editMode]="editMode" [canEdit]="false"
                (save)="onRowSave($event)" [canAddNew]="true" [canEditRow]="canEditRow">
                <kendo-grid-column field="typeId" title="{{'Movement Type' | translate}}" [width]="150">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="codelist" codelist="MovementType"
                            [(ngModel)]="dataItem.typeId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.typeId | codelist:'MovementType' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fromId" title="{{'Movement From' | translate}}" [width]="200">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="codelist" codelist="Berth" [(ngModel)]="dataItem.fromId"
                            [selectLabel]="berthSelectLabel"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.fromId | codelist:'Berth':'name' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="toId" title="{{'Movement To' | translate}}" [width]="200">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="codelist" codelist="Berth" [(ngModel)]="dataItem.toId"
                            [selectLabel]="berthSelectLabel"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.toId | codelist:'Berth':'name' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="startDate" title="{{'Movement Start Date' | translate}}" [width]="200">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="datetime" [(ngModel)]="dataItem.startDate"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.startDate | date}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="endDate" title="{{'Movement End Date' | translate}}" [width]="200">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="datetime" [(ngModel)]="dataItem.endDate"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.endDate | date}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="remarks" title="{{'Remarks' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control type="textarea" [(ngModel)]="dataItem.remarks"></app-control>
                    </ng-template>
                </kendo-grid-column>
            </app-editable-grid>
        </div>
    </div>
</ng-template>

<ng-template #pilotDetailsContent>
    <div class="row">
        <div class="col-3">
            <app-control label='Pilot Time On (Arrival)' type="datetime" [(ngModel)]="model.pilotStationArrivalOn"
                [isDisabled]="true">
            </app-control>
        </div>
        <div class="col-3">
            <app-control label='Pilot Time Off (Arrival)' type="datetime" [(ngModel)]="model.pilotStationArrivalOff"
                [isDisabled]="true">
            </app-control>
        </div>
        <div class="col-3">
            <app-control label='Pilot Time On (Departure)' type="datetime" [(ngModel)]="model.pilotStationDepartureOn"
                [isDisabled]="true">
            </app-control>
        </div>
        <div class="col-3">
            <app-control label='Pilot Time Off(Departure)' type="datetime" [(ngModel)]="model.pilotStationDepartureOff"
                [isDisabled]="true">
            </app-control>
        </div>

    </div>
</ng-template>

<ng-template #vesselDetailsContent>
    <app-vessel-data [model]="model.vessel" [canEdit]="false"></app-vessel-data>
</ng-template>

<ng-template #remarksContent>
    <div class="row mt-3">
        <div class="col-12">
            <app-control label="General Remarks" [(ngModel)]="model.generalRemarks"></app-control>
        </div>
        <div class="col-12">
            <app-control label="Cancel Remarks" [(ngModel)]="model.cancelRemarks" [isDisabled]="true"></app-control>
        </div>
        <div class="col-12">
            <app-control label="Anchorage Remark" [(ngModel)]="model.anchorageRemark" [isDisabled]="true"></app-control>
        </div>
        <div class="col-12">
            <app-control label="ATA Remarks" [(ngModel)]="model.ataRemarks" [isDisabled]="true"></app-control>
        </div>
        <div class="col-12">
            <app-control label="ATD Remarks" [(ngModel)]="model.atdRemarks" [isDisabled]="true"></app-control>
        </div>
    </div>
</ng-template>
