import { Component, OnInit } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { WarehouseDocumentPermissions } from '@common/classes/permissions';
import { CodelistService } from '@common/services/codelist.service';
import { CommonService } from '@common/services/common.service';
import { EditEvent } from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-warehouse-document-view',
    templateUrl: './warehouse-document-view.component.html',
    styleUrls: ['./warehouse-document-view.component.scss'],
    providers: [CommonService]
})
export class WarehouseDocumentViewComponent extends BaseViewComponent implements OnInit {
    entityName = 'WarehouseDocument';
    editPermission = WarehouseDocumentPermissions.Action.Edit;
    createPermission = WarehouseDocumentPermissions.Action.Create;
    serviceRequest: any;
    warehouseOptions: any[] = [];

    breadcrumb = [
        {
            title: 'Cargo',
            icon: 'boxes-stacked',
        },
        {
            icon: 'boxes-stacked',
            title: 'Warehouse Documents',
            route: '/warehouse-document/list'
        },
        {
            icon: 'box',
            title: 'Warehouse Document View'
        }
    ];
    override arrayProperties = ['items', 'attachments'];

    constructor(common: CommonService, private codelistService: CodelistService) {
        super(common);
        this.actionBar.push({
            label: 'Actions',
            items: [
                {
                    label: 'Cancel',
                    icon: 'times',
                    isVisible: () => this.canCancel(),
                    onClick: () => this.cancel(),
                },
                {
                    label: 'Complete',
                    icon: 'check',
                    isVisible: () => this.canComplete(),
                    onClick: () => this.executeCommand('CompleteWarehouseDocument')
                }
            ],
            permissions: [WarehouseDocumentPermissions.Action]
        });
    }

    override getIdentifier = () => this.model.yearNumber;

    override async modelLoaded() {
        await super.modelLoaded();

        const data = await this.cqrs.query('Warehouses',
        {
            sourceId: this.serviceRequest.sourceId,
            destinationId: this.serviceRequest.destinationId
        }) as any[];

        this.warehouseOptions = data.map(g => ({ value: g.id, label: g.name }));
    }

    override canEdit() {
        return super.canEdit() && (!['CAN', 'COM'].includes(this.model.statusId));
    }

    override async createEntity() {
        super.createEntity();
        const serviceRequestId = +this.router.routerState.snapshot.root.queryParams.serviceRequestId;
        if (!serviceRequestId) {
            this.toastrService.error(this.translateService.instant('Service request not specified'));
            return this.navigateToList();
        }
        await this.fetchServiceRequest(serviceRequestId);
        this.model.items.push(...this.serviceRequest.cargoItems.map(item => ({
            cargoTypeId: item.cargoTypeId,
            packagingTypeId: item.packagingTypeId,
            quantity: item.quantity,
            grossWeight: item.weight,
            netWeight: item.weight,
            isQuantityDisabled: item.quantity === 0
        })));
    }

    override async loadModel(): Promise<void> {
        await super.loadModel();
        await this.fetchServiceRequest(this.model.serviceRequest.id);
    }

    async fetchServiceRequest(serviceRequestId: number) {
        this.serviceRequest = await this.cqrs.query('ServiceRequest', { id: serviceRequestId });
        this.model.serviceRequestId = serviceRequestId;
        this.model.serviceRequestNumber = this.serviceRequest.yearNumber;
        this.model.typeId = { 'EXPORT': 'REC', 'IMPORT': 'DEL', 'DIRECT': 'DIR' }[this.serviceRequest.type.id];
        this.expandModelProperties(this.serviceRequest);
    }

    goToServiceRequest() {
        if (!this.model.serviceRequestId) return;
        window.open(`/service-request/view/${this.model.serviceRequestId}`, '_blank');
    }

    canCancel() {
        return this.viewMode && this.model.statusId !== 'CAN' && this.user.hasPermission(WarehouseDocumentPermissions.Action.Cancel);
    }

    canComplete() {
        return this.viewMode && this.model.statusId === 'SAV' && this.user.hasPermission(WarehouseDocumentPermissions.Action.Complete);
    }

    async cancel() {
        const data = await this.dialogService.confirm('Warehouse Document Cancellation', 'Are you sure you want to cancel this warehouse document?');
        if (!data) return;
        this.executeCommand('CancelWarehouseDocument');
    }

    editItemRow(event: EditEvent) {
        event.dataItem.isQuantityDisabled = event.dataItem.quantity === 0;
    }
}
