import { BaseListComponent } from '@common/classes/base-list';
import { AdministrationPermissions } from '@common/classes/permissions';
import { Component } from '@angular/core';
import { CommonService } from '@common/services/common.service';


@Component({
    selector: 'app-role-list',
    templateUrl: './role-list.component.html',
    styleUrls: ['./role-list.component.scss'],
    providers: [CommonService]
})

export class RoleListComponent extends BaseListComponent {

    breadcrumb = [
        {
            title: 'Administration',
            icon: 'cog'
        },
        {
            icon: 'user-plus',
            title: 'Roles'
        }
    ];
    queryName = 'Roles';
    createPermission = AdministrationPermissions.Role.Create;
    constructor(commonService: CommonService) {
        super(commonService);
    }
}
