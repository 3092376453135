<div class="modal-header">
    <h4 class="modal-title" translate>New Service Request</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-stock-record-items-grid [queryAll]="true" [sourceDestination]="sourceDestination"></app-stock-record-items-grid>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="close()" translate>Cancel</button>
    <button class="btn btn-primary" (click)="confirm()" translate [disabled]="!stockRecordGrid?.selection?.length">Confirm</button>
</div>
