<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-3">
            <app-control label="Vessel Name" [(ngModel)]="filter.vesselId" type="codelist"
                codelist="ApprovedVessel"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Process" [(ngModel)]="filter.processId" type="codelist"
                codelist="OperationType"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Cargo Type" [(ngModel)]="filter.cargoTypeId" type="codelist"
                codelist="CargoType"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Berth" [(ngModel)]="filter.berthId" type="codelist"
                codelist="Berth"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <app-control label="Docking From" [(ngModel)]="filter.dockingFrom" type="datetime"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Docking To" [(ngModel)]="filter.dockingTo" type="datetime"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Agency" [(ngModel)]="filter.agencyId" type="codelist"
                codelist="Organization"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Shift Date" [(ngModel)]="filter.shiftDate" type="datetime" [time]="false"></app-control>
        </div>
    </div>
</app-filter>
<div class="card mt-3">
    <div class="row">
        <div [ngClass]="{'active-tab': activeCard === 0}" (click)="activateCard(0)"
            class="col mt-2 mb-2 text-center border-right pointer">
            <br />
            <h3 translate>DAILY</h3>
            <br />
        </div>
        <div [ngClass]="{'active-tab': activeCard === 1}" (click)="activateCard(1)"
            class="col mt-2 mb-2 text-center border-right pointer">
            <br />
            <h3 translate>SUMMARY</h3>
            <br />
        </div>
        <div [ngClass]="{'active-tab': activeCard === 2}" (click)="activateCard(2)"
            class="col mt-2 mb-2 text-center border-right pointer">
            <br />
            <h3 translate>III SHIFT</h3>
            <br />
        </div>
    </div>
</div>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <kendo-tabstrip (tabSelect)="onTabSelect($event)">
            <kendo-tabstrip-tab title="{{'Eastern Terminal' | translate}}" [selected]="true">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="terminalContent"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'General Cargo Terminal' | translate}}">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="terminalContent"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'Container Terminal' | translate}}">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="containerTerminal"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'A&V Gas Terminal' | translate}}">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="terminalContent"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'MBM Terminal' | translate}}">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="terminalContent"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>
</div>
<ng-template #terminalContent>
    <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()">
        <kendo-grid-column field="vesselName" title="{{'Vessel' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="processName" title="{{'Process' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="cargoTypeName" title="{{'Cargo Type' | translate}}" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="weight" title="{{'Weight' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column title="{{'Realized / TO DO' | translate}}"><!-- Export missing -->
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{activeCard === 1 ? item.realizedAll : item.realizedDaily}} / {{item.remaining}}</span>
                </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [title]="'Anchorage Date' | translate" field="anchorageTime">
            <ng-template kendoGridCellTemplate let-item>
                <span>{{item.anchorageTime | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [title]="'Docking' | translate" field="ata">
            <ng-template kendoGridCellTemplate let-item>
                <span>{{item.ata | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [title]="'Start Date' | translate" field="startDate">
            <ng-template kendoGridCellTemplate let-item>
                <span>{{item.startDate | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [title]="'End Date' | translate" field="endDate">
            <ng-template kendoGridCellTemplate let-item>
                <span>{{item.endDate | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [title]="'Departure' | translate" field="atd">
            <ng-template kendoGridCellTemplate let-item>
                <span>{{item.atd | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="berthNames" title="{{'Berth' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="agencyName" title="{{'Agency' | translate}}">
        </kendo-grid-column>
    </app-grid>
</ng-template>

<ng-template #containerTerminal>
    <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()"
        detailItems="operations" [detailParams]="detailParams" exportFileName="ContainerTerminal">
        <kendo-grid-column field="vesselName" title="{{'Vessel Name' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="realization" title="{{'Realization' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="remaining" title="{{'Remaining' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="anchorageTime" title="{{'Anchorage Date' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.anchorageTime | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dockingTime" title="{{'Docking' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.dockingTime | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="operationsStart" title="{{'Starting Date' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.operationsStart | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="operationsEnd" title="{{'End Date' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.operationsEnd | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="atd" title="{{'Leaving' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.atd | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="exitToQueue" title="{{'Exit To Anchorage' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.exitToQueue | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="berthNames" title="{{'Berth' | translate}}">
        </kendo-grid-column>
        <ng-template kendoGridDetailTemplate let-dataItem>
            <div class="row">
                <app-grid [data]="dataItem.operations">
                    <kendo-grid-column field="process" title="{{'Process' | translate}}">
                    </kendo-grid-column>

                    <kendo-grid-column field="containerTypeName" title="{{'Container Type' | translate}}">
                    </kendo-grid-column>

                    <kendo-grid-column field="quantityPiece" title="{{'Quantity Of Containers (Piece)' | translate}}">
                    </kendo-grid-column>

                    <kendo-grid-column field="quantityTeu" title="{{'Quantity Of Containers (TEU)' | translate}}">
                    </kendo-grid-column>

                    <kendo-grid-column field="realizationPiece" title="{{'Realization Per Shift (Piece)' | translate}}">
                    </kendo-grid-column>

                    <kendo-grid-column field="realizationTeu" title="{{'Realization Per Shift (TEU)' | translate}}">
                    </kendo-grid-column>

                    <kendo-grid-column field="remainingPiece" title="{{'Remaining (Piece)' | translate}}">
                    </kendo-grid-column>

                    <kendo-grid-column field="remainingTeu" title="{{'Remaining (TEU)' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column field="agency" title="{{'Agency' | translate}}">
                    </kendo-grid-column>
                </app-grid>
            </div>
        </ng-template>
    </app-grid>
</ng-template>
