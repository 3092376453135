import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { DialogService } from '@common/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

export interface IDeactivatableComponent {
    hasUnsavedChanges: boolean;
    cancelChanges(): void;
    saveChanges(): Promise<void>;
}

@Injectable()
export class CanDeactivateView<T extends IDeactivatableComponent> implements CanDeactivate<T> {
    constructor(
        private dialogService: DialogService,
        private translateService: TranslateService) { }

    canDeactivate(component: T) {
        return new Promise<boolean>(async (resolve, reject) => {
            if (!component.hasUnsavedChanges) return resolve(true);
            const value = await this.dialogService.changes(this.translateService.instant('Unsaved changes'), this.translateService.instant('There may be some unsaved changes. Do you want to save them?'));
            if (value === true) {
                await component.saveChanges();
                component.hasUnsavedChanges = false;
            }
            resolve(true);
        });
    }
}
