import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '../common/common.module';
import { AdministrationPermissions } from '@common/classes/permissions';
import { UserModule } from './user/user.module';
import { OrganizationModule } from './organization/organization.module';
import { RoleModule } from './role/role.module';

const ADMINISTRATION_ROUTES: Routes = [
    {
        path: 'user',
        loadChildren: () => UserModule,
        data: { title: 'Users' }
    },
    {
        path: 'organization',
        loadChildren: () => OrganizationModule,
        data: { title: 'Organizations' }
    },
    {
        path: 'role',
        loadChildren: () => RoleModule,
        data: { title: 'Roles' }
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(ADMINISTRATION_ROUTES)],
    exports: [],
    declarations: [],
    providers: []
})
export class AdministrationModule {

}
