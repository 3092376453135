import { ControlSize } from '@common/classes/control-size';
import { ViewMode } from '@common/models/view-mode';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';

export const environment = {
    production: false,
    name: 'base',
    serverUrl: `${window.location.protocol}//${window.location.hostname}:5000`,
    apiUrl: `${window.location.protocol}//${window.location.hostname}:5000/api`,
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    title: 'PCS Durres',
    version: '0.1.0',
    defaultLanguage: 'en',
    settings: {
        header: {
            title: '',
            logo: '/assets/img/durres-logo.svg',
            welcomeMessage: 'Welcome to Durres Port Authority Port Community System',
            environmentColor: '#ffc0cb'
        },
        appControl: {
            format: 'n2',
            size: ControlSize.normal,
            multi: false,
            time: true,
            codelist: {
                take: 100
            },
            fileDownloadUrl: 'api:///query/DownloadAttachment',
            fileRestrictions: {
                allowedExtensions: [
                    '.jpg', '.jpeg', '.png', '.bmp', '.tif', // Images
                    '.doc', '.docx', '.xls', '.xlsx', '.txt', '.pdf', 'xslt' // Documents
                ],
                maxFileSize: 5_242_880,
                minFileSize: 1000,
            },
            dropdown: {
                popupSettings: undefined as PopupSettings
            }
        },
        grid: {
            pageable: { buttonCount: 4, info: true, type: 'numeric', pageSizes: [10, 20, 50] },
            pageSize: 10,
            sortable: false,
            sort: [
                {
                    field: 'id',
                    dir: 'desc'
                }
            ]
        },
        view: {
            defaultViewMode: ViewMode.view,
            save: {
                redirectToViewMode: ViewMode.view
            }
        },
        list: {
            persistFilter: true,
            rememberFilterState: true
        },
        actionBar: {
            rememberState: true
        },
        validation: {
            errors: {
                showTooltip: true,
                showFeedback: false
            }
        },
        roundingSettings: {
            price: 3,
            quantity: 3,
            amount: 2,
            taxAmount: 2,
            discountPercentage: 2
        }
    }
};
