import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RememberStateService {
    private rememberState = environment.settings.actionBar.rememberState;

    initialize(storageKey: string): any {
        if (this.rememberState) {
            const savedState = localStorage.getItem(storageKey);
            if (savedState) {
                return isNaN(Number(savedState)) ?  savedState === 'true' : Number(savedState);
            }
        }
    }

    setState(storageKey: string, value: any): void {
        localStorage.setItem(storageKey, value.toString());
    }
}
