import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';

@Component({
    selector: 'app-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentHeaderComponent {
    @Input() title: string;
    @Input() breadcrumb: any[];

    onItemClick(event: BreadCrumbItem) {}
}
