<app-loader [isBusy]="!model || isBusy">
    <ng-template>
        <app-action-bar [items]="actionBar" [collapsed]="false"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
        <app-vessel-data [model]="model" [canEdit]="true"></app-vessel-data>
        <div class="row mt-3">
            <div class="col">
                <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                    <kendo-tabstrip-tab title="{{'Vessel Documents' | translate}}" [selected]="true">
                        <ng-template kendoTabContent>
                            <div class="row mt-3">
                                <div class="col">
                                    <app-documents-grid [model]="model" [editMode]="editMode"
                                        [showVersionColumn]="true"></app-documents-grid>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'History' | translate}}">
                        <ng-template kendoTabContent>
                            <div class="row mt-3">
                                <div class="col-4">
                                    <app-control label="Created By" type="codelist" codelist="User"
                                        [(ngModel)]="model.createdById" [isDisabled]="true"></app-control>
                                </div>
                                <div class="col-4">
                                    <app-control label="Created Date" type="datetime"
                                        [(ngModel)]="model.createdDate" [isDisabled]="true"></app-control>
                                </div>
                                <div class="col-4">
                                    <app-control label="Approved By" type="codelist" codelist="User"
                                        [(ngModel)]="model.approvedById" [isDisabled]="true"></app-control>
                                </div>
                                <div class="col-4">
                                    <app-control label="Approved Date" type="datetime"
                                        [(ngModel)]="model.approvedDate" [isDisabled]="true"></app-control>
                                </div>
                                <div class="col-4">
                                    <app-control label="Modified By" type="codelist" codelist="User"
                                        [(ngModel)]="model.lastModifiedById" [isDisabled]="true"></app-control>
                                </div>
                                <div class="col-4">
                                    <app-control label="Modified Date" type="datetime"
                                        [(ngModel)]="model.lastModifiedDate" [isDisabled]="true"></app-control>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>
    </ng-template>
</app-loader>
