import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RememberStateService } from '@common/services/remember-state.service';
import { NgbAccordion, NgbPanel } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-accordion-header',
    template: `
    <div class="accordion-header-container pointer" (click)="toggle()">
        <div class="accordion-title">
            <ng-content></ng-content>
        </div>
        <div class="chevron d-flex align-items-center">
            <fa-icon [icon]="panel.isOpen ? 'angle-up' : 'angle-down'"></fa-icon>
        </div>
    </div>
    `,
    styles: [
        `
        .accordion-header-container {
            display: flex;
        }
        .chevron {
            margin-left: auto;
        }
        .pointer {
            cursor: pointer;
        }
        `
    ]
})
export class AccordionHeaderComponent implements OnInit {
    @Input() panel: NgbPanel;
    @Input() disabled: boolean = false;
    @Input() closeOtherPanels: boolean;

    @Output() panelChange = new EventEmitter();

    constructor(
        public accordion: NgbAccordion,
        private rememberStateService: RememberStateService,
        private router: Router
    ) {
        this.panelChange = this.accordion.panelChange;
    }

    ngOnInit() {
        const panelState = this.rememberStateService.initialize(`panel${this.router.url}`);

        if (panelState) {
            setTimeout(() => {
                this.accordion.expand(this.panel.id);
            });
        }
        if (!panelState) {
            setTimeout(() => {
                this.accordion.collapse(this.panel.id);
            });
        }
        // Angular change detection workaround
        setTimeout(() => {
            this.accordion.closeOtherPanels = this.closeOtherPanels;
        });
    }

    toggle() {
        if (!this.disabled) this.accordion.toggle(this.panel.id);
        this.rememberStateService.setState(`panel${this.router.url}`, this.panel.isOpen);
    }

    close() {
        if (this.panel.isOpen) {
            this.accordion.toggle(this.panel.id);
        }
    }
}
