import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-filter',
    templateUrl: './app-filter.component.html',
    styleUrls: ['./app-filter.component.scss']
})
export class AppFilterComponent {
    @Output() clearFilter = new EventEmitter();
    @Output() search = new EventEmitter();

    @HostListener('document:keyup.enter', ['$event']) onEnterPress(event: KeyboardEvent) {
        this.search.emit();
    }
}
