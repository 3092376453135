<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Vessels" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col">
            <app-control label="Name" [(ngModel)]="filter.name"></app-control>
        </div>
        <div class="col">
            <app-control label="IMO Number" [(ngModel)]="filter.imoNumber" type="number"></app-control>
        </div>
        <div class="col">
            <app-control label="Vessel Type" [(ngModel)]="filter.typeId" type="codelist"
                codelist="VesselType"></app-control>
        </div>
        <div class="col">
            <app-control label="Flag" [(ngModel)]="filter.countryId" type="codelist" codelist="Country"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <app-control label="Waiting Approval" type="boolean" [(ngModel)]="filter.waitingApproval"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()" [rowClass]="notApprovedRow">
            <kendo-grid-column [title]="'Name' | translate" field="name">
                <ng-template kendoGridCellTemplate let-row>
                    <div [routerLink]="['/vessel', defaultViewMode, row.id]">
                        <a class="text-primary link-box"> {{row.name}} </a>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'IMO Number' | translate" field="imo"></kendo-grid-column>
            <kendo-grid-column [title]="'Call Sign' | translate" field="callSign"></kendo-grid-column>
            <kendo-grid-column [title]="'Vessel Type' | translate" field="typeId">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.typeId | codelist:'VesselType' | async}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Flag' | translate" field="countryId">
                <ng-template kendoGridCellTemplate let-item>
                    <app-flag-icon [code]="item.countryId" codelist="Country"></app-flag-icon>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Length (m)' | translate" field="lengthOverall"></kendo-grid-column>
            <kendo-grid-column [title]="'Active' | translate" field="active">
                <ng-template kendoGridCellTemplate let-item>
                    <app-control type="boolean" [isDisabled]="true" [ngModel]="item.active"></app-control>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'Approved' | translate" field="approved" [class]="{ approvedColumn: true }">
                <ng-template kendoGridCellTemplate let-item>
                    <app-control type="boolean" [isDisabled]="true" [ngModel]="item.approved"></app-control>
                </ng-template>
            </kendo-grid-column>
        </app-grid>
    </div>
</div>
