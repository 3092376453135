import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { RoleListComponent } from './components/role-list/role-list.component';
import { RoleViewComponent } from './components/role-view/role-view.component';

export const ROLE_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: RoleListComponent
    },
    {
        path: 'view/:id',
        component: RoleViewComponent,
        data: {
            mode: 'view'
        }
    },
    {
        path: 'edit/:id',
        component: RoleViewComponent,
        data: {
            mode: 'edit'
        }
    },
    {
        path: 'create',
        component: RoleViewComponent,
        data: {
            mode: 'create'
        }
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];
@NgModule({
    imports: [CommonModule, RouterModule.forChild(ROLE_ROUTES)],
    exports: [],
    declarations: [RoleListComponent, RoleViewComponent],
    providers: []
})
export class RoleModule { }
