import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { CommonService } from '@common/services/common.service';
import { format } from 'date-fns';

@Component({
    selector: 'app-ferry-terminal',
    templateUrl: './ferry-terminal.component.html',
    styleUrls: ['./ferry-terminal.component.scss'],
    providers: [CommonService]
})
export class FerryTerminalComponent extends BaseListComponent {
    queryName = 'FerriesTerminal';
    title = `${this.translateService.instant('Daily record for ferries')} (${format(new Date(), 'dd.MM.yyyy')})`;
    exportExcelCommand = 'ExportFerryTerminal';

    breadcrumb = [
        {
            title: 'Reporting',
            icon: 'file-lines'
        },
        {
            icon: 'ship',
            title: 'Ferry (AFTO) terminal'
        }
    ];

    canCreateNew = () => false;
    constructor(common: CommonService) { super(common); }

    override getDefaultFilter = () => ({
        date: new Date()
    });
}
