import { Router } from '@angular/router';
import { CqrsService } from '@common/services/cqrs.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

@Component({
    selector: 'app-service-request-create',
    templateUrl: './service-request-create.component.html',
    styleUrls: ['./service-request-create.component.scss'],
})
export class ServiceRequestCreateComponent implements OnInit {

    model: any = {};
    typeTransitions: any[] = [];
    isExport = false;
    sourceIds: string[];

    constructor(public activeModal: NgbActiveModal, private cqrs: CqrsService) { }

    confirm() {
        this.activeModal.close(this.model);
    }

    async fetchServiceRequestTypeTransitions(typeId: string) {
        const data = await this.cqrs.query('ServiceRequestTypeTransitions', { typeId, sourceIds: this.sourceIds }) as any[];
        this.typeTransitions = data.map(({ source, destination }) => ({ label: `${source.localizedCode} - ${destination.localizedCode}`, value: `${source.id}-${destination.id}` }));
    }

    ngOnInit() {
        if (this.isExport) this.serviceRequestTypeChange(this.model.typeId);
    }

    serviceRequestTypeChange(typeId: string) {
        this.model.sourceDestination = null;
        if (!typeId) this.typeTransitions = [];
        else this.fetchServiceRequestTypeTransitions(typeId);
    }
}
