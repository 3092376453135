<div class="row" *ngIf="queryAll">
    <div class="col-3">
        <app-control label="Cargo Type" type="codelist" codelist="CargoType" [(ngModel)]="filter.cargoTypeId"></app-control>
    </div>
    <div class="col-3">
        <app-control label="Packaging Type" type="codelist" codelist="PackagingType" [(ngModel)]="filter.packagingTypeId"></app-control>
    </div>
    <div class="col-3">
        <app-control label="Warehouse" type="codelist" codelist="Warehouse" [(ngModel)]="filter.warehouseId"></app-control>
    </div>
    <div class="col-1">
        <button class="btn btn-sm btn-danger mr-2" (click)="clearFilter()">
            <fa-icon class="ml-1" icon="trash"></fa-icon>&nbsp;<span class="btn-text" translate>Clear</span>
        </button>
    </div>
    <div class="col-1">
        <button class="btn btn-sm btn-primary" (click)="search()">
            <fa-icon class="ml-1" icon="search"></fa-icon>&nbsp;<span class="btn-text" translate>Search</span>
        </button>
    </div>
    <div class="col-1">
        <button class="btn btn-sm btn-primary" (click)="selection = []">
            <fa-icon class="ml-1" icon="xmark"></fa-icon>&nbsp;<span class="btn-text" translate>Clear Selection</span>
        </button>
    </div>
</div>
<div class="row mt-3">
    <div class="col">
        <app-grid [data]="gridData" [selectable]="true" [(selection)]="selection"
            (selectionChange)="selectionChange.emit($event)" (pageChange)="search()">
            <kendo-grid-checkbox-column [showSelectAll]="true" [width]="30"></kendo-grid-checkbox-column>
            <kendo-grid-column field="cargoType.name" title="{{'Cargo Type' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="packagingType.name" title="{{'Packaging Type' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="grossWeight" title="{{'Gross Weight' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="netWeight" title="{{'Net Weight' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="warehouseDocumentItem.warehouse.name" title="{{'Warehouse' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column *ngIf="isSilos" field="silosClearance.name" title="{{'Silos Clearance' | translate}}">
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{getLastInspectionRecord(row)?.silosClearanceId | codelist:'SilosClearance' | async}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="isYard" field="yardState" title="{{'Yard State' | translate}}">
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{getLastInspectionRecord(row)?.yardState}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="isYard" field="surface" title="{{'Occupied Surface (m²)' | translate}}">
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{getLastInspectionRecord(row)?.surface}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf="isYard || isSilos" field="lastInspectionDate" title="{{'Last Inspection Date' | translate}}">
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{getLastInspectionRecord(row)?.lastInspectionDate | date}}</span>
                </ng-template>
            </kendo-grid-column>
            <ng-template *ngIf="isSilos || isYard" kendoGridDetailTemplate let-dataItem>
                <app-grid [data]="dataItem.inspectionRecords" [sortable]="false" [sort]="[{ field: 'createdDate', dir: 'desc' }]">
                    <kendo-grid-column *ngIf="isSilos" field="silosClearance.name" title="{{'Silos Clearance' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            <span>{{row.silosClearanceId | codelist:'SilosClearance' | async}}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="isYard" field="yardState" title="{{'Yard State' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="isYard" field="surface" title="{{'Occupied Surface (m²)' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="isYard || isSilos" field="lastInspectionDate" title="{{'Last Inspection Date' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            <span>{{row.lastInspectionDate | date}}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="createdBy.userName" title="{{'Entered By' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            <span>{{row.createdById | codelist:'User' | async}}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="lastModifiedBy.userName" title="{{'Last Modified By' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            <span>{{row.lastModifiedById | codelist:'User' | async}}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="lastModifiedDate" title="{{'Last Modified Date' | translate}}">
                        <ng-template kendoGridCellTemplate let-row>
                            <span>{{row.lastModifiedDate | date}}</span>
                        </ng-template>
                    </kendo-grid-column>
                </app-grid>
            </ng-template>
        </app-grid>
    </div>
</div>
