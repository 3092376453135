import { Component, ViewChild } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { VesselPermissions } from '@common/classes/permissions';
import { CommonService } from '@common/services/common.service';
import { VesselDataComponent } from '../vessel-data/vessel-data.component';

@Component({
    selector: 'app-vessel-view',
    templateUrl: './vessel-view.component.html',
    styleUrls: ['./vessel-view.component.scss'],
    providers: [CommonService]
})
export class VesselViewComponent extends BaseViewComponent {
    entityName = 'Vessel';
    breadcrumb = [
        {
            title: 'Vessels',
            icon: 'ship',
            route: '/vessel/list'
        },
        {
            title: 'Vessel View',
            icon: 'ship'
        }
    ];
    override arrayProperties = ['documents'];

    @ViewChild(VesselDataComponent) vesselData: VesselDataComponent;

    editPermission = VesselPermissions.Action.Edit;
    createPermission = VesselPermissions.Action.Create;

    override getIdentifier = () => this.model.name;

    constructor(commonService: CommonService) {
        super(commonService);
        this.actionBar.push({
            label: 'Actions',
            items: [
                {
                    label: 'Approve',
                    icon: 'check',
                    isVisible: () => this.canApprove(),
                    onClick: () => this.approve()
                },
            ]
        });
    }

    override saveChanges(options?: any) {
        if (this.vesselData.appControls.some(x => !!x.error)) {
            this.toastrService.error(this.translateService.instant('Some required fields are empty'));
            return;
        }
        return super.saveChanges();
    }

    canApprove() {
        return this.viewMode && !this.model.approved && this.user.hasPermission(VesselPermissions.Action.Approve);
    }

    approve() {
        this.model.approved = true;
        this.model.approvedById = this.user.id;
        this.model.approvedDate = new Date();
        this.saveChanges();
    }
}
