import { environment } from '@environments/environment.local';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UploadActionButtonsComponent } from './upload-action-buttons.component';

@Component({
    template: `
        <div class="modal-header">
            <div class="row" style="width: 100%">
                <div class="col-10">
                    <h4 class="modal-title">{{ title }}</h4>
                </div>
                <div class="col-2">
                    <button class="k-button"
                        [attr.aria-label]="'Download' | translate"
                        [attr.title]="'Download' | translate"
                        *ngIf="canDownload()"
                        (click)="download()">
                        <fa-icon icon="download"></fa-icon>
                    </button>
                    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-body">

            <div class="alert alert-danger" role="alert">
                <div [innerHTML]="message"></div>
            </div>

            <div *ngIf="hasStackTrace()">
                <div class="panel panel-danger">
                    <div class="panel-heading" translate><b>StackTrace</b></div>
                    <div class="panel-body">
                        <pre style="color: #c7254e; max-height: 500px; overflow-y: scroll;" [innerHTML]="stackTrace"></pre>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="activeModal.close(true)" translate>Ok</button>
        </div>
    `,
})
export class ErrorDialogComponent {
    stackTrace: string;
    message: string;
    isServerSideError: boolean;
    titleText: string;
    validationErrors: any[];

    constructor(
        public activeModal: NgbActiveModal,
        private translateService: TranslateService) {
    }

    get title() {
        if (this.titleText) {
            return this.titleText;
        }

        return this.isServerSideError
            ? this.translateService.instant('System Error')
            : this.translateService.instant('Client side error occurred');
    }

    hasStackTrace() {
        return !!this.stackTrace && ['local', 'development'].includes(environment.name);
    }

    setMessage(message, validationErrors) {
        this.validationErrors = validationErrors;
        if (!message || !_.isString(message)) {
            console.error(message);
            message = this.translateService.instant('Check the console for error details');
        }

        const lines = message.indexOf('\r\n') < 0 ? message.split('\n') : message.split('\r\n');
        let html = '<span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>';
        html += `<span> ${lines[0]}</span>`;

        // Client side error message contains also the stacktrace which we do not want to display
        if (lines.length === 1 || !this.isServerSideError) {
            this.message = html;
            return;
        }

        html += '<br />';
        for (let i = 1; i < lines.length; i++) {
            html += `<span>${lines[i]}</span><br />`;
        }

        this.message = html;
    }

    setStackTrace(stackTrace) {
        if (stackTrace && !_.isString(stackTrace)) {
            console.error(stackTrace);
            stackTrace = stackTrace.toString();
        }

        this.stackTrace = stackTrace;
    }

    canDownload() {
        return !!this.validationErrors;
    }

    download() {
        let content = 'Property name;Error message\r\n';
        _.each(this.validationErrors, error => {
            content += `${error.propertyName};${error.errorMessage}\r\n`;
        });

        UploadActionButtonsComponent.download('errors.csv', window.btoa(content));
    }
}
