import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { ExportType } from '@common/classes/export-type';
import { CommonService } from '@common/services/common.service';
import { format } from 'date-fns';

@Component({
    selector: 'app-silos-report',
    templateUrl: './silos-report.component.html',
    styleUrls: ['./silos-report.component.scss'],
    providers: [CommonService]
})
export class SilosReportComponent extends BaseListComponent {
    queryName = 'SilosReport';
    title = `${this.translateService.instant('Silos report')} (${format(new Date(), 'dd.MM.yyyy')})`;
    exportExcelCommand = 'ExportSilosReport';
    detailParams = ['seqNr', 'warehouseName', 'silosClearanceName', 'lastInspectionDate1', 'lastInspectionDate2', 'lastInspectionDate3'];

    breadcrumb = [
        {
            title: 'Reporting',
            icon: 'file-lines'
        },
        {
            title: 'Silos Reporting',
            icon: 'ship'
        }
    ];

    canCreateNew = () => false;
    constructor(common: CommonService) { super(common); }

    override export(exportType: ExportType, customGrid?: any): Promise<void> {
        return Promise.resolve(this.appGrid.grid.saveAsExcel());
    }
}
