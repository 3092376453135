import { AppControlComponent } from '@common/components/app-control/app-control.component';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { SaveEvent } from '@progress/kendo-angular-grid';
import { AdministrationPermissions, VesselVisitPermissions } from '@common/classes/permissions';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { CommonService } from '@common/services/common.service';
import { ViewMode } from '@common/models/view-mode';
import { subDays } from 'date-fns';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-visit-view',
    templateUrl: './vessel-visit-view.component.html',
    styleUrls: ['./vessel-visit-view.component.scss'],
    providers: [CommonService]
})
export class VesselVisitViewComponent extends BaseViewComponent implements OnInit {
    entityName = 'VesselVisit';
    breadcrumb = [
        {
            icon: 'ship',
            title: 'Vessel Visits',
            route: '/vessel-visit/list'
        },
        {
            icon: 'ship',
            title: 'Vessel Visit View'
        }
    ];
    override arrayProperties = ['cargo', 'freightForwarders', 'agents', 'berths', 'documents', 'operations', 'standstills', 'movements'];

    VesselVisitPermissions = VesselVisitPermissions; // for HTML binding
    editPermission = VesselVisitPermissions.Action.Edit;
    createPermission = VesselVisitPermissions.Action.Create;
    freightForwarderOptions: any[] = [];
    cargoAgentOptions: any[] = [];
    departureAgentOptions: any[] = [];
    lastMovementTypeId: string;
    vesselCategoryId: any;
    cargoTypes: any[] = [];
    packagingTypes: any[] = [];
    isAdminUser: boolean;


    @ViewChild('berthControl', { static: false }) berthControl: AppControlComponent;

    constructor(commonService: CommonService) {
        super(commonService);
        this.actionBar.push({
            label: 'Actions',
            items: [
                {
                    label: 'Cancel',
                    icon: 'ban',
                    isVisible: () => this.canCancelVisit(),
                    onClick: () => this.cancelVisit()
                },
                {
                    label: 'On Anchorage',
                    icon: 'anchor',
                    isVisible: () => this.canAnchor(),
                    onClick: () => this.onAnchorage()
                },
                {
                    label: 'Insert ATA',
                    icon: 'right-to-bracket',
                    isVisible: () => this.canArrive(),
                    onClick: () => this.arrive()
                },
                {
                    label: 'Insert ATD',
                    icon: 'right-from-bracket',
                    isVisible: () => this.canDepart(),
                    onClick: () => this.depart()
                },
                {
                    label: 'Update Berth',
                    icon: 'location-dot',
                    isVisible: () => this.canUpdateBerth(),
                    onClick: () => this.updateBerth()
                },
                {
                    label: 'Approve',
                    icon: 'check',
                    isVisible: () => this.canApprove(),
                    onClick: () => this.approveVesselVisit()
                },
                {
                    label: 'Update Position',
                    icon: 'map-location-dot',
                    isVisible: () => this.canUpdatePosition(),
                    onClick: () => this.updatePosition()
                }
            ],
            permissions: [VesselVisitPermissions.Action]
        },
            {
                label: 'Operations',
                items: [
                    {
                        label: 'Operation Start',
                        icon: 'play',
                        isVisible: () => this.canEnterOperationalData(VesselVisitPermissions.Operations.OperationStart) && !this.model?.operationsStart,
                        onClick: () => this.enterOperationsDate(true)
                    },
                    {
                        label: 'Operation End',
                        icon: 'stop',
                        isVisible: () => this.canEnterOperationalData(VesselVisitPermissions.Operations.OperationEnd) && !!this.model?.operationsStart && !this.model?.operationsEnd,
                        onClick: () => this.enterOperationsDate(false)
                    },
                    {
                        label: 'Operational Data',
                        icon: 'table',
                        isVisible: () => this.canEnterOperationalData(VesselVisitPermissions.Operations.OperationalData) && this.vesselCategoryId !== 'PASS',
                        onClick: () => this.enterOperationalData()
                    },
                    {
                        label: 'Operational Progress',
                        icon: 'table',
                        isVisible: () => this.canEnterOperationalData(VesselVisitPermissions.Operations.OperationalProgress) && this.vesselCategoryId !== 'PASS',
                        onClick: () => this.enterOperationalProgress()
                    }
                ],
                permissions: [VesselVisitPermissions.Operations]
            },
            {
                label: 'Standstills',
                items: [
                    {
                        label: 'Add Standstill',
                        icon: 'pause',
                        isVisible: () => this.canAddStandstill(),
                        onClick: () => this.addStandstill()
                    }
                ],
                permissions: [VesselVisitPermissions.Standstills]
            },
            {
                label: 'Movements',
                items: [
                    {
                        label: 'Add Movement',
                        icon: 'up-down-left-right',
                        isVisible: () => this.canAddMovement(),
                        onClick: () => this.addMovement()
                    }
                ],
                permissions: [VesselVisitPermissions.Action]
            },
            {
                label: 'Pilot Time',
                items: [
                    {
                        label: ' Add pilot time (ARRIVAL)',
                        icon: 'clock',
                        isVisible: () => this.canAddPilotTime(true),
                        onClick: () => this.addPilotTime(true)
                    },
                    {
                        label: ' Add pilot time (DEPARTURE)',
                        icon: 'clock',
                        isVisible: () => this.canAddPilotTime(false),
                        onClick: () => this.addPilotTime(false)
                    }
                ],
                permissions: [VesselVisitPermissions.Action]
            });
    }

    override getIdentifier = () => this.model.yearNumber;

    override canEdit() {
        return super.canEdit() && (['ANN', 'ARR'].includes(this.model.statusId));
    }

    async ngOnInit() {
        super.ngOnInit();
        const { results: allOrganizations } = await this.common.cqrs.query('Organizations', { take: 1000, permissionFilter: false }) as any;
        const mapToCodelist = (orgs: any[]) => orgs.map(({ id: value, name: label }) => ({ value, label }));
        this.isAdminUser = this.user.isSystemUser || this.user.hasPermission(AdministrationPermissions.User.ViewAll);

        this.freightForwarderOptions = mapToCodelist(allOrganizations.filter(org => org.roles.some(r => r.roleId === 4))); // freight forwarders
        this.cargoAgentOptions = mapToCodelist(allOrganizations.filter(org => org.typeId === 'AGN')); // cargo agents
        this.departureAgentOptions = mapToCodelist(allOrganizations.filter(org => org.roles.some(r => r.roleId === 3))); // vessel agents
    }

    override async modelLoaded(): Promise<void> {
        super.modelLoaded();
        this.model.berths = this.model?.berths.map(b => b.berth?.id); // multi-select
        this.lastMovementTypeId = _.sortBy(this.model.movements, 'createDate').at(-1)?.typeId;
        this.vesselCategoryId = this.model.vessel?.type?.vesselCategory.id;
        this.fetchCargoTypes();
        this.fetchPackagingTypes();
    }

    override async afterSave(model: any) {
        // In case status refresh needed on view mode
        if (this.mode === ViewMode.view) await this.initialize();
        super.afterSave(model);
    }

    override navigateToEditMode(model?: any): void {
        // if (!this.user.isSystemUser && addHours(new Date(this.model.eta), 12).getTime() <= new Date().getTime()) {
        //     this.toastrService.error(this.translateService.instant('It is not possible to edit the data of the selected vessel visit because the ETA is less than 12 hours from the current time'));
        //     return;
        // }
        super.navigateToEditMode(model);
    }

    canCancelVisit() {
        return this.viewMode && this.user.hasPermission(VesselVisitPermissions.Action.Cancel) && this.model.statusId === 'ANN';
    }

    canAnchor() {
        return this.user.hasPermission(VesselVisitPermissions.Action.Anchor) && this.model.statusId === 'ANN';
    }

    async cancelVisit() {
        const data = await this.dialogService.form({
            title: 'Vessel Visit Cancellation',
            message: 'Are you sure you want to cancel this vessel visit?',
            properties: [{ name: 'cancelRemarks', label: 'Cancel Remarks' }]
        });
        if (!data) return;
        this.executeCommand('CancelVesselVisit', { cancelRemarks: data.cancelRemarks }, () => this.model.statusId = 'CAN');
    }

    canArrive() {
        const isAnchorage = this.lastMovementTypeId === 'BA';
        return this.viewMode && !isAnchorage &&
            ['ANN', 'ANC'].includes(this.model.statusId) &&
            (this.model.vessel.typeId === '116' ? this.user.hasPermission(VesselVisitPermissions.Action.YachtArrive) :
            this.user.hasPermission(VesselVisitPermissions.Action.Arrive));
    }

    async arrive() {
        const data = await this.dialogService.form({
            title: 'Vessel Visit ATA',
            canConfirm: (model) => model.ata,
            properties: [
                { name: 'ata', label: 'ATA', type: AppControlType.DateTime },
                { name: 'remarks', label: 'ATA Remarks', type: AppControlType.TextArea }
            ]
        });
        if (!data) return;
        this.executeCommand('ArriveVesselVisit', data);
    }

    canDepart() {
        return this.viewMode && this.model.statusId === 'ARR' &&
            (this.model.vessel.typeId === '116' ? this.user.hasPermission(VesselVisitPermissions.Action.YachtDeparture) :
            this.user.hasPermission(VesselVisitPermissions.Action.Depart));
    }

    async depart() {
        const data = await this.dialogService.form({
            title: 'Vessel Visit ATD',
            canConfirm: (model) => model.atd,
            properties: [
                { name: 'atd', label: 'ATD', type: AppControlType.DateTime },
                { name: 'remarks', label: 'ATD Remarks', type: AppControlType.TextArea }
            ]
        });
        if (!data) return;
        this.executeCommand('DepartVesselVisit', data);
    }

    async updateBerth() {
        const data = await this.dialogService.form({
            title: 'Enter Berth',
            canConfirm: (model) => model.berths?.length,
            properties: [{
                name: 'berths', label: 'Berth', type: AppControlType.CodeList, codelist: 'Berth', multi: true
            }]
        });
        if (!data?.berths) return;
        this.model.berths = data.berths;
        this.saveChanges();
    }

    async updatePosition() {
        const data = await this.dialogService.form({
            title: 'Update Position',
            canConfirm: (model) => model.positionId,
            properties: [{
                name: 'positionId', label: 'Position', type: AppControlType.CodeList, codelist: 'Position', required: true
            }]
        });
        if (!data?.positionId) return;
        this.model.positionId = data.positionId;
        this.saveChanges();
    }

    async onAnchorage() {
        const data = await this.dialogService.form({
            title: 'On Anchorage',
            properties: [
                { name: 'anchorageTime', label: 'Anchorage Time', type: AppControlType.DateTime, time: true },
                { name: 'remarks', label: 'Remark', type: AppControlType.TextArea }
            ]
        });
        if (!data) return;
        this.executeCommand('AnchorVesselVisit', data, () => {
            this.model.statusId = 'ANC';
            this.model.anchorageTime = data.anchorageTime;
            this.model.anchorageRemark = data.remarks;
        });
    }

    canEnterOperationalData(permission: string) {
        return !['CAN', 'DEP'].includes(this.model.statusId) && this.viewMode && this.user.hasPermission(permission);
    }

    async enterOperationsDate(isStart: boolean) {
        const property = isStart ? 'operationsStart' : 'operationsEnd';
        const data = await this.dialogService.form({
            title: `Enter Operations ${isStart ? 'Start' : 'End'}`,
            canConfirm: (model) => model[property],
            properties: [{ name: property, label: `Operations ${isStart ? 'Start' : 'End'} Date`, type: AppControlType.DateTime }]
        });
        if (!data?.[property]) return;
        this.model[property] = data[property];
        this.saveChanges();
    }

    async enterOperationalData() {
        const data = await this.dialogService.form({
            title: 'Enter Operational Data',
            canConfirm: (model) => model.dockingTime,
            properties: [
                {
                    name: 'dockingTime', label: 'Docking Time',
                    type: AppControlType.DateTime, time: true,
                    required: true, initialValue: this.model.dockingTime },
                {
                    name: 'numberOfCranes', label: 'Number Of Cranes',
                    type: AppControlType.Number, initialValue: this.model.numberOfCranes },
                {
                    name: 'processingMethodId', label: 'Processing Method',
                    type: AppControlType.CodeList, codelist: 'ProcessingMethod',
                    initialValue: this.model.processingMethodId
                },
                {
                    name: 'processingRate', label: 'Processing Rate',
                    type: AppControlType.Number, initialValue: this.model.processingRate
                },
                {
                    name: 'processingRemark', label: 'Processing Remark',
                    type: AppControlType.TextArea, initialValue: this.model.processingRemark
                }
            ]
        });
        if (!data) return;
        Object.keys(data).forEach(key => this.model[key] = data[key]);
        this.saveChanges();
    }

    async enterOperationalProgress() {
        const codelistFilter = (property: string) => (item: any) => this.model.cargo.some(c => c[property] === item.value);
        const data = await this.dialogService.form({
            title: 'Add Operational Progress',
            canConfirm: (model) => Object.keys(model).filter(k => !['remarks', 'weight', 'quantity'].includes(k)).every(prop => !!model[prop]),
            properties: [
                {
                    name: 'operationTypeId', label: 'Operation Type', type: AppControlType.CodeList, codelist: 'OperationType', required: true, colSpan: 4,
                    filter: codelistFilter('operationTypeId')
                },
                {
                    name: 'cargoTypeId', label: 'Cargo Type', type: AppControlType.CodeList, codelist: 'CargoType', required: true, colSpan: 4,
                    filter: codelistFilter('cargoTypeId')
                },
                {
                    name: 'packagingTypeId', label: 'Packaging Type', type: AppControlType.CodeList, codelist: 'PackagingType', required: true, colSpan: 4,
                    filter: codelistFilter('packagingTypeId')
                },
                { name: 'weight', label: 'Weight', type: AppControlType.Number, colSpan: 4 },
                { name: 'quantity', label: 'Quantity', type: AppControlType.Number, colSpan: 4 },
                { name: 'shiftId', label: 'Shift', type: AppControlType.CodeList, codelist: 'Shift', required: true, colSpan: 4 },
                { name: 'shiftDate', label: 'Shift Date', type: AppControlType.DateTime, time: false, required: true, colSpan: 4 },
                { name: 'remarks', label: 'Remarks', type: AppControlType.TextArea, colSpan: 12 },
            ]
        }, { size: 'xl' });
        if (!data) return;
        this.model.operations.push(data);
        this.saveChanges();
    }

    async changeDocumentStatus({ documentId, action }: { documentId: number, action: 'approve' | 'reject' }) {
        const data = await this.dialogService.form({
            title: `${action === 'approve' ? 'Approve' : 'Reject'} Vessel Visit Document`,
            properties: [{ name: 'remarks', label: 'Remarks', type: AppControlType.TextArea }]
        });
        if (!data) return;
        this.executeCommand('ChangeVesselVisitDocumentStatus', { documentId, remarks: data.remarks, action });
    }

    canAddStandstill(): boolean {
        return !['CAN', 'DEP'].includes(this.model.statusId) && this.viewMode && this.user.hasPermission(VesselVisitPermissions.Standstills.Add);
    }

    async addStandstill() {
        const data = await this.dialogService.form({
            title: 'Add Standstill',
            canConfirm: (model) => Object.keys(model).filter(k => k !== 'remarks').every(prop => !!model[prop]),
            properties: [
                { name: 'from', label: 'From', type: AppControlType.DateTime, required: true },
                { name: 'to', label: 'To', type: AppControlType.DateTime, required: true },
                { name: 'remarks', label: 'Remarks', type: AppControlType.TextArea },
            ]
        });
        if (!data) return;
        this.model.standstills.push({ ...data, createdDate: new Date(), createdById: this.user.id });
        this.saveChanges();
    }

    canAddMovement(): boolean {
        return !['CAN', 'DEP'].includes(this.model.statusId) && this.viewMode && this.user.hasPermission(VesselVisitPermissions.Add.Movement);
    }

    async addMovement() {
        const data = await this.dialogService.form({
            title: 'Add Movement',
            canConfirm: (model) => Object.keys(model).filter(k => k !== 'remarks').every(prop => !!model[prop]),
            properties: [
                {
                    name: 'typeId', label: 'Type', type: AppControlType.CodeList, codelist: 'MovementType', required: true, colSpan: 4,
                    filter: (item) => this.lastMovementTypeId === 'BB' ? true : item.value !== this.lastMovementTypeId
                },
                { name: 'fromId', label: 'From', type: AppControlType.CodeList, codelist: 'Berth', required: true, colSpan: 4 },
                { name: 'toId', label: 'To', type: AppControlType.CodeList, codelist: 'Berth', required: true, colSpan: 4 },
                { name: 'startDate', label: 'Start Date', type: AppControlType.DateTime, required: true, colSpan: 4 },
                { name: 'endDate', label: 'End Date', type: AppControlType.DateTime, required: true, colSpan: 4 },
                { name: 'remarks', label: 'Remarks', type: AppControlType.TextArea, colSpan: 12 },
            ]
        }, { size: 'xl' });
        if (!data) return;
        this.model.movements.push(data);
        this.saveChanges();
        await this.initialize();
    }

    canAddPilotTime(arrival: boolean): boolean {
        const direction = arrival ? 'Arrival' : 'Departure';
        return this.viewMode && this.user.hasPermission(VesselVisitPermissions.Add.PilotTime) && this.model.pilotId !== 'WP' && ['ANN', 'ARR'].includes(this.model.statusId) && !this.model[`pilotStation${direction}On`] &&
            !this.model[`pilotStation${direction}Off`] && (arrival ? true : !!this.model.pilotStationArrivalOn && !!this.model.pilotStationArrivalOff);
    }

    async addPilotTime(arrival: boolean) {
        const direction = arrival ? 'Arrival' : 'Departure';
        const data = await this.dialogService.form({
            title: `Add Pilot Time (${arrival ? 'ARRIVAL' : 'DEPARTURE'})`,
            canConfirm: (model) => !!model[`pilotStation${direction}On`] && !!model[`pilotStation${direction}Off`],
            properties: [
                {
                    name: `pilotStation${direction}On`, label: 'Pilot time on', type: AppControlType.DateTime,
                    required: true, disabledDates: (date: Date) => arrival ? false : date <= subDays(new Date(this.model.pilotStationArrivalOn), 1)
                },
                {
                    name: `pilotStation${direction}Off`, label: 'Pilot time off', type: AppControlType.DateTime,
                    required: true, disabledDates: (date: Date) => arrival ? false : date <= subDays(new Date(this.model.pilotStationArrivalOff), 1)
                }
            ]
        });
        if (!data) return;
        Object.keys(data).forEach(key => this.model[key] = data[key]);
        this.saveChanges();
    }

    canUpdateBerth() {
        return this.viewMode && ['ANN', 'ARR'].includes(this.model.statusId)
            && this.user.hasPermission(VesselVisitPermissions.Action.UpdateBerth);
    }

    canUpdatePosition() {
        return this.viewMode && !['CAN', 'DEP'].includes(this.model.statusId)
            && this.user.hasPermission(VesselVisitPermissions.Action.UpdatePosition);
    }

    berthSelectLabel = (b: any) => b.name;
    etdDisabledDates = (date: Date) => date <= subDays(new Date(this.model.eta), 1);
    canEditFreightForwarders = () => this.user.hasPermission(VesselVisitPermissions.Add.FreightForwarder);
    canEditCargoAgents = () => this.user.hasPermission(VesselVisitPermissions.Add.CargoAgent);

    canApprove() {
        return this.viewMode && !['CAN', 'DEP'].includes(this.model.statusId) &&
            !this.model.approved && this.user.hasPermission(VesselVisitPermissions.Action.Approve);
    }

    approveVesselVisit() {
        this.model.approved = true;
        this.saveChanges();
    }

    onRowSave(event: SaveEvent) {
        event.dataItem.vesselVisitId = this.model.id;
    }

    canEditRow = (row: any) => !row?.id || row.createdBy?.organizationId === this.user.organizationId || this.isAdminUser;

    operationTypeFilter = (operationType: any, search: string) => {
        if (this.isAdminUser) return true;
        if (this.user?.organizationId === this.model.createdBy?.organizationId) {
            return operationType.value !== 'LO';
        } else if (this.user?.organizationId === this.model.departureAgentId) {
            return operationType.value !== 'DI';
        }
        return true;
    };

    documentTypeFilter = (documentType: any) => {
        if (this.user?.organizationId === this.model.createdBy?.organizationId) {
            return documentType.direction?.id !== 'D';
        } else if (this.user?.organizationId === this.model.departureAgentId) {
            return documentType.direction?.id !== 'A';
        }
        return true;
    };

    async onVesselChange(id) {
        if (!id) this.vesselCategoryId = null;
        const vessel = await this.common.cqrs.query('Vessel', { id }) as any;
        this.vesselCategoryId = vessel.type.vesselCategory.id;
        this.fetchCargoTypes();
        this.fetchPackagingTypes();
    }

    async fetchCargoTypes() {
        this.cargoTypes = (await this.common.codelistService.getCodelist({ name: 'CargoType', customFilter: this.vesselCategoryId, useCache: false })).map((x: any) => ({
            label: `${x.customText || x.name}`,
            value: x.id,
        }));
    }

    async fetchPackagingTypes() {
        this.packagingTypes = (await this.common.codelistService.getCodelist({ name: 'PackagingType', customFilter: this.vesselCategoryId, useCache: false })).map((x: any) => ({
            label: `${x.customText || x.name}`,
            value: x.id,
        }));
    }

    validateCargoAgentRow = (dataItem: any) => dataItem.directionId && dataItem.organizationId;
    validateCargoRow = (dataItem: any) => dataItem.operationTypeId && dataItem.cargoTypeId && (this.vesselCategoryId === 'GEN' ? dataItem.weight : dataItem.quantity)
        && dataItem.packagingTypeId && dataItem.portOfLoadingId;
    validateFreightForwardRow = (dataItem: any) => dataItem.organizationId;

    getCargoTypeLabel = () => {
        switch (this.vesselCategoryId) {
            case 'PASS': return 'Load Type';
            default: return 'Cargo Type';
        }
    };

    getpackagingTypeLabel = () => {
        switch (this.vesselCategoryId) {
            case 'PASS': return 'Load Description';
            case 'CNT': return 'Container Type';
            default: return 'Packaging Type';
        }
    };
}
