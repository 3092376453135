<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Service Requests" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-3">
            <app-control label='Number' [(ngModel)]="filter.number" type="number"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Direction' [(ngModel)]="filter.directionId" type="codelist"
                codelist="DirectionOfGoods"></app-control>
        </div>
        <div class="col-3">
            <app-control type="datetime" label='Date from' [(ngModel)]="filter.dateFrom"></app-control>
        </div>
        <div class="col-3">
            <app-control type="datetime" label='Date to' [(ngModel)]="filter.dateTo"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Status' [(ngModel)]="filter.statusId" type="codelist"
                codelist="ServiceRequestStatus"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Freight Forwarder' type="codelist" codelist="Organization"
                [(ngModel)]="filter.forwarderId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Vessel Visit' [(ngModel)]="filter.vesselVisitId" type="codelist"
                codelist="VesselVisit"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Terminal operator' type="codelist" codelist="PortFacility"
                [(ngModel)]="filter.terminalOperatorId"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <app-grid [data]="gridData" (pageChange)="search()">

            <kendo-grid-column title="{{'Number' | translate}}" field="id">
                <ng-template kendoGridCellTemplate let-row>
                    <div [routerLink]="['/service-request', defaultViewMode, row.id]">
                        <a class="text-primary link-box"> {{row.yearNumber}} </a>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="{{'Type' | translate}}" field="typeName" [width]="150">
            </kendo-grid-column>

            <kendo-grid-column title="{{'Direction' | translate}}" field="direction">
            </kendo-grid-column>

            <kendo-grid-column title="{{'Source' | translate}}" field="sourceId">
            </kendo-grid-column>

            <kendo-grid-column title="{{'Destination' | translate}}" field="destinationId">
            </kendo-grid-column>

            <kendo-grid-column title="{{'Service Request Date' | translate}}" field="serviceRequestDate">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.serviceRequestDate | date}}</span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="{{'Status' | translate}}" field="statusId">
                <ng-template kendoGridCellTemplate let-row>
                    <span [pill]="{ status: row.statusId, module: 'ServiceRequest' }">
                        {{row.statusName}}
                    </span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="{{'Freight Forwarder' | translate}}" field="freightForwarderName">
            </kendo-grid-column>

            <kendo-grid-column title="{{'Vessel Visit' | translate}}" field="vesselVisitId">
                <ng-template kendoGridCellTemplate let-item>
                    <div [routerLink]="['/vessel-visit', defaultViewMode,  item.vesselVisitId]">
                        <a class="text-primary link-box"> <span>{{item.vesselVisitName}}</span> </a>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="{{'Terminal Operator' | translate}}" field="terminalOperatorName">
            </kendo-grid-column>

        </app-grid>
    </div>
</div>
