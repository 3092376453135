<kendo-appbar class="appbar" position="top" positionMode="fixed">
    <kendo-appbar-section *ngIf="user">
        <button class="k-button k-button-clear hamburger" (click)="onToggleSidebar()">
            <fa-icon [icon]="['fas', 'bars']"></fa-icon>
        </button>
    </kendo-appbar-section>
    <kendo-appbar-section>
        <img *ngIf="logo" [src]="logo" style="max-height: 45px; margin-right: 10px" />
        <h1 *ngIf="title" class="title">{{title}}</h1>
    </kendo-appbar-section>
    <kendo-appbar-section>
        <div class="pill" [ngStyle]="{'background-color': environmentColor}">
            <h1 *ngIf="environment" class="title">{{environment}}</h1>
        </div>
    </kendo-appbar-section>
    <kendo-appbar-spacer></kendo-appbar-spacer>
    <!-- <kendo-appbar-section *ngIf="isBellVisible()" class="actions">
        <app-notification></app-notification>
        <span class="k-appbar-separator"></span>
    </kendo-appbar-section> -->
    <kendo-appbar-section class="mr-4">
        <div *ngIf="user" ngbDropdown display="dynamic" placement="bottom-right" class="language">
            <button class="btn-link" id="dropdownBasic1" ngbDropdownToggle>
                <fa-icon icon="flag"></fa-icon>&nbsp; <span translate>Language</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button class="dropdown-item" (click)="changeDisplayLanguage('en')">
                    <img class="flag" src="/assets/img/united-states.png" />&nbsp;EN - English
                </button>
                <div tabindex="-1" class="dropdown-divider"></div>
                <button class="dropdown-item" (click)="changeDisplayLanguage('al')">
                    <img class="flag" src="/assets/img/albania.png" />&nbsp;AL - Shqip
                </button>
            </div>
        </div>
    </kendo-appbar-section>
    <kendo-appbar-section *ngIf="user">
        <fa-icon icon="user"></fa-icon>&nbsp;
        <a style="color: white;" [routerLink]="['user-account/view', user.id]">
            {{ user.fullName ? user.fullName : user.userName }}
        </a>
    </kendo-appbar-section>
    <kendo-appbar-section *ngIf="user">
        <button class="k-button k-button-clear" (click)="logout()">
            <fa-icon icon="arrow-right-from-bracket"></fa-icon>
        </button>
    </kendo-appbar-section>
</kendo-appbar>
