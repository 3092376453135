import { Component, Input, ViewChildren, AfterViewInit, OnInit } from '@angular/core';
import { AppControlComponent } from '@common/components/app-control/app-control.component';

@Component({
    selector: 'app-vessel-data',
    templateUrl: './vessel-data.component.html',
    styleUrls: ['./vessel-data.component.scss']
})
export class VesselDataComponent implements OnInit, AfterViewInit {
    @Input() model: any;
    @Input() canEdit = false;

    @ViewChildren(AppControlComponent) appControls: AppControlComponent[];

    ngOnInit() {
        this.model.countryId = this.model.country?.id;
    }

    ngAfterViewInit(): void {
        if (!this.canEdit)
            setTimeout(() => this.appControls.forEach(ac => ac.isDisabled = true));
    }

    imoRequired(): boolean {
        return !['29', '131', '135', '136', '137', '138', '139', '140', '141', '142', '143', '144', '145', '146', '147'].includes(this.model.typeId);
    }

    ownerCompanyRequired(): boolean {
        return ['20', '49', '82', '104', '128'].includes(this.model.typeId);
    }
}
