<section class="content-header header-padding blue mb-2">
    <h1 *ngIf="title" style="margin-bottom: 0">
        <fa-icon style="margin-left: -10px; font-size: 20px; padding-right: 10px; font-weight: bold;"></fa-icon>
        <span class="header-1" translate>{{title}}</span>
    </h1>
    <ul class="breadcrumb mt-2" id="breadcrumb">
        <li>
            <a class="active" aria-current="page" [routerLink]="['/']">
                <fa-icon icon="home"></fa-icon>
                &nbsp;<span translate>Home</span>
            </a>
        </li>
        <li *ngFor="let item of breadcrumb">
            <a class="active" aria-current="page" [routerLink]="[item.route]" *ngIf="item.route">
                <fa-icon *ngIf="item.icon" icon="{{item.icon}}"></fa-icon>
                &nbsp;<span translate>{{item.title}}</span>
            </a>
            <a class="last-child active" aria-current="page" *ngIf="!item.route">
                <fa-icon *ngIf="item.icon" icon="{{item.icon}}"></fa-icon>
                &nbsp;<span class="last-child active" translate>{{item.title}}</span>
            </a>
        </li>
    </ul>
</section>
