import { DialogService } from '@common/services/dialog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ViewMode } from '@common/models/view-mode';
import { UserService } from './user.service';
import { ConsoleWindowService } from './console-window.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import { HttpClient } from '@angular/common/http';
import { CqrsService } from '@common/services/cqrs.service';
import * as _ from 'lodash';
import { CodelistService } from './codelist.service';

@Injectable()
export class CommonService {

    constructor(
        public toastrService: ToastrService,
        public translateService: TranslateService,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public userService: UserService,
        public consoleWindowService: ConsoleWindowService,
        public dialogService: DialogService,
        public cqrs: CqrsService,
        public codelistService: CodelistService,
        public http: HttpClient,
        private injector: Injector,
    ) { }

    isViewMode() {
        return this.activatedRoute.snapshot.data.mode === ViewMode.view;
    }

    isEditMode() {
        return !this.isViewMode();
    }

    openModal(content: any, options: NgbModalOptions = {}) {
        return this.dialogService.open(content, { ...options, injector: this.injector });
    }
}
