<div class="card card-border-top">
    <div class="card-body">
        <div class="row">
            <div class="col">
                <app-control label='Vessel Name' [(ngModel)]="model.name" [required]="true">
                </app-control>
            </div>
            <div class="col">
                <app-control label='IMO' [(ngModel)]="model.imo" [required]="imoRequired()" type="number">
                </app-control>
            </div>
            <div class="col">
                <app-control label='Call Sign' [(ngModel)]="model.callSign">
                </app-control>
            </div>
            <div class="col">
                <app-control label='MMSI' [(ngModel)]="model.mmsi" type="number">
                </app-control>
            </div>
            <div class="col">
                <app-control label='Flag' type="codelist" codelist="Country" [required]="true"
                    [(ngModel)]="model.countryId">
                </app-control>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-control type="number" label='Gross Tonnage' [(ngModel)]="model.grossTonnage" [required]="true">
                </app-control>
            </div>
            <div class="col">
                <app-control type="number" label='Net Tonnage' [(ngModel)]="model.netTonnage" [required]="true">
                </app-control>
            </div>
            <div class="col">
                <app-control label='DWT' [(ngModel)]="model.dwt">
                </app-control>
            </div>
            <div class="col">
                <app-control type="number" label='Length (M)' [(ngModel)]="model.lengthOverall" [required]="true" format="n2">
                </app-control>
            </div>
            <div class="col">
                <app-control type="number" label='Draft (M)' [(ngModel)]="model.maxDraft" format="n2">
                </app-control>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-control label='Port Of Registry' type="codelist" codelist="Port"
                    [(ngModel)]="model.portOfRegistryId">
                </app-control>
            </div>
            <div class="col">
                <app-control label='Vessel Type' type="codelist" codelist="VesselType" [(ngModel)]="model.typeId" [required]="true">
                </app-control>
            </div>
            <div class="col">
                <app-control label='Classification Society' [(ngModel)]="model.classificationSociety">
                </app-control>
            </div>
            <div class="col">
                <app-control label='Owner Company' [(ngModel)]="model.ownerCompany" [required]="ownerCompanyRequired()">
                </app-control>
            </div>
            <div class="col">
                <app-control type="number" label='Year of Build' [(ngModel)]="model.yearBuilt" format="#" [min]="1900" [max]="9999">
                </app-control>
            </div>
        </div>
        <div class="row" *ngIf="ownerCompanyRequired()">
            <div class="col">
                <app-control label='Contract Number' [(ngModel)]="model.contractNbr" [required]="true"></app-control>
            </div>
            <div class="col">
                <app-control label='Contract Start date' type="datetime" [time]="false" [(ngModel)]="model.contractStartDate"
                    [required]="true"></app-control>
            </div>
            <div class="col">
                <app-control label='Contract End date' type="datetime" [time]="false" [(ngModel)]="model.contractEndDate"
                    [required]="true"></app-control>
            </div>
            <div class="col">
                <app-control label='Subject Representative' [(ngModel)]="model.subjectRepresentative" [required]="true">
                </app-control>
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col-1">
                <app-control label='Active' type="boolean" [(ngModel)]="model.active">
                </app-control>
            </div>
            <div class="col-1">
                <app-control label='Approved' type="boolean" [(ngModel)]="model.approved" [isDisabled]="true">
                </app-control>
            </div>
        </div>
    </div>
