import { Component } from '@angular/core';

@Component({
    selector: 'app-info-button',
    template: `
        <button class="info-hover btn btn-sm btn-primary mb-2"
            (mouseover)="toggleInfo(true)"
            (mouseout)="toggleInfo(false)">
            <fa-icon class="ml-1" icon="info"></fa-icon>&nbsp;<span class="btn-text">{{'Info' | translate}}</span>
        </button>
        <ng-content *ngIf="infoVisible"></ng-content>
    `,
    styles: []
})
export class InfoButtonComponent {
    infoVisible: boolean = false;

    toggleInfo(visibility: boolean): void {
        this.infoVisible = visibility;
    }
}
