import { Component } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
    template: `
        <kendo-dialog-titlebar>
            <div style="font-size: 18px; line-height: 1.3em;">
                <span class="k-icon k-i-warning"></span> {{ title }}
            </div>
        </kendo-dialog-titlebar>

        <div class="m-5 text-center" [innerHTML]="message"></div>

        <kendo-dialog-actions [layout]="'normal'">
            <button [disabled]="isBusy" (click)="close(false)" class="btn btn-secondary" translate>Cancel</button>
            <button [disabled]="isBusy" (click)="close(true)" class="btn btn-primary" translate>OK</button>
        </kendo-dialog-actions>
    `,
})
export class ConfirmDialogComponent extends DialogContentBase {
    title: string;
    message: string;
    isBusy = false;

    constructor(public dialog: DialogRef) {
        super(dialog);
    }

    close(result) {
        if (!this.isBusy) {
            this.isBusy = true;
            this.dialog.close(result);
        }
    }
}
