import { CqrsService } from '@common/services/cqrs.service';
import { UserService } from '../services/user.service';
import { OnInit, Component, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { from, Observable } from 'rxjs';
import { User } from '@common/models/User';
import { DialogService } from '@common/services/dialog.service';
import { IActionBarGroup } from '@common/components/action-bar/action-bar.component';
import { environment } from '@environments/environment';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import { finalize } from 'rxjs/operators';
import { EntityError } from './entity-error';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { ExportType } from './export-type';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { CommonService } from '@common/services/common.service';
import { EntityErrors, EntityErrorsComponent } from '@common/entity-errors/entity-errors.component';

@Component({ template: '' })
export abstract class BaseListComponent implements OnInit {

    protected cqrs: CqrsService;
    protected toastrService: ToastrService;
    protected router: Router;
    protected userService: UserService;
    protected translateService: TranslateService;
    protected dialogService: DialogService;
    protected consoleWindowService: ConsoleWindowService;
    protected exportPDFCommand;
    protected exportExcelCommand;
    defaultViewMode = environment.settings.view.defaultViewMode;

    actionBar: IActionBarGroup[];
    @ViewChild(AppGridComponent) public appGrid: AppGridComponent;
    gridData: GridDataResult | any[];
    user: User;
    isBusy = false;
    filter: any;
    createPermission: string;
    public selection = [];

    parentRoute = window.location.pathname.split('/').slice(0, -1).join('/');
    abstract queryName: string;
    persistFilter = environment.settings.list.persistFilter;

    protected constructor(protected common: CommonService) {
        this.cqrs = common.cqrs;
        this.toastrService = common.toastrService;
        this.router = common.router;
        this.userService = common.userService;
        this.translateService = common.translateService;
        this.dialogService = common.dialogService;
        this.consoleWindowService = common.consoleWindowService;

        this.search = _.debounce(this.search.bind(this), 50);
        this.clearFilter = _.debounce(this.clearFilter.bind(this), 50);

        this.user = this.userService.currentUserSubject.getValue();

        this.actionBar = [
            {
                label: 'Actions',
                items: [{
                    label: 'New',
                    icon: 'plus',
                    isVisible: () => this.canCreateNew(),
                    onClick: () => this.createNew()
                }],
            },
            {
                label: 'Searching',
                items: [
                    {
                        label: 'Search',
                        icon: 'search',
                        onClick: () => this.search()
                    },
                    {
                        label: 'Clear',
                        icon: 'trash',
                        onClick: () => this.clearFilter()
                    }
                ]
            },
            {
                label: 'Export',
                items: [
                    {
                        label: 'Excel',
                        icon: 'file-excel',
                        isVisible: () => this.canExportExcel(),
                        isDisabled: () => this.appGrid?.grid?.loading,
                        onClick: () => this.export(ExportType.Excel)
                    },
                    {
                        label: 'Pdf',
                        icon: 'file-pdf',
                        isVisible: () => this.canExportPDF(),
                        onClick: () => {
                            this.export(ExportType.Pdf);
                        }
                    }
                ],
            }
        ];
    }

    getDefaultFilter() {
        return {};
    };

    dataQuery(): Promise<any> {
        return this.cqrs.query(this.queryName, this.filter);
    }

    ngOnInit() {
        this.initialize();
    }

    createNew() { this.router.navigate([`${this.parentRoute}/create/`]); }

    initialize() {
        this.filter = this.getFilter();
        this.search();
    }

    search(customGrid = null) {
        this.saveFilter();
        const grid = customGrid || this.appGrid.grid as GridComponent;
        grid.loading = true;
        const { skip, pageSize } = grid;
        const { field, dir } = (grid.sort || environment.settings.grid.sort)?.[0] || {};
        this.filter = { ...this.filter, skip, take: pageSize, orderBy: field, orderDirection: dir };

        return this.dataQuery()
            .then(res => this.gridData = (res.inlineCount >= 0 && res.results) ? { data: res.results, total: res.inlineCount } : res?.data || res)
            .catch((err) => this.toastrService.error(err.message || err))
            .finally(() => grid.loading = false);
    }

    private getFilter() {
        const persistedFilter = this.getPersistedFilter();
        const filter = this.getDefaultFilter();
        return this.persistFilter && _.isObject(filter) ? {
            ...filter,
            ...persistedFilter
        } : filter;
    }

    saveFilter() {
        localStorage.setItem(`filter:${this.router.url}`, JSON.stringify(this.filter));
    }

    clearFilter() {
        this.filter = this.getDefaultFilter();
        this.search();
    }

    protected getPersistedFilter() {
        if (this.persistFilter === false) {
            return {};
        }

        try {
            const filter = localStorage.getItem(`filter:${this.router.url}`);
            return JSON.parse(filter);
        } catch (error) {
            return {};
        }
    }

    canCreateNew() {
        if (this.createPermission) {
            return this.user?.hasPermission(this.createPermission);
        }
        return true;
    }

    protected showEntityErrors(entityErrors?: EntityError[]) {
        if (!entityErrors || entityErrors.length === 0) return;
        this.consoleWindowService.open(EntityErrorsComponent, [
            { provide: EntityErrors, useValue: new EntityErrors(entityErrors) },
            { provide: CommonService, useValue: this.common },
        ]);
    }

    ActionHasPermission(permission) {
        return this.user?.hasPermission(permission);
    }

    public canExportPDF() {
        return !!this.exportPDFCommand;
    }

    public canExportExcel() {
        return !!this.exportExcelCommand;
    }

    async export(exportType: ExportType, customGrid = null) {
        const grid = customGrid || this.appGrid.grid as GridComponent;
        const data: any = {
            columnDefs: grid.columns.toArray()
                .filter((x: any) => x.field)
                .map((x: any) => ({
                    header: x.displayTitle,
                    field: x.field,
                })),
            exportType, query: this.filter ?? {}, selectedIds: this.selection
        };
        return this.cqrs.command(exportType === ExportType.Pdf ? this.exportPDFCommand : this.exportExcelCommand, data)
            .then((result: any) => result && UploadActionButtonsComponent.download(result.fileName, result.content));
    }
}
