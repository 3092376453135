import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RememberStateService } from '@common/services/remember-state.service';

export type ToggleableFilter = {
    icon: string;
    text: string;
    filters: string[];
}

@Component({
    selector: 'app-toggleable-filter',
    templateUrl: './toggleable-filter.component.html',
    styleUrls: ['./toggleable-filter.component.scss']
})
export class ToggleableFilterComponent implements OnInit {
    @Input() filters: ToggleableFilter[];
    @Output() filterToggled = new EventEmitter<string[]>();

    activeIndex: number;

    constructor(
        private rememberStateService: RememberStateService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.activeIndex = this.rememberStateService.initialize(`toggleable-filter${this.router.url}`);
        if (!this.activeIndex) this.activeIndex = 0;
        this.filterToggled.emit(this.filters[this.activeIndex].filters);
    }

    private toggle(index: number) {
        this.activeIndex = index;
        this.filterToggled.emit(this.filters[index].filters);
        this.rememberStateService.setState(`toggleable-filter${this.router.url}`, index);
    }
}
