import { StockRecordItemSelectorComponent } from './components/stock-record-item-selector/stock-record-item-selector.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '../common/common.module';
import { StockRecordListComponent } from './components/stock-record-list/stock-record-list.component';
import { StockRecordViewComponent } from './components/stock-record-view/stock-record-view.component';
import { RouterModule, Routes } from '@angular/router';
import { ViewMode } from '../common/models/view-mode';
import { StockRecordItemsGridComponent } from './components/stock-record-items-grid/stock-record-items-grid.component';

const STOCK_RECORD_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: StockRecordListComponent,
    },
    {
        path: 'list/general',
        component: StockRecordListComponent,
    },
    {
        path: 'edit/:id',
        component: StockRecordViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'view/:id',
        component: StockRecordViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'create',
        component: StockRecordViewComponent,
        data: {
            mode: ViewMode.create
        },
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(STOCK_RECORD_ROUTES)
    ],
    declarations: [
        StockRecordListComponent,
        StockRecordViewComponent,
        StockRecordItemsGridComponent,
        StockRecordItemSelectorComponent
    ],
    providers: [],
    exports: []
})
export class StockRecordsModule { }
