import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { ExportType } from '@common/classes/export-type';
import { CommonService } from '@common/services/common.service';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { format } from 'date-fns';

@Component({
    selector: 'app-ship-plan',
    templateUrl: './ship-plan.component.html',
    styleUrls: ['./ship-plan.component.scss'],
    providers: [CommonService]
})
export class ShipPlanComponent extends BaseListComponent {
    queryName = 'ShipPlan';
    activeTab = 0;
    persistFilter = false;
    title = `${this.translateService.instant('Perspective Ship Plan')} (${format(new Date(), 'dd.MM.yyyy')})`;
    exportExcelCommand = 'ExportShipPlan';
    detailParams = ['operationTypeName', 'cargoTypeName', 'quantity', 'weight'];

    breadcrumb = [
        {
            title: 'Reporting',
            icon: 'file-lines'
        },
        {
            icon: 'ship',
            title: 'Perspective Ship Plan'
        }
    ];

    canCreateNew = () => false;
    constructor(common: CommonService) { super(common); }

    onTabSelect(select: SelectEvent) {
        this.filter.activeTab = this.activeTab = select.index;
        this.filter.terminalOperatorId = null;
        this.filter.callPurposeId = null;
        this.search();
    }

    override getDefaultFilter = () => ({
        activeTab: this.activeTab
    });

    override export(exportType: ExportType, customGrid?: any): Promise<void> {
        if (this.activeTab !== 0) return super.export(exportType, customGrid);
        else Promise.resolve(this.appGrid.grid.saveAsExcel());
    }

    get vesselNameTitle() {
        switch(this.activeTab) {
            case 0: return 'Vessel Name';
            case 1: return 'Yacht';
            case 2: return 'Cruise';
            default: return 'N/A';
        }
    }
}
