import { Component } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { CommonService } from '@common/services/common.service';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';

@Component({
    selector: 'app-user-account-view',
    templateUrl: './user-account-view.component.html',
    styleUrls: ['./user-account-view.component.scss'],
    providers: [CommonService]
})
export class UserAccountViewComponent extends BaseViewComponent {
    entityName = 'UserAccount';

    breadcrumb = [
        {
            icon: 'user',
            title: 'User Account'
        }
    ];

    constructor(protected common: CommonService) {
        super(common);
        this.actionBar[0].items.push({
            label: 'Change password',
            icon: 'book',
            isVisible: () => this.canChangePassword(),
            onClick: () => this.changePassword()
        });
    }

    canChangePassword() {
        return !this.editMode;
    }

    changePassword() {
        this.dialogService.open(ChangePasswordModalComponent, { size: 'md' }, dialogRef => dialogRef.user = this.user);
    }

    override saveCommand(customData?: {}): Promise<any> {
        this.entityName = 'User';
        return super.saveCommand(customData);
    }

    override getIdentifier = () => this.model.fullName;
}
