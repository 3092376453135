import { BaseListComponent } from '@common/classes/base-list';
import { Component } from '@angular/core';
import { CommonService } from '@common/services/common.service';
import { AdministrationPermissions } from '@common/classes/permissions';

@Component({
    selector: 'app-organization-list',
    templateUrl: './organization-list.component.html',
    styleUrls: ['./organization-list.component.scss'],
    providers: [CommonService]
})

export class OrganizationListComponent extends BaseListComponent {

    queryName = 'Organizations';

    breadcrumb = [
        {
            title: 'Administration',
            icon: 'cog'
        },
        {
            icon: 'building',
            title: 'Organizations'
        }
    ];

    createPermission = AdministrationPermissions.Organization.Create;

    constructor(private commonService: CommonService) {
        super(commonService);
    }
}
