<nav class="sidebar overflow-auto" [ngStyle]="{'width': isCollapsed ? '60px' : '250px'}">
    <ng-template ngFor let-item [ngForOf]="navigation">
        <ul class="nav nav-pills flex-column nav-primary" *ngIf="item.hasPermission" [ngClass]="{'active': selectedItem === item || isActive(item.route)}">
            <li>
                <a class="nav-link" *ngIf="!isCollapsed" href="javascript:void(0)" style="display: block;" (click)="selectItem(item)">
                    <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>&nbsp;{{item.title | translate}}
                    <span class="float-right" *ngIf="item.children">
                        <fa-icon *ngIf="item !== selectedItem" icon="caret-down"></fa-icon>
                        <fa-icon *ngIf="item === selectedItem" icon="caret-up"></fa-icon>
                    </span>
                </a>
                <a class="nav-link" *ngIf="isCollapsed" href="javascript:void(0)" style="display: block;" (click)="selectItem(item)" ngbTooltip="{{item.title | translate}}" placement="right" container="body">
                    <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
                </a>
                <ul class="nav nav-pills flex-column" [hidden]="(selectedItem !== item && !isActive(item.route))">
                    <ng-template ngFor let-child [ngForOf]="item.children">
                        <li class="nav-item" *ngIf="child.hasPermission" [ngClass]="{'active': isActive(child.route), 'align-with-group': isCollapsed}">
                            <a *ngIf="!isCollapsed" class="nav-link" routerLink="{{child.route}}">
                                <fa-icon *ngIf="child.icon" [icon]="child.icon"></fa-icon>&nbsp;{{child.title | translate}}
                            </a>
                            <a *ngIf="isCollapsed" class="nav-link" routerLink="{{child.route}}" ngbTooltip="{{child.title | translate}}" placement="right" container="body">
                                <fa-icon *ngIf="child.icon" [icon]="child.icon"></fa-icon>
                            </a>
                        </li>
                    </ng-template>
                </ul>
            </li>
        </ul>
    </ng-template>
</nav>
