import { VesselViewComponent } from './components/vessel-view/vessel-view.component';
import { VesselListComponent } from './components/vessel-list/vessel-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewMode } from '@common/models/view-mode';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { CommonModule } from '@common/common.module';
import { VesselDataComponent } from './components/vessel-data/vessel-data.component';

export const VESSEL_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: VesselListComponent
    },
    {
        path: 'view/:id',
        component: VesselViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'edit/:id',
        component: VesselViewComponent,
        data: {
            mode: ViewMode.edit
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'create',
        component: VesselViewComponent,
        data: {
            mode: ViewMode.create
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];


@NgModule({
    imports: [CommonModule],
    exports: [VesselDataComponent],
    declarations: [VesselListComponent, VesselViewComponent, VesselDataComponent],
    providers: [CanDeactivateView]
})
export class VesselComponentModule { }


@NgModule({
    imports: [VesselComponentModule, RouterModule.forChild(VESSEL_ROUTES)],
    providers: [CanDeactivateView]
})
export class VesselModule { }
