<div class="row mt-5 text-center">
    <div class="col"></div>
    <div class="col-auto">
        <h2>
            <fa-icon [icon]="['fas', 'exclamation-circle']" size="2x" class="text-danger mr-2"></fa-icon>
            <br/>
            <span translate>401 - Unauthorized</span>
            <br/>
            <a class="btn btn-link" [routerLink]="['/']">Back Home</a>
        </h2>
    </div>
    <div class="col"></div>
</div>
