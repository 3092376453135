<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Stock records" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-3">
            <app-control label="Number" [(ngModel)]="filter.id" type="number"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Service Request" [(ngModel)]="filter.serviceRequestId" type="number"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Freight Forwarder" [(ngModel)]="filter.freightForwarderId" type="select"
                [options]="freightForwarderOptions"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Terminal Operator" [(ngModel)]="filter.terminalOperatorId" type="codelist"
                codelist="PortFacility"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Type" [(ngModel)]="filter.typeId" type="codelist"
                codelist="ServiceRequestType"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Cargo" [(ngModel)]="filter.cargoTypeId" type="codelist"
                codelist="CargoType"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Date From" [(ngModel)]="filter.dateFrom" type="datetime"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Date To" [(ngModel)]="filter.dateTo" type="datetime"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <app-grid [data]="gridData" (pageChange)="search()">
            <kendo-grid-column title="{{'Number' | translate}}" field="id">
                <ng-template kendoGridCellTemplate let-row>
                    <a class="text-primary link-box" [routerLink]="['/stock-record', defaultViewMode, row.id]">
                        {{row.yearNumber}}
                    </a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{'Service Request' | translate}}" field="serviceRequestNumber">
                <ng-template kendoGridCellTemplate let-item>
                    <a class="text-primary link-box"
                        [routerLink]="['/service-request', defaultViewMode, item.serviceRequestId]">
                        {{item.serviceRequestNumber}}
                    </a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{'Freight Forwarder' | translate}}" field="freightForwarderName">
            </kendo-grid-column>
            <kendo-grid-column title="{{'Terminal' | translate}}" field="terminalName">
            </kendo-grid-column>
            <kendo-grid-column title="{{'Type' | translate}}" field="typeName">
            </kendo-grid-column>
            <kendo-grid-column title="{{'Date' | translate}}" field="date">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.date | date}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{'Cargo Type' | translate}}" field="cargoTypeName">
            </kendo-grid-column>
        </app-grid>
    </div>
</div>
