import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-operations-grid',
    templateUrl: './operations-grid.component.html',
    styleUrls: ['./operations-grid.component.css']
})
export class OperationsGridComponent {
    @Input() operations: any[];
    @Input() showOperationType = true;
    @Input() canEdit = false;
    @Input() editMode = false;

    validateOperationRow = (dataItem: any) => dataItem.shiftId && dataItem.operationTypeId && dataItem.cargoTypeId
        && dataItem.packagingTypeId && dataItem.weight;

}
