import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { VesselVisitComponentModule } from '@vessel-visit/vessel-visit.module';
import { DailyReportComponent } from './components/daily-report/daily-report.component';
import { FerryTerminalComponent } from './components/ferry-terminal/ferry-terminal.component';
import { IndirectProcessingReportComponent } from './components/indirect-processing-report/indirect-processing-report.component';
import { ShipPlanComponent } from './components/ship-plan/ship-plan.component';
import { ShipsInPortComponent } from './components/ships-in-port/ships-in-port.component';
import { SilosReportComponent } from './components/silos-report/silos-report.component';
import { TerminalOperationsComponent } from './components/terminal-operations/terminal-operations.component';
import { VesselOperationsComponent } from './components/vessel-operations/vessel-operations.component';
import { VesselScheduleComponent } from './components/vessel-schedule/vessel-schedule.component';
import { VesselsInOrderComponent } from './components/vessels-in-order/vessels-in-order.component';

export const REPORTING_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'vessel-schedule',
        pathMatch: 'full'
    },
    {
        path: 'vessel-schedule',
        component: VesselScheduleComponent
    },
    {
        path: 'ship-plan',
        component: ShipPlanComponent
    },
    {
        path: 'vessel-order',
        component: VesselsInOrderComponent
    },
    {
        path: 'ships-in-port',
        component: ShipsInPortComponent
    },
    {
        path: 'vessel-operations',
        component: VesselOperationsComponent
    },
    {
        path: 'ferry-terminal',
        component: FerryTerminalComponent
    },
    {
        path: 'terminal-operations',
        component: TerminalOperationsComponent
    },
    {
        path: 'indirect-processing-report',
        component: IndirectProcessingReportComponent
    },
    {
        path: 'silos-report',
        component: SilosReportComponent
    },
    {
        path: 'daily-report',
        component: DailyReportComponent
    },
    {
        path: '**',
        redirectTo: 'vessel-schedule'
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(REPORTING_ROUTES), CommonModule, VesselVisitComponentModule
    ],
    declarations: [
        VesselScheduleComponent,
        ShipPlanComponent,
        VesselsInOrderComponent,
        ShipsInPortComponent,
        FerryTerminalComponent,
        TerminalOperationsComponent,
        VesselOperationsComponent,
        IndirectProcessingReportComponent,
        SilosReportComponent,
        DailyReportComponent
    ]
})
export class ReportingModule { }
