import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'active',
    pure: false
})
export class ActivePipe implements PipeTransform {
    transform(value: any[], ...args: unknown[]): unknown {
        const filtered = value?.filter(x => x.active === undefined || x.active === true);

        return value?.length === filtered?.length ? value : filtered;
    }
}
