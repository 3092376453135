<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Roles" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-3">
            <app-control label='Name' [(ngModel)]="filter.name"></app-control>
        </div>
    </div>
</app-filter>
<div class="card mt-3">
    <div class="card-body">
        <app-grid [data]="gridData" (pageChange)="search()">
            <kendo-grid-column title="{{ 'Name' | translate }}" field="name" [width]="250">
                <ng-template kendoGridCellTemplate let-row>
                    <div [routerLink]="['/administration/role/view', row.id]">
                        <a class="text-primary link-box"> {{ row.name }} </a>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </app-grid>
    </div>
</div>
