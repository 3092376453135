import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';
import { User } from '@common/models/User';
import { filter, first } from 'rxjs/operators';
import { MenuItem, NAVIGATION } from 'app/navigation';
import { UserService } from '@common/services/user.service';
import { RememberStateService } from '@common/services/remember-state.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    url: string;
    navigation: MenuItem[];
    isCollapsed: boolean;
    selectedItem: MenuItem;

    user: User;

    constructor(
        private router: Router,
        private rememberStateService: RememberStateService,
        userService: UserService
    ) {
        this.navigation = NAVIGATION;
        this.selectedItem = this.navigation[0];

        userService.currentUserSubject.subscribe((user) => {
            this.user = user;
            this.setPermissions();
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) this.url = this.router.url;
        });

        this.router.events.pipe(filter(x => x instanceof NavigationEnd), first()).subscribe((event) => {
            const item = _.find(this.navigation.filter(x => x.route === this.router.url || _.find(x.children, c => this.router.url.indexOf(c.route) === 0)));
            if (item) this.selectedItem = item;
        });
    }

    ngOnInit(): void {
        this.isCollapsed = this.rememberStateService.initialize('sidebar');
    }

    isActive(route: string) {
        return this.url && (this.url === route || this.url.includes(`${route}/`));
    }

    selectItem(item) {
        if (item.route) {
            this.router.navigate([item.route]);
            return this.selectedItem = null;
        }
        if (this.selectedItem === item) {
            return this.selectedItem = null;
        }
        this.selectedItem = item;
    }

    toggle() {
        this.isCollapsed = !this.isCollapsed;
        this.rememberStateService.setState('sidebar', this.isCollapsed);
    }

    private setPermissions() {
        _.forEach(this.navigation, x => this.setMenuItemPermission(x));
    }

    private setMenuItemPermission(menuItem: MenuItem) {
        menuItem.hasPermission = this.hasPermission(menuItem);
        _.forEach(menuItem.children, y => this.setMenuItemPermission(y));
    }

    hasPermission(menuItem: MenuItem) {
        if (!this.user) return false;

        const permissions = _.isArray(menuItem.permissions) ? menuItem.permissions : (menuItem.permissions ? [menuItem.permissions] : []);
        if (!menuItem.permissions || (_.isArray(menuItem.permissions) && menuItem.permissions.length === 0)) {
            return true;
        }

        return this.user.hasPermission(...permissions);
    }
}
