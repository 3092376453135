import { IDeactivatableComponent } from '@common/guards/view.deactivate';
import { DialogService } from '@services/dialog.service';
import { filter } from 'rxjs/operators';
import { OnInit, OnDestroy, Component, ViewChildren, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ViewMode } from '@common/models/view-mode';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { Subscription, BehaviorSubject, takeUntil, Subject, firstValueFrom } from 'rxjs';
import { User } from '@common/models/User';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { CommonService } from '@common/services/common.service';
import { IActionBarGroup } from '@common/components/action-bar/action-bar.component';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';
import { EntityError } from './entity-error';
import { EntityErrors, EntityErrorsComponent } from '@common/entity-errors/entity-errors.component';
import { CqrsService } from '@common/services/cqrs.service';
import { AppControlComponent } from '@common/components/app-control/app-control.component';

@Component({ template: '' })

export abstract class BaseViewComponent implements OnInit, OnDestroy, IDeactivatableComponent {
    private userSubscription: Subscription;
    isBusy$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private destroy$ = new Subject<boolean>();

    user: User;
    isBusy = true;

    readonly viewMode: boolean;
    readonly createMode: boolean;
    readonly editMode: boolean;
    actionBar: IActionBarGroup[];
    public selection = [];
    public hasUnsavedChanges = false;

    protected cqrs: CqrsService;
    protected translateService: TranslateService;
    protected toastrService: ToastrService;
    protected consoleWindowService: ConsoleWindowService;
    protected router: Router;
    protected activatedRoute: ActivatedRoute;
    protected dialogService: DialogService;

    public mode: ViewMode;
    protected id: number | string;
    model: any;
    tabIndex: number;
    editPermission: string;
    createPermission: string;

    abstract entityName: string;
    arrayProperties: string[] = []; // Initialize empty array for array properties in create mode
    parentRoute: string;
    title: string;
    activeTab = 0;
    initialModel = {};

    @ViewChildren(AppControlComponent) appControls: AppControlComponent[];
    @ViewChild(TabStripComponent) tabstrip: TabStripComponent;

    async getTitle() {
        const entityTitle = this.translateService.instant(this.entityName.replace(/([A-Z])/g, ' $1').trim());
        let customNewTranslation = await firstValueFrom(this.translateService.get(`New ${entityTitle}`));
        if (customNewTranslation === `New ${entityTitle}`) customNewTranslation = null; // Ignore default translation
        return this.createMode ? customNewTranslation || `${this.translateService.instant('New')} ${entityTitle}` : `${entityTitle} - ${this.getIdentifier()}`;
    }

    protected constructor(protected common: CommonService) {
        this.cqrs = common.cqrs;
        this.translateService = common.translateService;
        this.toastrService = common.toastrService;
        this.router = common.router;
        this.activatedRoute = common.activatedRoute;
        this.consoleWindowService = common.consoleWindowService;
        this.dialogService = common.dialogService;

        this.id = common.activatedRoute.snapshot.params.id;
        this.mode = common.activatedRoute.snapshot.data.mode;
        this.createMode = this.mode === ViewMode.create;
        this.viewMode = this.mode === ViewMode.view;
        this.editMode = !this.viewMode;

        this.parentRoute = window.location.pathname.split('/').slice(0, this.createMode ? -1 : -2).join('/');

        this.activeTab = +this.router.routerState.snapshot.root.queryParams.tab || 0;
        this.actionBar = [{
            label: 'Edit Actions',
            items: [
                {
                    label: 'Save',
                    icon: 'save',
                    isVisible: () => !this.viewMode,
                    onClick: () => this.saveChanges()
                },
                {
                    label: 'Edit',
                    icon: 'edit',
                    isVisible: () => this.canEdit() && this.viewMode,
                    onClick: () => this.navigateToEditMode()
                },
                {
                    label: 'Cancel changes',
                    icon: 'ban',
                    isVisible: () => this.editMode,
                    onClick: () => this.cancelChanges()
                }
            ],
        }];

        this.userSubscription = this.common.userService.currentUserSubject
            .pipe(filter(Boolean), takeUntil(this.destroy$))
            .subscribe((user) => { this.user = user; });

        this.isBusy$
            .pipe(takeUntil(this.destroy$))
            .subscribe((value: boolean) => {
                this.isBusy = value;
                if (value === false) {
                    setTimeout(() => {
                        this.tabstrip?.selectTab(this.activeTab);
                        this.onTabSelect({ index: this.activeTab } as SelectEvent);
                    });
                }
            });
    }

    ngOnInit() {
        this.initialize();
    }

    async initialize() {
        if (this.createMode) await this.createEntity();
        else await this.loadModel();
        await this.modelLoaded();
    }

    createEntity(): void | Promise<void> {
        if (!this.canCreateNew()) {
            this.navigateToList();
            this.toastrService.error('User does not have create permissions');
            return;
        }
        this.model = this.initialModel;
        this.arrayProperties.forEach(prop => this.model[prop] = []);
    }

    async loadModel() {
        this.model = await this.dataQuery();
    }

    getIdentifier() {
        return this.model.id;
    }

    async modelLoaded() {
        if (!this.model) return this.navigateToList();
        this.expandModelProperties();
        if (this.mode === ViewMode.edit && !this.canEdit()) {
            this.navigateToViewMode();
            this.toastrService.error('User does not have edit permissions');
            return;
        }
        this.title = await this.getTitle();
        this.isBusy$.next(false);
        if (this.editMode) this.hasUnsavedChanges = true;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
        this.consoleWindowService.close();
    }

    dataQuery(): Promise<any> {
        return this.cqrs.query(this.entityName, { id: this.id });
    }

    canCancel() {
        return !this.viewMode;
    }

    cancelChanges() {
        this.hasUnsavedChanges = false;
        if (this.createMode) {
            this.navigateToList();
        } else {
            this.navigateToViewMode();
        }
    }

    canEdit() {
        if (this.editPermission) {
            return this.user.hasPermission(this.editPermission);
        }
        return true;
    }

    canCreateNew() {
        if (this.createPermission) {
            return this.user?.hasPermission(this.createPermission);
        }
        return true;
    }

    saveCommand(customData = {}): Promise<any> {
        return this.cqrs.command(`Save${this.entityName}`, { saveModel: this.model, id: this.id, action: this.mode, ...customData });
    };

    async saveChanges(customData?: any) {
        if (this.appControls.some(x => !!x.error)) {
            this.toastrService.error(this.translateService.instant('Some required fields are empty'));
            return;
        }
        this.isBusy$.next(true);

        this.beforeSave();
        try {
            const result = await this.saveCommand(customData);
            if (result?.error) return;
            this.hasUnsavedChanges = false;
            // this.model = result;
            this.afterSave(result);
        } catch (err) {
            this.toastrService.error('Error saving data');
            this.showEntityErrors(err.error.entityErrors);
            return err;
        } finally {
            this.isBusy$.next(false);
        }
    }

    cloneEntity() {
        this.beforeClone();
        this.saveCommand({ id: -1, action: ViewMode.create }).then(result => {
            if (result?.error) return;
            this.afterClone(result);
        }).catch(err => {
            this.toastrService.error('Error while cloning');
            this.showEntityErrors(err.error.entityErrors);
            return err;
        }).finally(() => this.isBusy$.next(false));
    }

    beforeSave() {
        this.hideEntityErrors();
    }

    beforeClone() { }

    afterSave(model) {
        this.navigateToViewMode(model);
        this.isBusy$.next(false);
        this.toastrService.success(this.translateService.instant('Save successful'));
    }

    afterClone(model) {
        this.navigateToEditMode(model);
        this.isBusy$.next(false);
        this.toastrService.success(this.translateService.instant('Clone successful'));
    }

    executeCommand(commandName: string, data = {}, customCallback: (data?: any) => any | Promise<void> = null): Promise<any> {
        this.isBusy$.next(true);
        return this.cqrs.command(commandName, { ...data, id: this.model.id })
            .then(async (data) => {
                this.toastrService.success('Success');
                if (!customCallback) await this.initialize();
                else await customCallback(data);
                return data;
            })
            .finally(() => this.isBusy$.next(false));
    }

    navigateToViewMode(model = this.model) {
        return this.router.navigate([`${this.parentRoute}/view/${model.id}`], this.getNavigationExtras());
    }

    navigateToEditMode(model = this.model) {
        this.router.navigate([`${this.parentRoute}/edit/${model.id}`], this.getNavigationExtras(ViewMode.edit));
    }

    navigateToList() {
        this.router.navigate([`${this.parentRoute}/list/`]);
    }

    protected hideEntityErrors() {
        this.consoleWindowService.close();
    }

    expandModelProperties(model = this.model) {
        Object.getOwnPropertyNames(model).forEach((prop) => {
            // Set id properties for app-control binding
            if (_.isArray(model[prop])) model[prop].forEach((item) => {
                this.expandModelProperties(item);
                item[`${_.camelCase(this.entityName)}Id`] = this.model.id;
            });
            else if (_.isObject(model[prop])) {
                model[`${prop}Id`] = model[prop]?.id;
                this.expandModelProperties(model[prop]);
            }
        });
    }

    getNavigationExtras(viewMode: ViewMode = ViewMode.view): NavigationExtras {
        return { queryParamsHandling: 'merge', onSameUrlNavigation: 'reload' };
    }

    // If we are using kendo-tabstip
    onTabSelect(select: SelectEvent) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                tab: +select.index
            },
            ...this.getNavigationExtras()
        });
        this.activeTab = +select.index;
    }

    protected showEntityErrors(entityErrors?: EntityError[]) {
        if (!entityErrors || entityErrors.length === 0) return;
        this.consoleWindowService.open(EntityErrorsComponent, [
            { provide: EntityErrors, useValue: new EntityErrors(entityErrors) },
            { provide: CommonService, useValue: this.common },
        ]);
    }
}
