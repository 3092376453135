import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    EventEmitter,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList, SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {
    ColumnBase,
    DataStateChangeEvent,
    GridComponent, ScrollMode,
    SelectionEvent,
    SortSettings,
    PagerSettings, SelectableSettings, CellCloseEvent, CellClickEvent, PageChangeEvent, CommandColumnComponent, ToolbarTemplateDirective, ToolbarComponent, DetailTemplateDirective,
    DetailExpandEvent,
} from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor, process } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { ExportService } from '@common/services/export.service';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-grid',
    templateUrl: './app-grid.component.html',
    styleUrls: ['./app-grid.component.scss'],
})
export class AppGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    private destroy$ = new Subject<boolean>();
    public id = _.uniqueId('app-grid-');
    gridData: GridDataResult = { data: [], total: 0 };
    state: State = { skip: 0 };

    // @Input() public toolbar: TemplateRef<any>;

    @ContentChild(ToolbarTemplateDirective) protected toolbar: ToolbarTemplateDirective;
    @ContentChildren(ColumnBase) protected columns = new QueryList<ColumnBase>();

    @ViewChild(GridComponent) public grid: GridComponent;
    @ViewChildren(CommandColumnComponent) protected commandColumns: Array<CommandColumnComponent>;
    @ContentChild(DetailTemplateDirective) public detailTemplate: DetailTemplateDirective;

    @Input() data: any[];
    @Input() sortable = environment.settings.grid.sortable as SortSettings;
    @Input() sort = [...environment.settings.grid.sort] as SortDescriptor[];
    @Input() customSort: { field: string; sortFn: (row: any) => any };
    @Input() selectable: boolean | SelectableSettings;
    @Input() scrollable: ScrollMode = 'scrollable';
    @Input() selectBy = 'id';
    @Input() pageSize: number = environment.settings.grid.pageSize;
    @Input() selection = [];
    @Input() pageable = environment.settings.grid.pageable as PagerSettings;
    @Input() isBusy: boolean;
    @Input() rowClass = () => ({ 'grid-table-row': false });
    @Input() exportFileName: string = 'Export';
    @Input() detailItems: string = null;
    @Input() detailParams: string[] = null;

    @Output() stateChange = new EventEmitter<State>();
    @Output() selectionChange = new EventEmitter<any[]>();
    @Output() pageChange = new EventEmitter<PageChangeEvent>();
    @Output() sortChange = new EventEmitter<SortDescriptor[]>();
    @Output() cellClick = new EventEmitter<CellClickEvent>();
    @Output() cellClose = new EventEmitter<CellCloseEvent>();

    constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected commonService: CommonService,
        private router: Router,
        private exportService: ExportService) { }

    ngOnInit() {
        this.state.take = +localStorage.getItem(`pageSize:${this.router.url}`) || this.pageSize;
        this.state.sort = this.sort;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isBusy) this.isBusy = changes.isBusy.currentValue;

        if (!changes.data) {
            return;
        }

        if (changes.sort) {
            this.state.sort = this.sort;
        }

        // if (this.data) {
        //     this.arrayDataStateChanged(this.data, this.state as any);
        // } else {
        //     this.internalData = [];
        // }
        if (changes.data && changes.data.currentValue) {
            const currentValue = changes.data.currentValue;
            if (Array.isArray(currentValue)) {
                this.gridData = { data: currentValue, total: currentValue.length };
            } else {
                if ('total' in currentValue && 'data' in currentValue && Object.keys(currentValue).length === 2) {
                    this.gridData = { data: currentValue.data, total: currentValue.total };
                } else if (currentValue && currentValue.isSuccess) {
                    this.gridData = { data: currentValue.data.results, total: currentValue.data.inlineCount };
                } else {
                    this.gridData = { data: [], total: 0 };
                }
            }
        }
    }

    public sortChanged(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.applySort();
        this.sortChange.emit(sort);
    }

    applySort() {
        const sort = this.sort[0];
        if (!sort.dir) {
            this.gridData.data = orderBy(this.gridData.data, environment.settings.grid.sort as SortDescriptor[]);
        } else if (this.customSort && sort.field === this.customSort.field) {
            const sorted = this.gridData.data.sort(this.customSort.sortFn);
            this.gridData.data = sort.dir === 'desc' ? sorted.reverse() : sorted;
        } else {
            this.gridData.data = orderBy(this.gridData.data, this.sort);
        }
    }

    onPageChange(event: PageChangeEvent) {
        this.pageChange.emit(event);
        localStorage.setItem(`pageSize:${this.router.url}`, event.take.toString());
    }

    ngAfterViewInit() {
        this.grid.toolbarTemplate = this.toolbar;
        this.grid.detailTemplate = this.detailTemplate;
        this.updateColumns();
        this.columns.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.updateColumns();
        });
    }

    addColumn(column: ColumnBase, index?: number) {
        const columns = this.columns.toArray();
        if (index === undefined) {
            columns.push(column);
        } else {
            columns.splice(index, 0, column);
        }

        this.columns.reset(columns);
    }

    updateColumns(columns: ColumnBase[] = this.columns?.toArray() || []) {
        this.grid.columns.reset(columns);
        this.changeDetectorRef.detectChanges();
    }

    arrayDataStateChanged(array, state: DataStateChangeEvent) {
        if (state) {
            this.state = state;
            this.stateChange.emit(state);
        }
        // In case there is a serverside pagination implemented
        this.gridData = array?.data ? array : process(array, {
            ...this.state,
        });
        this.applySort();
    }

    onSelectionChange(event: SelectionEvent): void {
        this.selectionChange.emit(this.selection);
    }

    async transformExcelExport(args: any): Promise<void> {
        args.preventDefault();
        this.exportService.transformExcelExport(args.workbook, this.gridData.data, this.exportFileName, this.detailItems, this.detailParams);
    }
}
