<app-loader [isBusy]="!model || isBusy">
    <ng-template>
        <app-action-bar [items]="actionBar"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>

        <div class="card mt-3">
            <div class="card-body">
                <div class="row">
                    <div class="col-3">
                        <app-control label='Code' [(ngModel)]="model.code" [required]="true"></app-control>
                    </div>
                    <div class="col-3">
                        <app-control label='Name' [(ngModel)]="model.name" [required]="true"></app-control>
                    </div>
                    <div class="col-3">
                        <app-control label='Type' [(ngModel)]="model.typeId" type="codelist" codelist="OrganizationType"
                            [required]="true"></app-control>
                    </div>
                    <div class="col-3">
                        <app-control label='Address' [(ngModel)]="model.address"></app-control>
                    </div>
                    <div class="col-3">
                        <app-control label='Email' pattern="[\w\d\.\-\_\@]" [(ngModel)]="model.email"></app-control>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col">
                <kendo-tabstrip>
                    <kendo-tabstrip-tab title="{{'Roles'|translate}}" [selected]="true">
                        <ng-template kendoTabContent>
                            <div class="row mt-3" *ngIf="editMode">
                                <div class="col-3">
                                    <app-control #selectedRole type="codelist" codelist="Role"></app-control>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-success btn-sm float-right"
                                        (click)="addRole(selectedRole.value)"
                                        [disabled]="!selectedRole.value || roleExists(selectedRole.value)">
                                        <fa-icon icon="plus"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <app-grid [data]="model.roles" class="mb-2">
                                        <kendo-grid-column field="id" title="{{'Name'|translate}}">
                                            <ng-template kendoGridCellTemplate let-row>
                                                <span>{{row.role.name}}</span>
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column *ngIf="editMode" field="id" title="" [width]="100">
                                            <ng-template kendoGridCellTemplate let-row>
                                                <button class="btn btn-danger btn-xs" (click)="removeRole(row.role.id)">
                                                    <fa-icon icon="trash"></fa-icon>
                                                </button>
                                            </ng-template>
                                        </kendo-grid-column>
                                    </app-grid>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Effective Permissions'|translate}}">
                        <ng-template kendoTabContent>
                            <div class="row">
                                <div class="col">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item" *ngFor="let permission of permissions">
                                            {{ permission }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Users'|translate}}">
                        <ng-template kendoTabContent>
                            <div class="row mt-3">
                                <div class="col">
                                    <app-grid [data]="users" class="mb-2">
                                        <kendo-grid-column field="userName" title="{{'Username'|translate}}">
                                        </kendo-grid-column>
                                        <kendo-grid-column field="fullName" title="{{'Full Name'|translate}}">
                                        </kendo-grid-column>
                                    </app-grid>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>

    </ng-template>
</app-loader>
