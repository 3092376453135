import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { firstValueFrom } from 'rxjs';
import { UploadActionButtonsComponent } from '../upload-action-buttons.component';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    username: string;
    password: string;
    isBusy = false;
    errorMessage: string;
    showChangePassword = false;

    constructor(private userService: UserService, private router: Router, private http: HttpClient) {
        userService.isAuthenticatedSubject.next(false);
    }

    ngOnInit(): void {
        localStorage.clear();
    }

    signIn() {
        this.isBusy = true;
        this.errorMessage = null;
        const returnUrl = this.router.routerState.snapshot.root.queryParams.returnUrl ?? '/';

        this.userService.login(this.username, this.password).then((value) => {
            this.isBusy = false;

            this.router.navigate([returnUrl]);
        }).catch((errorMessage) => {
            this.isBusy = false;
            this.errorMessage = errorMessage;
        });
    }

    async downloadForm() {
        UploadActionButtonsComponent.downloadBlob('Registration form', await firstValueFrom(
            this.http.get(`${window.location.protocol}/assets/documents/Registration_form.pdf`, { responseType: 'blob' })
        ));
    }
}
