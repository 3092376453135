import { LicenseComponent } from './home/license/license.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Router, NavigationStart, NavigationError, NavigationEnd, NavigationCancel, PreloadAllModules } from '@angular/router';
import { NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule as AngularCommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

import { CommonModule } from './common/common.module';
import { UserService } from './common/services/user.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ROUTES } from './app.routing';

import localeSI from '@angular/common/locales/en-SI';
import { registerLocaleData } from '@angular/common';

import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/en-SI/all';

import { environment } from '../environments/environment';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { ConsoleContentDirective } from '@common/directives/console-content.directive';
import { DialogErrorHandler } from '@common/handlers/dialog-error-handler';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { ToastrModule } from 'ngx-toastr';
import * as _ from 'lodash';

// the second parameter 'fr' is optional
registerLocaleData(localeSI, 'en-SI');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LicenseComponent,
        ConsoleContentDirective
    ],
    imports: [
        AngularCommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules }),

        IntlModule,

        ToastrModule.forRoot(),
        NgbModule,
        LoadingBarModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),

        CommonModule
    ],
    exports: [
        BrowserAnimationsModule,
        RouterModule,
        ToastrModule,
        NgbModule,
        LoadingBarModule,
        IntlModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'en-SI' },
        { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'dd.MM.yyyy HH:mm' } },
        UserService,
        ConsoleWindowService,
        { provide: ErrorHandler, useClass: DialogErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private userService: UserService,
        router: Router,
        private loadingBarService: LoadingBarService,
        translate: TranslateService,
        http: HttpClient,
        modalConfig: NgbModalConfig,
        library: FaIconLibrary
    ) {
        library.addIconPacks(fas);
        library.addIconPacks(far);

        modalConfig.backdrop = 'static';

        const routerSate = this.loadingBarService.useRef('router');
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                routerSate.start();
            } else if (event instanceof NavigationError) {
                routerSate.complete();
                console.error(event.error);
            } else if (event instanceof NavigationEnd) {
                routerSate.complete();
            } else if (event instanceof NavigationCancel) {
                routerSate.complete();
            }
        });

        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(environment.defaultLanguage);
    }
}
