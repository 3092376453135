import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { OrganizationListComponent } from './components/organization-list/organization-list.component';
import { OrganizationViewComponent } from './components/organization-view/organization-view.component';
import { ViewMode } from '@common/models/view-mode';

export const ORG_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: OrganizationListComponent
    },
    {
        path: 'edit/:id',
        component: OrganizationViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'view/:id',
        component: OrganizationViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'create',
        component: OrganizationViewComponent,
        data: {
            mode: ViewMode.create
        }
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(ORG_ROUTES)],
    exports: [],
    declarations: [OrganizationListComponent, OrganizationViewComponent],
    providers: []
})
export class OrganizationModule { }
