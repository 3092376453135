<div class="form-group" [ngClass]="{'has-value' : hasValue && AppControlType.Boolean !== type}">

    <label class="col-form-label" *ngIf="label && type !== AppControlType.Boolean"
        [ngClass]="{ 'label-star': !isDisabled && required === true, 'label-bg' : type === AppControlType.TextArea, 'disabled-bg' : isDisabled }"
        [for]="property">
        {{label | translate}}
    </label>

    <ng-container *ngTemplateOutlet="prependTemplate">
    </ng-container>
    <ng-content select=".input-group-prepend"></ng-content>

    <kendo-textbox *ngIf="type === AppControlType.String" (focus)="focusInHandler($event)"
        (blur)="focusOutHandler($event)" kendoTextBox type="text" class="k-textbox form-control form-control-sm"
        [disabled]="isDisabled" [ngClass]="{
            'is-invalid': !!error,
            'is-active' : !!value,
            'prependPadding': !!prepend
        }" [(ngModel)]="value" autocomplete="off" (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)">
        <ng-template kendoTextBoxSuffixTemplate>
            <ng-content select=".textbox-suffix"></ng-content>
        </ng-template>
    </kendo-textbox>

    <input *ngIf="type === AppControlType.Password" kendoTextBox type="password"
        class="k-textbox form-control form-control-sm" [ngClass]="{ 'is-invalid': !!error }"
        [attr.readonly]="isDisabled ? '' : null" [(ngModel)]="value" #tooltip="ngbTooltip" placement="bottom"
        ngbTooltip="" tooltipClass="error-tooltip arrow-bottom" autocomplete="off" />

    <kendo-textarea #textarea (focus)="focusInHandler($event)" *ngIf="type === AppControlType.TextArea"
        (blur)="focusOutHandler($event)" class="k-textarea" [(ngModel)]="value" [attr.readonly]="isDisabled ? '' : null"
        [disabled]="isDisabled" [ngClass]="{ 'is-invalid': !!error, 'disabled': isDisabled }" #tooltip="ngbTooltip"
        placement="bottom" [rows]="rows" ngbTooltip="" tooltipClass="error-tooltip arrow-bottom">
    </kendo-textarea>

    <kendo-numerictextbox *ngIf="type === AppControlType.Number" class="form-control form-control-sm"
        [(ngModel)]="value" (focus)="focusInHandler($event)" [maxlength]="maxlength" (blur)="focusOutHandler($event)"
        [format]="format || '#'" [min]="min || 0" [max]="max" [autoCorrect]="true" [disabled]="isDisabled"
        [ngClass]="{ 'is-invalid': !!error, 'disabled': isDisabled, 'is-read-only': isDisabled }"
        [decimals]="decimals || 2" [readonly]="isDisabled" #tooltip="ngbTooltip" placement="bottom" ngbTooltip=""
        tooltipClass="error-tooltip arrow-bottom">
    </kendo-numerictextbox>

    <kendo-combobox *ngIf="[AppControlType.Select, AppControlType.CodeList].includes(type) && !multi"
        (focus)="focusInHandler($event)" (blur)="focusOutHandler($event)" class="form-control form-control-sm"
        (open)="onDropdownOpen()" [textField]="textField" [valueField]="valueField" [valuePrimitive]="true"
        [data]="filteredOptions" [(ngModel)]="value" [filterable]="true" (filterChange)="filterCodeList($event)"
        [clearButton]="required !== true" [readonly]="isDisabled" [loading]="isBusy" [popupSettings]="popupSettings"
        #tooltip="ngbTooltip" placement="bottom" ngbTooltip="" tooltipClass="error-tooltip arrow-bottom"
        [ngClass]="{ 'is-invalid': !!error, 'disabled': isDisabled, 'is-read-only': isDisabled }" data-container="body">
    </kendo-combobox>

    <app-checkbox *ngIf="type === AppControlType.Boolean" label="{{label | translate}}" [property]="property"
        [(ngModel)]="value" [disabled]="isDisabled" [click]="click"></app-checkbox>

    <div *ngIf="type === AppControlType.DateTime">
        <i ngbTooltip=" {{'Clear'}}" style="position: absolute; top: 38px"
            [ngStyle]="{ 'right' : !!error ? '82px' : '50px' }" placement="bottom"
            *ngIf="required !== true && dateValue && !isDisabled" (click)="dateValue=null"
            class="k-icon k-clear-value k-i-close date-time-close"></i>
        <kendo-datepicker *ngIf="!time" class="form-control form-control-sm" #datePicker [navigation]="false"
            (focus)="focusInHandler($event)" (blur)="focusOutHandler($event)" [readonly]="isDisabled"
            [ngClass]="{ 'is-invalid': !!error, 'is-read-only': isDisabled }" [(ngModel)]="dateValue" placeholder=""
            #tooltip="ngbTooltip" placement="bottom" ngbTooltip="" tooltipClass="error-tooltip arrow-bottom" [disabledDates]="disabledDates">
            <kendo-datetimepicker-messages
                    today="{{'Today' | translate}}"
                    dateTab="{{'Date' | translate}}"
                    timeTab="{{'Time' | translate}}"
                    now="{{'Now' | translate}}"
                    accept="{{'Accept' | translate}}"
                    cancel="{{'Cancel' | translate}}">
            </kendo-datetimepicker-messages>
        </kendo-datepicker>
        <kendo-datetimepicker *ngIf="time" #dateTimePicker class="form-control form-control-sm" [readonly]="isDisabled"
            (focus)="focusInHandler($event)" (blur)="focusOutHandler($event)"
            [ngClass]="{ 'is-invalid': !!error, 'is-read-only': isDisabled }" [(ngModel)]="dateValue" placeholder=""
            #tooltip="ngbTooltip" placement="bottom" ngbTooltip="" tooltipClass="error-tooltip arrow-bottom" [disabledDates]="disabledDates">
            <kendo-datetimepicker-messages
                    today="{{'Today' | translate}}"
                    dateTab="{{'Date' | translate}}"
                    timeTab="{{'Time' | translate}}"
                    now="{{'Now' | translate}}"
                    accept="{{'Accept' | translate}}"
                    cancel="{{'Cancel' | translate}}">
            </kendo-datetimepicker-messages>
        </kendo-datetimepicker>
    </div>

    <kendo-combobox *ngIf="type === AppControlType.YesNo" class="form-control form-control-sm" textField="text"
        valueField="value" [valuePrimitive]="true" [data]="filteredOptions" [(ngModel)]="value" [filterable]="false"
        [disabled]="isDisabled" [clearButton]="required !== true" #tooltip="ngbTooltip" placement="bottom" ngbTooltip=""
        tooltipClass="error-tooltip arrow-bottom" [ngClass]="{ 'is-invalid': !!error, 'disabled': isDisabled }">
    </kendo-combobox>

    <kendo-fileselect *ngIf="type === AppControlType.File" #tooltip="ngbTooltip" placement="bottom" ngbTooltip=""
        tooltipClass="error-tooltip arrow-bottom" [ngClass]="{ 'is-invalid': !!error, 'disabled': isDisabled }"
        [multiple]="multi" (select)="fileSelect($event)" [restrictions]="fileRestrictions" [(ngModel)]="value">
        <kendo-upload-messages [select]="multi ? ('Select files...' | translate) : ('Select file...' | translate)">
        </kendo-upload-messages>
        <!-- <ng-template kendoUploadFileTemplate let-state="state">
            <app-upload-single-file *ngIf="!multi" (removeFile)="removeFile($event)" [file]="file" [disabled]="isDisabled">
            </app-upload-single-file>
            <app-upload-multiple-files class="k-file-multiple" *ngIf="multi" (removeFile)="removeFile($event)"
                [disabled]="isDisabled" [files]="value">
            </app-upload-multiple-files>
        </ng-template> -->
    </kendo-fileselect>

    <div class="position-relative" *ngIf="[AppControlType.Select, AppControlType.CodeList].includes(type) && multi">
        <kendo-multiselect #multiselect (mouseenter)="isMouseEnter=true" (mouseleave)="isMouseEnter=false"
            (focus)="focusInHandler($event)" (blur)="focusOutHandler($event)" class="form-control form-control-sm"
            (open)="onDropdownOpen()" [textField]="textField" [valueField]="valueField" [valuePrimitive]="true"
            [data]="filteredOptions" [(ngModel)]="value" [filterable]="true" (filterChange)="filterCodeList($event)"
            [loading]="isBusy" [clearButton]="required !== true" [readonly]="isDisabled" [popupSettings]="popupSettings"
            placement="bottom" [ngClass]="{ 'is-invalid': !!error, 'is-read-only': isDisabled , 'required': required }"
            (ngModelChange)="change.emit($event)">
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <div style="padding: 4px;">
                    <input type="checkbox" class="k-checkbox" [checked]="isItemSelected(dataItem.value)">
                    <label class="k-checkbox-label">{{ dataItem.label }}</label>
                </div>
            </ng-template>
        </kendo-multiselect>
        <span class="multiselect-count" *ngIf="!(isBusy || isMouseEnter) && value?.length > 0">({{value.length}})</span>
    </div>

    <ng-content select=".input-group-append"></ng-content>


</div>

<ng-template #prependTemplate>
    <div class="input-group-prepend" *ngIf="prepend && hasValue">
        <span class="input-group-text">
            {{prepend}}
        </span>
    </div>
</ng-template>
