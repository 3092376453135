<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-3">
            <app-control label='Vessel' type="codelist" codelist="Vessel"
                [(ngModel)]="filter.vesselId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Type Of Cargo' type="codelist" codelist="CargoType"
                [(ngModel)]="filter.cargoTypeId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Contract Number' [(ngModel)]="filter.contractNbr"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Contract Date' type="datetime" [time]="false"
                [(ngModel)]="filter.contractDate"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Stevedore Company' type="codelist" codelist="Organization"
                [(ngModel)]="filter.freightForwarderId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Task Completion Date From' type="datetime"
                [(ngModel)]="filter.completionDateFrom"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Task Completion Date To' type="datetime"
                [(ngModel)]="filter.completionDateTo"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()" [sort]="[{ field: 'vesselVisitId', dir: 'desc' }]"
            detailItems="silosItems" [detailParams]="detailParams" exportFileName="SilosReporting">
            <kendo-grid-column field="vesselName" title="{{'Vessel' | translate}}"></kendo-grid-column>
            <kendo-grid-column field="cargoTypeName" title="{{'Cargo' | translate}}"></kendo-grid-column>
            <kendo-grid-column field="weight" title="{{'Quantity (Tons)' | translate}}"></kendo-grid-column>
            <kendo-grid-column field="contractNbr" title="{{'Contract No.' | translate}}"></kendo-grid-column>
            <kendo-grid-column field="contractDate" title="{{'Date Of Contract' | translate}}">
                <ng-template kendoGridCellTemplate let-row>
                    {{row.contractDate | date:'dd.MM.yyyy'}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="freightForwarderName" title="{{'Stevedore Company' | translate}}"></kendo-grid-column>
            <kendo-grid-column field="operationsEnd" title="{{'Task Completion' | translate}}">
                <ng-template kendoGridCellTemplate let-row>
                    {{row.operationsEnd | date}}
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridDetailTemplate let-dataItem>
                <div class="row">
                    <app-grid [data]="dataItem.silosItems" [sort]="[{ field: 'seqNr', dir: 'asc' }]">
                        <kendo-grid-column field="seqNr" title="{{'No.' | translate}}" [width]="70"></kendo-grid-column>
                        <kendo-grid-column field="warehouseName" title="{{'Silos' | translate}}"></kendo-grid-column>
                        <kendo-grid-column field="silosClearanceName" title="{{'Clearance Of The Silo' | translate}}"></kendo-grid-column>
                        <kendo-grid-column field="lastInspectionDate1" title="{{'48 Hours' | translate}}"></kendo-grid-column>
                        <kendo-grid-column field="lastInspectionDate2" title="{{'Day 3 To 7' | translate}}">
                            <ng-template kendoGridCellTemplate let-row>
                                {{row.lastInspectionDate2 | date}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="lastInspectionDate3" title="{{'After Day 7' | translate}}">
                            <ng-template kendoGridCellTemplate let-row>
                                {{row.lastInspectionDate3 | date}}
                            </ng-template>
                        </kendo-grid-column>
                    </app-grid>
                </div>
            </ng-template>
        </app-grid>
    </div>
</div>