import { ServiceRequestPermissions, WarehouseDocumentPermissions, StockRecordPermissions, CodeListPermissions, ReportingPermissions } from './common/classes/permissions';
import {
    AdministrationPermissions, VesselPermissions, VesselVisitPermissions,
} from '@common/classes/permissions';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { PermissionType } from '@common/models/User';

export class MenuItem {
    readonly title: string;
    readonly icon: string | string[];
    readonly children?: MenuItem[];
    readonly permissions?: PermissionType | PermissionType[];
    readonly route?: string;
    hasPermission?: boolean;
}

export const NAVIGATION: MenuItem[] = [
    {
        title: 'General Overview',
        icon: 'tachometer-alt',
        route: '/home'
    },
    {
        title: 'Vessel Visits',
        icon: 'ship',
        permissions: [VesselVisitPermissions, VesselPermissions],
        children: [
            {
                title: 'Vessel Visit List',
                icon: 'ship',
                route: '/vessel-visit/list',
                permissions: [VesselVisitPermissions.View]
            },
            {
                title: 'Vessel List',
                icon: 'ship',
                route: '/vessel/list',
                permissions: [VesselPermissions.View]
            },
        ]
    },
    {
        title: 'Cargo',
        icon: 'boxes-stacked',
        permissions: [ServiceRequestPermissions, WarehouseDocumentPermissions, StockRecordPermissions],
        children: [
            {
                title: 'Service Requests',
                icon: 'boxes-stacked',
                route: '/service-request/list',
                permissions: [ServiceRequestPermissions.View]
            },
            {
                title: 'Warehouse Documents',
                icon: 'boxes-stacked',
                route: '/warehouse-document/list',
                permissions: [WarehouseDocumentPermissions.View]
            },
            {
                title: 'Stock Records',
                icon: 'boxes-stacked',
                route: 'stock-record/list',
                permissions: [StockRecordPermissions.View]
            }
        ]
    },
    {
        title: 'Reporting',
        icon: 'file-lines',
        permissions: [ReportingPermissions.View],
        children: [
            {
                title: 'Vessel Schedule',
                icon: 'calendar',
                route: '/reporting/vessel-schedule',
                permissions: [ReportingPermissions.View.VesselSchedule]
            },
            {
                title: 'Perspective Ship Plan',
                icon: 'ship',
                route: '/reporting/ship-plan',
                permissions: [ReportingPermissions.View.ShipPlan]
            },
            {
                title: 'Vessels in Order',
                icon: 'ship',
                route: '/reporting/vessel-order',
                permissions: [ReportingPermissions.View.VesselsInOrder]
            },
            {
                title: 'Ships and Yachts in port',
                icon: 'ship',
                route: '/reporting/ships-in-port',
                permissions: [ReportingPermissions.View.ShipsAndYachts]
            },
            {
                title: 'Vessel Operations',
                icon: 'ship',
                route: '/reporting/vessel-operations',
                permissions: [ReportingPermissions.View.VesselOperations]
            },
            {
                title: 'Ferry (AFTO) Terminal',
                icon: 'ship',
                route: '/reporting/ferry-terminal',
                permissions: [ReportingPermissions.View.FerryTerminal]
            },
            {
                title: 'Terminal Operations Report',
                icon: 'boxes-stacked',
                route: '/reporting/terminal-operations',
                permissions: [ReportingPermissions.View.TerminalOperations]
            },
            {
                title: 'Indirect Processing Report',
                icon: 'boxes-stacked',
                route: '/reporting/indirect-processing-report',
                permissions: [ReportingPermissions.View.IndirectProcessingReport]
            },
            {
                title: 'Silos Report',
                icon: 'boxes-stacked',
                route: '/reporting/silos-report',
                permissions: [ReportingPermissions.View.SilosReport]
            },
            {
                title: 'Daily Report',
                icon: 'boxes-stacked',
                route: '/reporting/daily-report',
                permissions: [ReportingPermissions.View.DailyReport]
            }
        ]
    },
    {
        title: 'Codelists',
        icon: 'file-code',
        permissions: [
            CodeListPermissions,
        ],
        children: [
            {
                title: 'Pilots',
                icon: 'person-military-pointing',
                route: '/codelist/pilots',
                permissions: [CodeListPermissions.Edit.Pilots]
            }
        ]
    },
    {
        title: 'Administration',
        icon: 'cog',
        permissions: [
            AdministrationPermissions,
        ],
        children: [
            {
                title: 'Users',
                icon: 'users',
                route: '/administration/user',
                permissions: [AdministrationPermissions.User]
            },
            {
                title: 'Organizations',
                icon: 'building',
                route: '/administration/organization',
                permissions: [AdministrationPermissions.Organization]
            },
            {
                title: 'Roles',
                icon: 'user-plus',
                route: '/administration/role',
                permissions: [AdministrationPermissions.Role]
            }
        ]
    },
    {
        title: 'License',
        icon: 'list',
        route: '/license',
    },
];
