import { OperationsGridComponent } from './components/operations-grid/operations-grid.component';
import { VesselVisitGridComponent } from './components/vessel-visit-grid/vessel-visit-grid.component';
import { VesselComponentModule } from './../vessel/vessel.module';
import { VesselVisitViewComponent } from './components/vessel-visit-view/vessel-visit-view.component';
import { VesselVisitListComponent } from './components/vessel-visit-list/vessel-visit-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { CommonModule } from '@common/common.module';

export const VESSEL_VISIT_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: VesselVisitListComponent
    },
    {
        path: 'view/:id',
        component: VesselVisitViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'edit/:id',
        component: VesselVisitViewComponent,
        data: {
            mode: ViewMode.edit
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'create',
        component: VesselVisitViewComponent,
        data: {
            mode: ViewMode.create
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];

@NgModule({
    imports: [CommonModule, VesselComponentModule],
    declarations: [VesselVisitListComponent, VesselVisitViewComponent, VesselVisitGridComponent, OperationsGridComponent],
    exports: [CommonModule, VesselVisitGridComponent, OperationsGridComponent],
    providers: [CanDeactivateView]
})
export class VesselVisitComponentModule { }

@NgModule({
    imports: [
        RouterModule.forChild(VESSEL_VISIT_ROUTES), VesselVisitComponentModule,
    ],
    declarations: [],
    providers: [CanDeactivateView]
})
export class VesselVisitModule { }


