<kendo-grid #grid
        id="{{id}}"
        [data]="gridData"
        [skip]="state.skip"
        [pageable]="pageable"
        [pageSize]="state.take"
        [sortable]="sortable"
        [selectable]="selectable"
        [scrollable]="scrollable"
        [sort]="state.sort"
        [loading]="isBusy"
        [kendoGridSelectBy]="selectBy"
        [(selectedKeys)]="selection"
        [filter]="state.filter"
        [rowClass]="rowClass"
        (dataStateChange)="arrayDataStateChanged(data, $event)"
        (pageChange)="onPageChange($event)"
        (sortChange)="sortChanged($event)"
        (selectionChange)="onSelectionChange($event)"
        (excelExport)="transformExcelExport($event)"
        (cellClick)="cellClick.emit($event)"
        (cellClose)="cellClose.emit($event)"
        [navigable]="true">
        <kendo-grid-excel></kendo-grid-excel>
        <kendo-grid-messages pagerItems="{{'items' | translate}}" pagerItemsPerPage="{{'items per page' | translate}}"
            noRecords="{{'No records available' | translate}}" pagerOf="{{'of' | translate}}">
        </kendo-grid-messages>
</kendo-grid>
