import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-check-icon',
    template: `
        <div class="check-icon-container">
            <fa-icon *ngIf="check" class="green-icon" icon="check-circle"></fa-icon>
            <fa-icon *ngIf="!check && !hideFalse" class="red-icon" icon="times-circle"></fa-icon>
        </div>
    `,
    styles: ['.check-icon-container { text-align: center; }']
})
export class CheckIconComponent {
    @Input() check: boolean;
    @Input() hideFalse: boolean = false;
}
