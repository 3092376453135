<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Vessel Visits" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-2">
            <app-control label="Vessel Name" [(ngModel)]="filter.vesselId" type="codelist" codelist="ApprovedVessel"></app-control>
        </div>
        <div class="col-2">
            <app-control label="Vessel IMO" [(ngModel)]="filter.vesselImo"></app-control>
        </div>
        <div class="col-2">
            <app-control label="Status" [(ngModel)]="filter.statusId" type="codelist"
                codelist="VesselVisitStatus"></app-control>
        </div>
        <div class="col-2">
            <app-control label="Terminal Operator" [(ngModel)]="filter.terminalOperatorId" type="codelist"
                codelist="PortFacility"></app-control>
        </div>
        <div class="col-2">
            <app-control label="Call Purpose" [(ngModel)]="filter.callPurposeId" type="codelist"
                codelist="CallPurpose"></app-control>
        </div>
        <div class="col-2">
            <app-control label="Approved by Harbourmaster" [(ngModel)]="filter.approved" type="boolean"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col-2">
            <app-control label="ETA From" [(ngModel)]="filter.etaFrom" type="datetime"></app-control>
        </div>
        <div class="col-2">
            <app-control label="ETA To" [(ngModel)]="filter.etaTo" type="datetime"></app-control>
        </div>
        <div class="col-2">
            <app-control label="ETD From" [(ngModel)]="filter.etdFrom" type="datetime"></app-control>
        </div>
        <div class="col-2">
            <app-control label="ETD To" [(ngModel)]="filter.etdTo" type="datetime"></app-control>
        </div>
        <div class="col-2">
            <app-control label="Number" [(ngModel)]="filter.number" type="number"></app-control>
        </div>
        <div class="col-2">
            <app-control label="HAZMAT on board" [(ngModel)]="filter.hazmat" type="boolean"></app-control>
        </div>
    </div>
</app-filter>

<app-vessel-visit-grid [gridData]="gridData" (pageChange)="search()"></app-vessel-visit-grid>
