import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { ExportType } from '@common/classes/export-type';
import { CommonService } from '@common/services/common.service';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { format } from 'date-fns';

@Component({
    selector: 'app-daily-report',
    templateUrl: './daily-report.component.html',
    styleUrls: ['./daily-report.component.scss'],
    providers: [CommonService]
})
export class DailyReportComponent extends BaseListComponent {
    queryName: string;
    title = `${this.translateService.instant('Daily report')} (${format(new Date(), 'dd.MM.yyyy')})`;
    activeTab = 0;
    exportExcelCommand = 'ExportTransitDailyReport';

    breadcrumb = [
        {
            title: 'Reporting',
            icon: 'file-lines'
        },
        {
            title: 'Daily Reporting',
            icon: 'ship'
        }
    ];

    override canCreateNew = () => false;
    constructor(common: CommonService) { super(common); }

    override search() {
        this.setQueryName();
        return super.search();
    }


    onTabSelect(select: SelectEvent) {
        this.filter.activeTab = this.activeTab = select.index;
        this.search();
    }

    override getDefaultFilter = () => ({
        activeTab: this.activeTab
    });

    setQueryName() {
        switch (this.activeTab) {
            case 0: case 1:
                this.queryName = 'TransitDailyReport';
                this.exportExcelCommand = 'ExportTransitDailyReport';
                break;
            case 2:
                this.queryName = 'WatercraftsDailyReport';
                this.exportExcelCommand = 'ExportWatercraftsDailyReport';
                break;
            default:
                return;
        }
    }

    warehouseFilterOnly(item) {
        return !item.value.startsWith('S');
    }
}
