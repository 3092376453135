<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-4">
            <app-control label="Vessel Name" [(ngModel)]="filter.vesselId" type="codelist"
                codelist="ApprovedVessel"></app-control>
        </div>
        <div class="col-4">
            <app-control label="Arrival Agency" [(ngModel)]="filter.arrivalAgencyId" type="codelist"
                codelist="Organization"></app-control>
        </div>
        <div class="col-4">
            <app-control label="Departure Agency" [(ngModel)]="filter.departureAgencyId" type="codelist"
                codelist="Organization"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()">
            <kendo-grid-column field="vesselName" title="{{'Vessel Name' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="arrivalAgentName" title="{{'Arrival Agency' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="departureAgentName" title="{{'Departure Agency' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="anchorageRemark" title="{{'Remarks' | translate}}">
            </kendo-grid-column>
        </app-grid>
    </div>
</div>
