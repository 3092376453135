import { Injectable, TemplateRef, Injector } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DialogCloseResult, DialogService as KendoDialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog.component';
import { ChangesDialogComponent } from '@common/components/changes-dialog.component';
import { WarningDialogComponent } from '@common/components/warning-dialog.component';
import { firstValueFrom } from 'rxjs';
import { DialogFormComponent, DialogFormOptions } from '@common/components/dialog-form/dialog-form.component';

@Injectable({ providedIn: 'root' })
export class DialogService {

    constructor(
        private modalService: NgbModal,
        private kendoDialogService: KendoDialogService,
        private injector: Injector
    ) { }

    component(component: string | TemplateRef<any> | Function, options: DialogSettings, data: { [key: string]: any }) {
        const dialogRef = this.kendoDialogService.open({
            ...options,
            content: component
        });

        const dialogInstance = dialogRef.content.instance;
        Object.assign(dialogInstance, data);

        return firstValueFrom(dialogRef.result);
    }

    async confirm(title: string, message: string, hideCancel?: boolean, cancelText?: string, confirmText?: string): Promise<boolean> {
        const dialogRef = this.kendoDialogService.open({
            content: ConfirmDialogComponent,
            minWidth: 420
        });

        const confirmDialog = dialogRef.content.instance;
        confirmDialog.title = title;
        confirmDialog.message = message;
        confirmDialog.hideCancel = hideCancel || confirmDialog.hideCancel;
        confirmDialog.cancelText = cancelText || confirmDialog.cancelText;
        confirmDialog.confirmText = confirmText || confirmDialog.confirmText;

        const result = await firstValueFrom(dialogRef.result);
        return result instanceof DialogCloseResult ? false : !!result;
    }

    async open(content: any, modalOptions?: NgbModalOptions, createdCallback?: (NgbModalRef) => void) {
        const modal = this.modalService.open(content, { centered: true, ...modalOptions });
        if (createdCallback) {
            createdCallback(modal.componentInstance);
        }

        return modal.result
            .catch(reason => {
                if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
                    return;
                }

                throw reason;
            });
    }

    public form(options: DialogFormOptions, modalOptions?: NgbModalOptions) {
        const dialogRef = this.modalService.open(DialogFormComponent, { centered: true, ...modalOptions });
        dialogRef.componentInstance.initialize(options, this.injector);

        return dialogRef.result;
    }

    public changes(title: string, message: string): Promise<any> {
        const dialogRef = this.modalService.open(ChangesDialogComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        return dialogRef.result;
    }

    public warning(title: string, message: string): Promise<any> {
        const dialogRef = this.modalService.open(WarningDialogComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        return dialogRef.result;
    }
}
