<app-loader [isBusy]="!model || !serviceRequest || isBusy">
    <ng-template>
        <app-action-bar [items]="actionBar" [collapsed]="false"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>

        <div class="card card-border-top">
            <h4 class="card-title" translate>GENERAL DATA</h4>
            <div class="card-body">
                <div class="row">
                    <div class="col-2">
                        <app-control label='Document Type' [(ngModel)]="model.typeId" type="codelist"
                            codelist="WarehouseDocumentType" [required]="true" [isDisabled]="true"></app-control>
                    </div>
                    <div class="col-2">
                        <label class="col-form-label mb-2" translate> Status </label>
                        <span [pill]="{ status: model.statusId, module: 'WarehouseDocument' }">
                            {{model.statusId | codelist:'WarehouseDocumentStatus' | async}}
                        </span>
                    </div>
                    <div class="col-2" style="cursor: pointer !important" (click)="goToServiceRequest()">
                        <app-control label='Service Request Number' type="codelist" codelist="ServiceRequest"
                            [(ngModel)]="model.serviceRequestId" [isDisabled]="true"></app-control>
                    </div>
                    <div class="col-2">
                        <app-control label='Direction Of Goods' type="codelist"
                            codelist="DirectionOfGoods" [(ngModel)]="serviceRequest.directionId"
                            [isDisabled]="true"></app-control>
                    </div>
                    <div class="col-2">
                        <app-control label='Warehouse Document Date' [(ngModel)]="model.createdDate"
                            type="datetime" [isDisabled]="true"></app-control>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <app-control label='Freight Forwarder' type="codelist" codelist="Organization"
                            [(ngModel)]="serviceRequest.freightForwarderId" [isDisabled]="true"></app-control>
                    </div>
                    <div class="col-2">
                        <app-control label='Owner' [(ngModel)]="serviceRequest.cargoOwner"
                            [isDisabled]="true"></app-control>
                    </div>
                    <div class="col-2">
                        <app-control label='Importer' [(ngModel)]="model.importer"></app-control>
                    </div>
                    <div class="col-2">
                        <app-control label='Internal Document Number'
                            [(ngModel)]="model.internalDocumentNumber"></app-control>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-border-top mt-4">
            <h4 class="card-title" translate> TRANSPORT </h4>
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <app-control label='Transport Direction'
                            ngModel="{{serviceRequest.sourceId}} - {{serviceRequest.destinationId}}"
                            [isDisabled]="true"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Vessel Visit' [(ngModel)]="serviceRequest.vesselVisitId"
                            type="codelist" codelist="VesselVisit"
                            [isDisabled]="true"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Driver Name' [(ngModel)]="model.driver"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Carrier' [(ngModel)]="model.carrier"></app-control>
                    </div>
                    <div class="col">
                        <app-control label='Seals' [(ngModel)]="model.seals"></app-control>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-8">
                <div class="card card-border-top">
                    <h4 class="card-title" translate> REMARKS </h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <app-control label='Remarks' [(ngModel)]="model.remarks" type="textarea">
                                </app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card card-border-top">
                    <h4 class="card-title" translate>TERMINAL OPERATOR</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <app-control label='Terminal Operator' [isDisabled]="true"
                                    [(ngModel)]="serviceRequest.terminalOperatorId" type="codelist"
                                    codelist="PortFacility">
                                </app-control>
                            </div>
                            <div class="col">
                                <app-control label='Warehouse Worker' [(ngModel)]="model.createdById" type="codelist"
                                    codelist="User" [isDisabled]="true">
                                </app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col">
                <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                    <kendo-tabstrip-tab title="{{'Warehouse Document Items' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab1Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Customs Data' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab2Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Attachments' | translate}}">
                        <ng-template kendoTabContent>
                            <app-documents-grid [model]="model" [editMode]="editMode"
                                collectionName="attachments"></app-documents-grid>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>
    </ng-template>
</app-loader>

<ng-template #tab1Content>
    <div class="row mt-3">
        <div class="col">
            <app-editable-grid [data]="model.items" [editMode]="editMode" [canAddNew]="false" [canDelete]="false" (edit)="editItemRow($event)">

                <kendo-grid-column field="cargoTypeId" title="{{'Cargo Type' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.cargoTypeId | codelist:'CargoType' | async}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="packagingTypeId" title="{{'Packaging Type' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.packagingTypeId | codelist:'PackagingType' | async}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control type="number" [isDisabled]="dataItem.isQuantityDisabled" [(ngModel)]="dataItem.quantity"></app-control>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="grossWeight" title="{{'Gross Weight' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="number" [(ngModel)]="dataItem.grossWeight"></app-control>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="netWeight" title="{{'Net Weight' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="number" [(ngModel)]="dataItem.netWeight"></app-control>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="warehouseId" title="{{'Warehouse' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="select" [options]="warehouseOptions"
                            [(ngModel)]="dataItem.warehouseId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.warehouseId | codelist:'Warehouse' | async}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="imdgId" title="{{'IMDG' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control type="codelist" codelist="IMDG" [(ngModel)]="dataItem.imdgId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.imdgId | codelist:'Imdg' | async}}</span>
                    </ng-template>
                </kendo-grid-column>

            </app-editable-grid>
        </div>
    </div>
</ng-template>

<ng-template #tab2Content>
    <div class="row mt-3">
        <div class="col-3">
            <app-control label="Customs Document" [(ngModel)]="model.customsDocument"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Customs Procedure Type" [(ngModel)]="model.customsProcedureTypeId" type="codelist"
                codelist="CustomsProcedureType"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Customs Procedure Number" [(ngModel)]="model.customsProcedureNumber"
                type="number"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Customs Procedure Date" [(ngModel)]="model.customsProcedureDate"
                type="datetime"></app-control>
        </div>
    </div>
</ng-template>
