import { EditableGridComponent } from '@common/components/editable-grid/editable-grid.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-codelist-editor',
    templateUrl: './pilot-editor.component.html',
    styleUrls: ['./pilot-editor.component.scss'],
    providers: [CommonService]
})
export class PilotEditorComponent extends BaseListComponent {

    queryName = 'Pilots';
    breadcrumb = [
        {
            icon: 'file-code',
            title: 'Codelists'
        },
        {
            icon: 'person-military-pointing',
            title: 'Pilots'
        }
    ];

    @ViewChild(EditableGridComponent) editableGrid: EditableGridComponent;

    constructor(commonService: CommonService) {
        super(commonService);
    }

    override canCreateNew = () => false;

    override async search() {
        this.saveFilter();
        const grid = this.editableGrid.grid;
        grid.loading = true;

        return this.dataQuery()
            .then((results: any[]) => {
                this.gridData = results.map(row => {
                    const { localizedCode: codeEN, name: nameEN } = row.translations?.find(t => t.language?.id === 'EN') || {};
                    const { localizedCode: codeAL, name: nameAL } = row.translations?.find(t => t.language?.id === 'AL') || {};

                    return { ...row, codeEN, nameEN, codeAL, nameAL };
                });
            })
            .catch((err) => this.toastrService.error(err.message || err))
            .finally(() => grid.loading = false);
    };

    rowAction({ dataItem: model, isNew, action }) {
        this.cqrs.command('SavePilot', { model, isNew, action })
            .then(this.search)
            .catch(() => this.toastrService.error(this.translateService.instant('Error while saving data')));
    }
}
