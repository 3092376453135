import { ServiceRequestPermissions, WarehouseDocumentPermissions } from '@common/classes/permissions';
import { BaseViewComponent } from '@common/classes/base-view';
import { Component } from '@angular/core';
import { CommonService } from '@common/services/common.service';
import { AppControlType } from '@common/components/app-control/app-control.component';

@Component({
    selector: 'app-service-request-view',
    templateUrl: './service-request-view.component.html',
    styleUrls: ['./service-request-view.component.scss'],
    providers: [CommonService]
})
export class ServiceRequestViewComponent extends BaseViewComponent {
    entityName = 'ServiceRequest';
    editPermission = ServiceRequestPermissions.Action.Edit;
    createPermission = ServiceRequestPermissions.Action.Create;
    breadcrumb = [
        {
            title: 'Cargo',
            icon: 'boxes-stacked',
        },
        {
            icon: 'boxes-stacked',
            title: 'Service Requests',
            route: '/service-request/list'
        },
        {
            icon: 'box',
            title: 'Service Request View'
        }
    ];
    vesselVisitRequired = false;
    override arrayProperties = ['cargoItems', 'documents', 'attachments'];

    constructor(common: CommonService) {
        super(common);

        this.actionBar.push({
            label: 'Actions',
            items: [
                {
                    label: 'Send',
                    icon: 'envelope',
                    isVisible: () => this.canSend(),
                    onClick: () => this.executeCommand('SendServiceRequest')
                },
                {
                    label: 'Cancel',
                    icon: 'times',
                    isVisible: () => this.canCancel(),
                    onClick: () => this.cancel(),
                },
                {
                    label: 'Decline',
                    icon: 'times',
                    isVisible: () => this.canDecline(),
                    onClick: () => this.decline()
                },
                {
                    label: 'Approve',
                    icon: 'check',
                    isVisible: () => this.canApprove(),
                    onClick: () => this.approve()
                },
                {
                    label: 'Complete',
                    icon: 'check',
                    isVisible: () => this.canComplete(),
                    onClick: () => this.executeCommand('CompleteServiceRequest')
                },
                {
                    label: 'Confirm Equipment',
                    icon: 'check',
                    isVisible: () => this.canConfirmEquipment(),
                    onClick: () => this.confirmEquipment()
                }
            ],
            permissions: [ServiceRequestPermissions.Action]
        }, {
            label: 'Create',
            items: [
                {
                    label: 'Create warehouse document',
                    icon: 'file',
                    isVisible: () => this.canCreateWarehouseDocument(),
                    onClick: () => this.createWarehouseDocument(),
                    permissions: [WarehouseDocumentPermissions.Action.Create, ServiceRequestPermissions.Action.CreateWarehouseDocument]
                },
                {
                    label: 'Clone',
                    icon: 'clone',
                    isVisible: () => this.viewMode,
                    onClick: () => this.cloneEntity(),
                    permissions: [ServiceRequestPermissions.Action.Clone, ServiceRequestPermissions.Action.Create]
                }
            ]
        });
    }

    override getIdentifier = () => this.model.yearNumber;

    override async createEntity() {
        super.createEntity();
        const createData = this.activatedRoute.snapshot.queryParams.createData;
        if (createData) {
            const { typeId, sourceDestination } = JSON.parse(createData);
            [this.model.typeId, this.model.sourceId, this.model.destinationId] = [typeId, ...sourceDestination.split('-')];
        }
        this.model.serviceRequestDate = new Date();
    }

    override async modelLoaded() {
        super.modelLoaded();
        this.vesselVisitRequired = [this.model.sourceId, this.model.destinationId].includes('VESSEL');
    }

    override canEdit() {
        return super.canEdit() && this.model.status?.id === 'SAV';
    }

    canCreateWarehouseDocument() {
        return this.viewMode &&
            this.model.statusId === 'INP' &&
            this.model.typeId !== 'DIRECT' &&
            this.user.hasPermission(WarehouseDocumentPermissions.Action.Create);
    }

    createWarehouseDocument() {
        this.router.navigate(['/warehouse-document/create'], { queryParams: { serviceRequestId: this.model.id } });
    }

    canSend() {
        return this.viewMode && this.model.statusId === 'SAV' && this.user.hasPermission(ServiceRequestPermissions.Action.Plan);
    }

    canCancel() {
        return this.viewMode && ['SAV', 'INP'].includes(this.model.statusId) && this.user.hasPermission(ServiceRequestPermissions.Action.Cancel);
    }

    canComplete() {
        return this.viewMode && this.model.statusId === 'INP' && this.user.hasPermission(ServiceRequestPermissions.Action.Complete);
    }

    canApprove() {
        return this.viewMode && this.model.statusId === 'PEN' && this.user.hasPermission(ServiceRequestPermissions.Action.Approve);
    }

    async approve() {
        const data = await this.dialogService.form({
            title: 'Approve Service Request',
            properties: [{ name: 'terminalRemarks', label: 'Terminal Remarks', type: AppControlType.TextArea }]
        });
        if (!data) return;
        this.executeCommand('ApproveServiceRequest', { terminalRemarks: data.terminalRemarks });
    }

    canDecline() {
        return this.viewMode && this.model.statusId === 'PEN' && this.user.hasPermission(ServiceRequestPermissions.Action.Decline);
    }

    async decline() {
        const data = await this.dialogService.form({
            title: 'Decline Service Request',
            properties: [{ name: 'terminalRemarks', label: 'Terminal Remarks', type: AppControlType.TextArea }]
        });
        if (!data) return;
        this.executeCommand('DeclineServiceRequest', { terminalRemarks: data.terminalRemarks });
    }

    async cancel() {
        const data = await this.dialogService.form({
            title: 'Service Request Cancellation',
            message: 'Are you sure you want to cancel this service request?',
            properties: [{ name: 'cancelRemarks', label: 'Cancel Remarks' }]
        });
        if (!data) return;
        this.executeCommand('CancelServiceRequest', { cancelRemarks: data.cancelRemarks }, () => {
            this.model.statusId = 'CAN';
            this.model.cancelRemarks = data.cancelRemarks;
        });
    }

    canConfirmEquipment() {
        return this.viewMode && !['COM', 'CAN'].includes(this.model.statusId)
            && !this.isDirect
            && !this.model.equipmentConfirmedDate
            && this.user.hasPermission(ServiceRequestPermissions.Action.Confirm);
    }

    confirmEquipment() {
        this.model.equipmentConfirmedById  = this.user.id;
        this.model.equipmentConfirmedDate  = new Date();
        this.saveChanges();
    }

    override beforeClone(): void {
        ['terminalAcceptanceDate', 'terminalDeclineDate', 'terminalRemarks', 'remarks', 'equipmentConfirmedById', 'equipmentConfirmedDate'].forEach(p => this.model[p] = null);
        this.model.attachments = [];
    }

    get isVessel() {
        return [this.model.sourceId, this.model.destinationId].includes('VESSEL');
    }

    get isDirect() {
        return this.model.typeId === 'DIRECT';
    }
}
