<div class="row mt-3">
    <div class="col">
        <app-editable-grid [data]="operations" [canEdit]="canEdit" [editMode]="editMode" [validateRow]="validateOperationRow">
            <kendo-grid-column field="shiftId" title="{{'Shift' | translate}}" [width]="100">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [required]="true" name="shiftId" type="codelist"
                        codelist="Shift" [(ngModel)]="dataItem.shiftId"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.shiftId | codelist:'Shift' | async}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="shiftDate" title="{{'Shift Date' | translate}}" [width]="150">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control name="shiftDate" type="datetime" [time]="false"
                        [(ngModel)]="dataItem.shiftDate"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.shiftDate | date:'dd/MM/yyyy'}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="operationTypeId" title="{{'Operation Type' | translate}}" [width]="200" *ngIf="showOperationType">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [required]="true" name="operationTypeId" type="codelist"
                        codelist="OperationType" [(ngModel)]="dataItem.operationTypeId"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.operationTypeId | codelist:'OperationType' | async}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="cargoTypeId" title="{{'Cargo Type' | translate}}" [width]="200">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [required]="true" name="cargoTypeId" type="codelist"
                        codelist="CargoType" [(ngModel)]="dataItem.cargoTypeId"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.cargoTypeId | codelist:'CargoType' | async}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="packagingTypeId" title="{{'Packaging Type' | translate}}" [width]="200">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [required]="true" name="packagingTypeId" type="codelist"
                        codelist="PackagingType" [(ngModel)]="dataItem.packagingTypeId"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.packagingTypeId | codelist:'PackagingType' | async}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}" [width]="100">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control name="quantity" type="number"
                        format="n2" [(ngModel)]="dataItem.quantity"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.quantity}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="weight" title="{{'Weight' | translate}}" [width]="100">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control [required]="true" name="weight" type="number"
                        format="n2" [(ngModel)]="dataItem.weight"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.weight}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="remarks" title="{{'Remarks' | translate}}">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control name="remarks" [(ngModel)]="dataItem.remarks"></app-control>
                </ng-template>
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.remarks}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="createdDate" title="{{'Reporting Date' | translate}}" [width]="200">
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.createdDate | date}}</span>
                </ng-template>
            </kendo-grid-column>
        </app-editable-grid>
    </div>
</div>
