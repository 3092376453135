import { CodeList, CodelistService } from '@common/services/codelist.service';
import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { CommonService } from '@common/services/common.service';
import { AdministrationPermissions } from '@common/classes/permissions';
import { BaseViewComponent } from '@common/classes/base-view';

@Component({
    selector: 'app-role-view',
    templateUrl: './role-view.component.html',
    styleUrls: ['./role-view.component.scss'],
    providers: [CommonService]
})
export class RoleViewComponent extends BaseViewComponent implements OnInit {
    entityName = 'Role';
    breadcrumb = [
        {
            title: 'Administration',
            icon: 'cog'
        },
        {
            icon: 'user-plus',
            title: 'Roles',
            route: '/administration/role/list'
        },
        {
            icon: 'user-plus',
            title: 'Role View'
        }
    ];

    editPermission = AdministrationPermissions.Role.Edit;
    createPermission = AdministrationPermissions.Role.Create;
    permissions: CodeList[];
    organizations: any[];
    override arrayProperties = ['permissions'];

    constructor(commonService: CommonService, private codelistService: CodelistService) {
        super(commonService);
    }

    override getIdentifier = () => this.model.name;

    async ngOnInit() {
        super.ngOnInit();
        this.permissions = await this.codelistService.getCodelist({ name: 'Permission' });
    }

    override async modelLoaded() {
        super.modelLoaded();
        const { results } = await this.cqrs.query('Organizations', { roleId: this.model.id, skip: 0, take: 1000 }) as any;
        this.organizations = results;
    }

    addPermission(permissionId: number) {
        this.model.permissions = [
            { permissionId, permission: { fullName: this.permissions.find(p => p.id === permissionId).customText, id: permissionId } },
            ...this.model.permissions
        ];
    }

    permissionExists(permissionId: number) {
        return this.model.permissions?.some(perm => perm.permissionId === permissionId);
    }

    removePermission(permissionId: number) {
        this.model.permissions = this.model.permissions.filter(perm => perm.permissionId !== permissionId);
    }

    beforeSave() {
        this.model.normalizedName = this.model.name;
    }
}
