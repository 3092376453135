import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StockRecordItemsGridComponent } from '../stock-record-items-grid/stock-record-items-grid.component';

@Component({
    selector: 'app-stock-record-item-selector',
    templateUrl: './stock-record-item-selector.component.html',
    styleUrls: ['./stock-record-item-selector.component.css']
})
export class StockRecordItemSelectorComponent {
    sourceDestination: string;
    @ViewChild(StockRecordItemsGridComponent) stockRecordGrid: StockRecordItemsGridComponent;
    constructor(public activeModal: NgbActiveModal) { }

    confirm() {
        this.activeModal.close(this.stockRecordGrid.selection);
    }

    close() {
        this.activeModal.close(false);
    }
}
