import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { ExportType } from '@common/classes/export-type';
import { CommonService } from '@common/services/common.service';
import { SelectEvent } from '@progress/kendo-angular-layout';

@Component({
    selector: 'app-terminal-operations',
    templateUrl: './terminal-operations.component.html',
    styleUrls: ['./terminal-operations.component.scss'],
    providers: [CommonService]
})
export class TerminalOperationsComponent extends BaseListComponent {
    queryName = 'TerminalOperations';
    title = `${this.translateService.instant('Terminal operations report')}`;
    exportExcelCommand = 'ExportTerminalOperations';
    detailParams = ['process', 'containerTypeName', 'quantityPiece', 'quantityTeu', 'realizationPiece', 'realizationTeu', 'remainingPiece', 'remainingTeu', 'agency'];

    breadcrumb = [
        {
            title: 'Reporting',
            icon: 'file-lines'
        },
        {
            icon: 'ship',
            title: 'Terminal operations report'
        }
    ];

    activeCard = 0;
    activeTab = 0;
    persistFilter = false;
    terminalOperatorTabs = { 0: 'EMS', 1: 'TMGJ', 3: 'A&V', 4: 'MBM' };

    canCreateNew = () => false;
    constructor(common: CommonService) { super(common); }

    activateCard(tab: number) {
        this.activeCard = this.filter.activeCard = tab;
        this.search();
    }

    override getDefaultFilter = () => ({
        activeCard: this.activeCard,
        shiftDate: new Date(),
        terminalOperatorId: this.terminalOperatorTabs[this.activeTab]
    });

    onTabSelect(select: SelectEvent) {
        this.activeTab = select.index;
        this.queryName = this.activeTab === 2 ? 'VesselContainerCargo' : 'TerminalOperations';
        this.filter.terminalOperatorId = this.terminalOperatorTabs[this.activeTab];
        this.filter.activeCard = this.activeTab;
        this.search();
    }

    override export(exportType: ExportType, customGrid?: any): Promise<void> {
        if (this.activeTab !== 2) return super.export(exportType, customGrid);
        else Promise.resolve(this.appGrid.grid.saveAsExcel());
    }
}

