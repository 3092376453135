import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from '@common/components/error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { WebsocketService } from '@common/services/websocket.service';

@Injectable({
    providedIn: 'root'
})
export class DialogErrorHandler implements ErrorHandler {

    private modalService: NgbModal;
    private toastrService: ToastrService;
    private translateService: TranslateService;

    constructor(private injector: Injector) { }


    handleError(error) {
        this.modalService ||= this.injector.get(NgbModal); // injecting modalService in constructor does not work
        this.toastrService ||= this.injector.get(ToastrService);
        this.translateService ||= this.injector.get(TranslateService);

        if (error.rejection?.error === 'Token-Expired') return;

        if (error.rejection?.httpResponse) { // breeze query error
            error = error.rejection.httpResponse.response;
        }

        if (error.rejection?.error?.validationErrors) {
            this.handleServerSideError(error.rejection);
        }
        else if (error instanceof HttpErrorResponse) {
            this.handleServerSideError(error);
        } else if (error.rejection instanceof HttpErrorResponse) {
            this.handleServerSideError(error.rejection);
        } else {
            this.handleClientSideError(error);
        }
    }

    private handleServerSideError(response: HttpErrorResponse) {
        let error = response.error || {};
        if (error.validationErrors) {
            return this.openErrorDialog(error.validationErrors.map(e => e.errorMessage).join('\n'), null, true);
        }
        if (_.isString(error) && error[0] === '{') { // breeze command with status 500
            try {
                error = JSON.parse(error);
            } catch { }
        }

        this.openErrorDialog(error.errorMessage, error.exception, true);
    }

    private handleClientSideError(error: Error) {
        console.error(error);
        this.remoteLogError(error);
        this.openErrorDialog(error.message, error.stack, false);
    }

    private openErrorDialog(errorMessage, stackTrace, serverSide) {
        const dialogRef = this.modalService.open(ErrorDialogComponent, {
            size: 'lg'
        });
        dialogRef.componentInstance.isServerSideError = serverSide;
        dialogRef.componentInstance.setMessage(errorMessage);
        dialogRef.componentInstance.setStackTrace(stackTrace);
    }

    remoteLogError(error) {
        if (error.toString().match('Cannot read property')) return;

        const remoteLogService = this.injector.get(WebsocketService);
        remoteLogService.error(error.toString())
            .catch((err) => console.error(err));
    }
}
