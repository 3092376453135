import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-warehouse-documents',
    templateUrl: './warehouse-documents-list.component.html',
    styleUrls: ['./warehouse-documents-list.component.scss'],
    providers: [CommonService]
})
export class WarehouseDocumentsListComponent extends BaseListComponent {
    queryName = 'WarehouseDocuments';

    breadcrumb = [
        {
            title: 'Cargo',
            icon: 'boxes-stacked',
        },
        {
            icon: 'boxes-stacked',
            title: 'Warehouse Document List'
        }
    ];

    constructor(common: CommonService) { super(common); }

    override canCreateNew = () => false;
}
