import { Component, Input, TemplateRef, ContentChild } from '@angular/core';

@Component({
    selector: 'app-loader',
    template: `
        <div class="row" *ngIf="isBusy">
            <div class="col-auto mr-auto ml-auto mt-5">
                <kendo-loader size="large" type="pulsing"></kendo-loader>
            </div>
        </div>
        <div *ngIf="!isBusy">
            <ng-container *ngTemplateOutlet="detailRef"></ng-container>
        </div>
    `
})
export class LoaderComponent {
    @ContentChild(TemplateRef, { static: true }) detailRef;
    @Input() isBusy = true;
}
