import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseViewComponent } from '@common/classes/base-view';
import { StockRecordPermissions } from '@common/classes/permissions';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { FormProperty } from '@common/components/dialog-form/dialog-form.component';
import { ViewMode } from '@common/models/view-mode';
import { CommonService } from '@common/services/common.service';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { ServiceRequestCreateComponent } from 'app/service-requests/components/service-request-create/service-request-create.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-stock-record-view',
    templateUrl: './stock-record-view.component.html',
    styleUrls: ['./stock-record-view.component.css'],
    providers: [CommonService]

})
export class StockRecordViewComponent extends BaseViewComponent {
    entityName = 'StockRecord';
    breadcrumb = [
        {
            title: 'Cargo',
            icon: 'boxes-stacked',
        },
        {
            icon: 'boxes-stacked',
            title: 'Stock Records',
            route: '/stock-record/list'
        },
        {
            icon: 'box',
            title: 'Stock Record View',
        }
    ];
    editPermission = StockRecordPermissions.Action.Edit;
    createPermission = StockRecordPermissions.Action.Create;

    gridData = [];
    gridDataLoading = false;
    exitServiceRequests = [];

    constructor(protected common: CommonService, private route: ActivatedRoute) {
        super(common);
        this.actionBar.push({
            label: 'Actions',
            items: [
                {
                    label: 'Exit service request',
                    icon: 'file',
                    isVisible: () => this.user.hasPermission(StockRecordPermissions.Action.ExitServiceRequest),
                    isDisabled: () => !this.selection.length,
                    onClick: () => this.createExitServiceRequest()
                },
                {
                    label: 'Insert remark',
                    icon: 'comment',
                    isVisible: () => this.user.hasPermission(StockRecordPermissions.Action.InsertRemark),
                    onClick: () => this.insertRemarks()
                },
                {
                    label: 'Inspection entry',
                    icon: 'file',
                    isVisible: () => this.user.hasPermission(StockRecordPermissions.Action.InspectionEntry),
                    isDisabled: () => this.selection.length !== 1,
                    onClick: () => this.saveInspectionEntry(ViewMode.create)
                },
                {
                    label: 'Update last entry',
                    icon: 'file',
                    isVisible: () => this.selection.length === 1 && this.user.hasPermission(StockRecordPermissions.Action.InspectionEntry),
                    isDisabled: () => this.selection.length !== 1,
                    onClick: () => this.saveInspectionEntry(ViewMode.edit)
                },
                {
                    label: 'Delete last entry',
                    icon: 'file',
                    isVisible: () => this.selection.length === 1 && this.user.hasPermission(StockRecordPermissions.Action.InspectionEntry),
                    isDisabled: () => this.selection.length !== 1,
                    onClick: () => this.executeCommand('SaveInspectionEntry', { stockRecordItemId: this.selection[0], action: 'delete' })
                }
            ]
        });
    }

    override getIdentifier = () => this.model.yearNumber;

    override canEdit = () => false;

    override async modelLoaded() {
        super.modelLoaded();
        this.exitServiceRequests = await this.cqrs.query('ExitServiceRequests', { id: this.model.id });
        this.exitServiceRequests.forEach(e => {
            e.serviceRequestId = e.serviceRequest.id;
            e.serviceRequestYearNumber = e.serviceRequest.yearNumber;
        }); // For grid sorting
    }

    async insertRemarks() {
        const data = await this.dialogService.form({
            title: 'Insert Remarks',
            properties: [{
                name: 'remarks', label: 'Remarks', initialValue: this.model.remarks
            }],
            canConfirm: (data) => !!data.remarks
        });
        if (!data?.remarks) return;
        this.executeCommand('InsertStockRecordRemarks', { remarks: data.remarks }, () => this.model.remarks = data.remarks);
    }

    async createExitServiceRequest() {
        const sourceIds = [];
        this.selection.forEach(id => sourceIds.push(this.model.items.find(x => x.id === id)?.warehouseDocumentItem?.warehouse?.warehouseTypeId));
        const data = await this.dialogService.open(ServiceRequestCreateComponent, {},
            dialog => {
                dialog.isExport = true;
                dialog.model = { typeId: 'EXPORT' };
                dialog.sourceIds = _.uniq(sourceIds);
            });
        if (!data) return;
        const sr = await this.executeCommand('CreateExitServiceRequest', { ...data, stockRecordItemIds: this.selection });
        this.router.navigate(['/service-request/edit', sr.id]);
    }

    override async onTabSelect(select: SelectEvent) {
        super.onTabSelect(select);
        this.gridDataLoading = true;

        const commandIndices = { 1: 'StockRecordWarehouseDocuments' };
        const command = commandIndices[select.index];
        if (!command) return;
        this.gridData = await this.cqrs.query(command, { id: this.model.id });
        this.gridDataLoading = false;
    }

    goToServiceRequest(id: number) {
        if (!this.model.serviceRequestCargoItem?.serviceRequest?.id) return;
        window.open(`/service-request/view/${id}`, '_blank');
    }

    async saveInspectionEntry(action: string) {
        const properties : FormProperty[] = [
            {
                name: 'lastInspectionDate', label: 'Last Inspection Date',
                type: AppControlType.DateTime, time: true, required: true,
            }
        ];

        const destinationId = this.model.serviceRequestCargoItem?.serviceRequest?.destinationId;
        if (destinationId === 'SILOS') {
            properties.push({ name: 'silosClearanceId', label: 'Silos Clearance',
                type: AppControlType.CodeList, codelist: 'SilosClearance', required: true });
        }
        else {
            properties.push({ name: 'surface', label: 'Occupied Surface (m²)',
                type: AppControlType.Number, pattern: '[\d]', required: true });
            properties.push({ name: 'yardState', label: 'Yard State',
                type: AppControlType.String, required: true });
        }

        properties.forEach(prop => {
            const item = this.model.items.find(x => x.id === this.selection[0]);
            if (item.inspectionRecords.length > 0) {
                const lastInspectionRecord = item.inspectionRecords.at(-1);
                prop.initialValue = lastInspectionRecord[prop.name];
            }
        });

        const res = await this.dialogService.form({
            title: 'Enter Inspection Data', properties
        });
        if (!res) return;

        this.executeCommand('SaveInspectionEntry', { stockRecordItemId: this.selection[0], action, ...res });
    }
}
