<app-loader [isBusy]="!model || isBusy">
    <ng-template>
        <app-action-bar [items]="actionBar" [collapsed]="false"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>

        <div class="card card-border-top">
            <h4 class="card-title"> {{'GENERAL DATA' | translate}}</h4>
            <div class="card-body">
                <div class="row">
                    <div class="col-3">
                        <app-control label='Entry Service Request Number' style="cursor: pointer !important"
                            [(ngModel)]="model.serviceRequestCargoItem.serviceRequest.yearNumber"
                            (click)="goToServiceRequest(model.serviceRequestCargoItem.serviceRequest.id)">
                        </app-control>
                    </div>
                    <div class="col-3" *ngIf="model.owner">
                        <app-control label='Owner' [(ngModel)]="model.owner.organizationId" type="codelist"
                            codelist="Organization">
                        </app-control>
                    </div>
                    <div class="col-3">
                        <app-control label='Cargo Owner'
                            [(ngModel)]="model.serviceRequestCargoItem.serviceRequest.cargoOwner">
                        </app-control>
                    </div>

                </div>
            </div>
        </div>

        <div class="card card-border-top mt-4">
            <h4 class="card-title"> {{'CUSTOMS DATA' | translate}}</h4>
            <div class="card-body">
                <div class="row" *ngIf="model.serviceRequestCargoItem">
                    <div class="col-3">
                        <app-control label="Customs Procedure Type" [(ngModel)]="model.customsProcedureTypeId"
                            type="codelist" codelist="CustomsProcedureType"></app-control>
                    </div>
                    <div class="col-3">
                        <app-control label="Customs Procedure Number"
                            [(ngModel)]="model.customsProcedureNumber"></app-control>
                    </div>
                    <div class="col-3">
                        <app-control label="Customs Procedure Date"
                            [(ngModel)]="model.serviceRequestCargoItem.customsProcedureDate" type="datetime">
                        </app-control>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-border-top mt-4">
            <h4 class="card-title"> {{'REMARKS' | translate}}</h4>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <app-control label='Remarks' [(ngModel)]="model.remarks" type="textarea">
                        </app-control>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                    <kendo-tabstrip-tab title="{{'Stock Record Items' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab1Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Warehouse Documents' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab2Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Exit Service Request' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab3Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>
    </ng-template>
</app-loader>


<ng-template #tab1Content>
    <app-stock-record-items-grid [items]="model.items" [serviceRequest]="model.serviceRequestCargoItem?.serviceRequest" [(selection)]="selection"></app-stock-record-items-grid>
</ng-template>

<ng-template #tab2Content>
    <div class="row mt-3">
        <div class="col">
            <app-grid [data]="gridData" [isBusy]="gridDataLoading" [sortable]="true"
                [sort]="[{ field: 'id', dir: 'asc' }]">
                <kendo-grid-column title="{{'Document'|translate}}" field="id">
                    <ng-template kendoGridCellTemplate let-item>
                        <div [routerLink]="['/warehouse-document/view', item.id]">
                            <a class="text-primary link-box" target="_blank"> {{item.yearNumber}} </a>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Service Request'|translate}}">
                    <ng-template kendoGridCellTemplate let-item>
                        <div [routerLink]="['/service-request/view', item.serviceRequest.id]">
                            <a class="text-primary link-box" target="_blank"> {{item.serviceRequest.yearNumber}}
                            </a>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="type.name" title="{{'Document Type' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="serviceRequest.vesselVisit.vessel.name"
                    title="{{'Vessel Visit' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="seals" title="{{'Seals' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="serviceRequest.terminalOperator.name"
                    title="{{'Terminal Operator' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="status.name" title="{{'Status' | translate}}">
                </kendo-grid-column>
                <ng-template kendoGridDetailTemplate let-dataItem>
                    <div class="row">
                        <app-grid [data]="dataItem.items">
                            <kendo-grid-column field="cargoType.name" title="{{'Cargo Type' | translate}}">
                            </kendo-grid-column>
                            <kendo-grid-column field="packagingType.name" title="{{'Packaging Type' | translate}}">
                            </kendo-grid-column>
                            <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}">
                            </kendo-grid-column>
                            <kendo-grid-column field="grossWeight" title="{{'Gross Weight' | translate}}">
                            </kendo-grid-column>
                            <kendo-grid-column field="netWeight" title="{{'Net Weight' | translate}}">
                            </kendo-grid-column>
                            <kendo-grid-column field="warehouse.name" title="{{'Warehouse' | translate}}">
                            </kendo-grid-column>
                            <kendo-grid-column field="imdg.name" title="{{'IMDG' | translate}}">
                            </kendo-grid-column>
                        </app-grid>
                    </div>
                </ng-template>
            </app-grid>
        </div>
    </div>
</ng-template>

<ng-template #tab3Content>
    <div class="row mt-3">
        <div class="col">
            <app-grid [data]="exitServiceRequests" [sortable]="true"
                [sort]="[{ field: 'serviceRequestId', dir: 'asc' }]">
                <kendo-grid-column title="{{'Service Request'|translate}}" field="serviceRequestId">
                    <ng-template kendoGridCellTemplate let-item>
                        <div [routerLink]="['/service-request/view', item.serviceRequestId]">
                            <a class="text-primary link-box" target="_blank"> {{item.serviceRequestYearNumber}} </a>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="weight" title="{{'Weight' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="serviceRequest.source.name" title="{{'Source' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="serviceRequest.destination.name" title="{{'Destination' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column field="serviceRequest.freightForwarder.name"
                    title="{{'Freight Forwarder' | translate}}">
                </kendo-grid-column>
            </app-grid>
        </div>
    </div>
</ng-template>
