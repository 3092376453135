import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import _ from 'lodash';
import { CodelistService } from '@common/services/codelist.service';
import { ComboBoxComponent, PopupSettings } from '@progress/kendo-angular-dropdowns';
import { ActivatedRoute } from '@angular/router';
import { ViewMode } from '@common/models/view-mode';
import { environment } from '@environments/environment';
import { addDays, setHours, startOfDay } from 'date-fns';
import { FileInfo, FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { ViewEncapsulation } from '@angular/core';

export enum AppControlType {
    String = 'string',
    TextArea = 'textarea',
    Boolean = 'boolean',
    DateTime = 'datetime',
    Number = 'number',
    Password = 'password',
    CodeList = 'codelist',
    Static = 'static',
    Select = 'select',
    File = 'file',
    YesNo = 'yesno',
    Color = 'color'
}

export interface ColorPalette { [colorHex: string]: string };

@Component({
    selector: 'app-control',
    templateUrl: 'app-control.component.html',
    styleUrls: ['app-control.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: AppControlComponent, multi: true }],
    encapsulation: ViewEncapsulation.None
})

export class AppControlComponent implements OnInit, ControlValueAccessor, OnChanges {
    private _date: Date;
    AppControlType = AppControlType;
    filteredOptions = [];
    error: string;
    isBusy = false;
    codelistTake = environment.settings.appControl.codelist.take;
    private val: any;
    onChange: any = _.noop;
    isMouseEnter: boolean;
    isClicked: boolean;

    @ViewChild('combobox') public combobox: ComboBoxComponent;
    @ViewChild('tooltip', { static: false }) tooltip;

    @Input() options = [];
    @Input() label: string;
    @Input() type: AppControlType;
    @Input() ngModel: NgModel;
    @Input() isDisabled: boolean;
    @Input() codelist: string;
    @Input() codelistFilter = '';
    @Input() textField = 'label';
    @Input() multi = environment.settings.appControl.multi;
    @Input() required = false;
    @Input() filter: (item, search) => boolean;
    @Input() format: string;
    @Input() min: number;
    @Input() max: number;
    @Input() fetch: () => Promise<any[]>;
    @Input() popupSettings: Partial<PopupSettings> = environment.settings.appControl.dropdown.popupSettings;
    @Input() hasValue = false;
    @Input() placeholder: string;
    @Input() initialValue: string;
    @Input() valueField = 'value';
    @Input() time = environment.settings.appControl.time;
    @Input() timeOnly = false;
    @Input() decimals = 0;
    @Input() palette: ColorPalette = { '#EB1515': 'red', '#ECE93E': 'yellow', '#38E02F': 'green', '#F3A51E': 'orange', '#0096FF': 'blue', '#9B51E0': 'purple' };
    @Input() futureOnly = false; // Allow only dates in the future
    @Input() selectLabel = (item) => `${item.customText || item.name}`;
    @Input() modelAsDate = false;
    @Input() disableTooltip = false;
    @Input() pattern: string;
    @Input() useCache = true;
    @Input() disabledDates: (date: Date) => boolean = (date: Date) => this.futureOnly && addDays(date, 1) < new Date();
    @Input() fileRestrictions: FileRestrictions = environment.settings.appControl.fileRestrictions;

    @Output() change = new EventEmitter<any>();
    @Output() open = new EventEmitter<any>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private codelistService: CodelistService,
        private cdRef: ChangeDetectorRef) { }

    set value(value) {
        this.val = value;
        this.onChange(value);
    }
    get value() {
        return this.val;
    }

    get dateValue() {
        if (_.isDate(this.val)) {
            return this.val;
        }

        if (_.isString(this.val)) {
            const date = new Date(this.val);
            // date = new Date(date.getTime() + (date.getTimezoneOffset() * 60_000));

            if (!date || !this._date || date.getTime() !== this._date.getTime()) {
                this._date = date;
            }

            return this._date;
        }

        return this.val;
    }

    set dateValue(value) {
        this._date = value;
        this.value = _.isDate(value) ? this.modelAsDate ? value : value.toISOString() : value;
    }

    get colorHexPalette() {
        return Object.keys(this.palette || {});
    }

    get colorLabel() {
        return `${this.label}: ${this.palette?.[this.value?.toUpperCase()]}`;
    }

    async ngOnInit() {
        this.maybeApplyActive();
        this.type ||= AppControlType.String;
        this.isBusy = true;
        if (this.codelist) this.queryCodelist();

        if (this.type === AppControlType.CodeList && this.fetch && this.value) {
            this.options = this.filteredOptions = [{ code: this.value, label: this.value }];
        }

        if (this.isDisabled === undefined && (this.activatedRoute.snapshot.data &&
            this.activatedRoute.snapshot.data.mode === ViewMode.view)) {
            this.isDisabled = true;
        }

        if (this.initialValue) {
            // Execute after content has been checked
            setTimeout(() => this.value = this.initialValue);
        }

        this.isBusy = false;
    }

    ngOnChanges(changes) {
        const ngModelValue = changes?.ngModel?.currentValue;
        if (changes.ngModel) this.writeValue(ngModelValue);

        if (changes?.initialValue?.firstChange)
            this.writeValue(changes.initialValue.currentValue);

        this.error = this.required && (!this.ngModel || (this.ngModel as any).length === 0) ? `${this.label || 'Property'} is required` : null;
        if (this.tooltip) this.tooltip.ngbTooltip = this.error;

        if (changes?.options && ([AppControlType.CodeList, AppControlType.Select].includes(this.type)))
            this.filteredOptions = this.options;

        if (changes?.codelist) {
            const initialValue = changes.initialValue?.currentValue;
            this.filterCodeList(initialValue || '');
        }

        if (ngModelValue !== this.value && !this.initialValue)
            this.value = ngModelValue || this.value; // Value change is called from parent component

        this.maybeApplyActive();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
        this.val = obj;
        // To prevent showing 'undefined' string text
        if ([AppControlType.String, AppControlType.TextArea].includes(this.type) && this.value === undefined)
            setTimeout(() => this.value = null);
        this.maybeApplyActive();
    }

    async queryCodelist(filter = this.codelistFilter, useCache = this.useCache) {
        const data = await this.codelistService.getCodelist({ name: this.codelist, filter, useCache, selectedIds: this.value ? [this.value].flat() : null });
        if (!data) return;
        this.options = data.map((x: any) => ({
            label: this.selectLabel(x),
            value: x.id,
            name: x.name
        }));
        const currentValue = this.multi ? this.value?.[0] : this.value;
        this.filteredOptions = this.options.filter((x, i) => x.value === currentValue || i < this.codelistTake + 1); // +1 if selected value is not in first 100
        if (_.isFunction(this.filter)) {
            this.filteredOptions = this.filteredOptions.filter((x: any) => this.filter(x, filter));
        }
    }

    queryCodelistDebounced = _.debounce((filter, useCache) => this.queryCodelist(filter, useCache), 200);

    filterCodeList($event) {
        const searchString = $event.toLowerCase();
        if (this.type === AppControlType.CodeList) return this.queryCodelistDebounced(searchString, !searchString);

        this.filteredOptions = _.take(
            this.options.filter((o) => o.label.toLowerCase().includes(searchString || '')),
            this.codelistTake);

        if (_.isFunction(this.filter)) {
            this.filteredOptions = this.filteredOptions.filter((x: any) => this.filter(x, searchString));
        }
    }

    async onDropdownOpen() {
        if (this.fetch) {
            const data = await this.fetch();
            this.options = this.filteredOptions = data;
        }
    }

    fileSelect(event: SelectEvent) {
        event.files.forEach((file: FileInfo) => {
            this.loadFile(file, content => {
                this.value.shift();
                this.value.push({ content, name: file.name });
            });
        });
    }

    private loadFile(file: FileInfo, onLoaded: (content: string) => void) {
        if (file.validationErrors?.length) {
            return;
        }

        const reader = new FileReader();
        reader.onload = ev => {
            const str = <string>ev.target.result; // data:text/plain;base64,[CONTENT]
            onLoaded(str.substring(str.indexOf(',') + 1));
        };
        reader.readAsDataURL(file.rawFile);
    }

    public isItemSelected(val) {
        return this.value?.some(x => x === val);
    }

    maybeApplyActive() {
        this.hasValue = ![null, undefined].includes(this.value) && this.value?.length !== 0;
        this.cdRef.detectChanges();
    }

    focusInHandler(event: any) {
        this.hasValue = !!this.value || !this.isDisabled;
        this.isClicked = true;
        this.placeholder = `Select ${this.label}`;
        this.cdRef.detectChanges();
    }

    focusOutHandler(event: any) {
        this.maybeApplyActive();
        this.isClicked = false;
        this.placeholder = null;
    }

    onKeyPress(event: KeyboardEvent) {
        if (!this.pattern) return;
        if (!new RegExp(this.pattern).test(event.key)) event.preventDefault();
    }
}
