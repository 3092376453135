<app-loader [isBusy]="!model || isBusy">
    <ng-template>
        <app-action-bar [items]="actionBar" [collapsed]="false"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>

        <div class="card card-border-top">
            <div class="card-body">
                <div class="row">
                    <div class="col-4">
                        <app-control label='Username' [(ngModel)]="model.userName" [isDisabled]="model.id > 0"
                            [required]="createMode">
                        </app-control>
                    </div>
                    <div class="col-4">
                        <app-control label='Full Name' [(ngModel)]="model.fullName" [required]="true"></app-control>
                    </div>
                    <div class="col-4">
                        <app-control label='Organization' [(ngModel)]="model.organizationId" type="codelist"
                            codelist="Organization" [required]="model.id > 1"></app-control>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-4">
                        <app-control label='Email' [(ngModel)]="model.email" [required]="true"
                            pattern="[\w\d\.\-\_\@]"></app-control>
                    </div>
                    <div class="col-4">
                        <app-control label='Phone Number' [(ngModel)]="model.phoneNumber"></app-control>
                    </div>
                    <div class="col-4">
                        <app-control label='Language' [(ngModel)]="model.languageId" type="codelist" codelist="Language"
                            [required]="true"></app-control>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="editMode">
                    <div class="col-4">
                        <app-control label='Password' type="password" [(ngModel)]="model.password"
                            [required]="createMode" [ngbPopover]="passwordValidators" triggers="mouseenter:mouseleave"
                            popoverTitle="Password validators"></app-control>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #passwordValidators>
            <ul>
                <li>minimum length 8 characters</li>
                <li>at least one uppercase letter</li>
                <li>at least one number</li>
                <li>at least one special character</li>
            </ul>
        </ng-template>


        <div class="row mt-3">
            <div class="col">
                <kendo-tabstrip>
                    <kendo-tabstrip-tab title="{{'Roles'|translate}}" [selected]="true">
                        <ng-template kendoTabContent>
                            <div class="row mt-3" *ngIf="editMode">
                                <div class="col-3">
                                    <app-control #selectedRole type="codelist" codelist="Role"></app-control>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-success btn-sm float-right"
                                        (click)="addRole(selectedRole.value)"
                                        [disabled]="!selectedRole.value || roleExists(selectedRole.value)">
                                        <fa-icon icon="plus"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <app-grid [data]="model.roles" class="mb-2">
                                        <kendo-grid-column field="id" title="{{'Name'|translate}}">
                                            <ng-template kendoGridCellTemplate let-row>
                                                <span>{{row.role.name}}</span>
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column *ngIf="editMode" field="id" title="" [width]="100">
                                            <ng-template kendoGridCellTemplate let-row>
                                                <button class="btn btn-danger btn-xs" (click)="removeRole(row.role.id)">
                                                    <fa-icon icon="trash"></fa-icon>
                                                </button>
                                            </ng-template>
                                        </kendo-grid-column>
                                    </app-grid>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Effective Permissions'|translate}}">
                        <ng-template kendoTabContent>
                            <div class="row">
                                <div class="col">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item" *ngFor="let permission of permissions">
                                            {{ permission }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>

    </ng-template>
</app-loader>
