<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title="Vessel operations" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-3">
            <app-control label="Vessel" [(ngModel)]="filter.vesselId" type="codelist"
                codelist="ApprovedVessel"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Direction" [(ngModel)]="filter.directionId" type="codelist"
                codelist="DirectionOfGoods"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Agent" [(ngModel)]="filter.agentId" type="codelist"
                codelist="Organization"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Terminal Operator" [(ngModel)]="filter.terminalOperatorId" type="codelist"
                codelist="PortFacility"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <app-control label="ATA From" [(ngModel)]="filter.ataFrom" type="datetime" [time]="true"></app-control>
        </div>
        <div class="col-3">
            <app-control label="ATA To" [(ngModel)]="filter.ataTo" type="datetime" [time]="true"></app-control>

        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()" [sort]="[{ field: 'ata', dir: 'desc' }]"
            detailItems="operationModels" [detailParams]="detailParams" exportFileName="VesselOperations">
            <kendo-grid-column field="vesselName" title="{{'Vessel' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="ata" title="{{'ATA' | translate}}">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.ata | date}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="atd" title="{{'ATD' | translate}}">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.atd | date}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="quantity" title="{{'Weight' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="operationTypeName" title="{{'Operation Type' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="processingRate" title="{{'Proc. Rate' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="numberOfCranes" title="{{'Nbr Of Cranes' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="operationsStart" title="{{'Operation Start' | translate}}">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.operationsStart | date}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="operationsEnd" title="{{'Operation End' | translate}}">
                <ng-template kendoGridCellTemplate let-item>
                    <span>{{item.operationsEnd | date}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="terminalOperatorName" title="{{'Terminal' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column field="agentName" title="{{'Agent' | translate}}">
            </kendo-grid-column>

            <ng-template kendoGridDetailTemplate let-dataItem>
                <app-operations-grid [operations]="dataItem.operationModels" [showOperationType]="false "></app-operations-grid>
            </ng-template>
        </app-grid>
    </div>
</div>
