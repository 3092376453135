import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { CommonService } from '@common/services/common.service';
import { format } from 'date-fns';

@Component({
    selector: 'app-vessels-in-order',
    templateUrl: './vessels-in-order.component.html',
    styleUrls: ['./vessels-in-order.component.scss'],
    providers: [CommonService]
})
export class VesselsInOrderComponent extends BaseListComponent {
    queryName = 'VesselsInOrder';
    title = `${this.translateService.instant('Vessels in order')} (${format(new Date(), 'dd.MM.yyyy')})`;
    exportExcelCommand = 'ExportVesselsInOrder';

    breadcrumb = [
        {
            title: 'Reporting',
            icon: 'file-lines'
        },
        {
            icon: 'ship',
            title: 'Vessels in Order'
        }
    ];

    canCreateNew = () => false;
    constructor(common: CommonService) { super(common); }
}
