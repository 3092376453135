<app-loader [isBusy]="!model">
    <ng-template>
        <app-action-bar [items]="actionBar"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-4">
                        <app-control label='Username' [isDisabled]="model.id > 0" [(ngModel)]="model.userName"></app-control>
                    </div>
                    <div class="col-4">
                        <app-control label='Full Name' [(ngModel)]="model.fullName"></app-control>
                    </div>
                    <div class="col-4">
                        <app-control label='Organization' type="codelist" codelist="Organization"
                            [(ngModel)]="model.organizationId"></app-control>
                    </div>
                    <div class="col-4">
                        <app-control label='Email' pattern="[\w\d\.\-\_\@]" [(ngModel)]="model.email"></app-control>
                    </div>
                    <div class="col-4">
                        <app-control label='Phone Number' pattern="[\d]" prepend="+" [(ngModel)]="model.phoneNumber"></app-control>
                    </div>
                    <div class="col-4">
                        <app-control label='Language' type="codelist" codelist="Language"
                            [(ngModel)]="model.languageId"></app-control>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</app-loader>
