import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { ExportType } from '@common/classes/export-type';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-vessel-operations',
    templateUrl: './vessel-operations.component.html',
    styleUrls: ['./vessel-operations.component.scss'],
    providers: [CommonService]
})
export class VesselOperationsComponent extends BaseListComponent {
    queryName = 'VesselOperations';
    exportExcelCommand = 'ExportVesselOperations';
    detailParams = ['shiftId', 'shiftDate', 'cargoTypeId', 'packagingTypeId', 'quantity', 'weight', 'remarks', 'createdDate'];

    breadcrumb = [
        {
            title: 'Reporting',
            icon: 'file-lines'
        },
        {
            icon: 'ship',
            title: 'Vessels Operations'
        }
    ];

    canCreateNew = () => false;
    constructor(common: CommonService) { super(common); }

    override export(exportType: ExportType, customGrid?: any): Promise<void> {
        return Promise.resolve(this.appGrid.grid.saveAsExcel());
    }
}
