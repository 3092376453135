import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    template: `
        <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [innerHTML]="message">
        </div>
        <div class="modal-footer">
            <button class="btn btn-danger" (click)="activeModal.close(false)" translate>Don't save</button>
            <button class="btn btn-primary" (click)="activeModal.close(true)" translate>Save</button>
        </div>
    `,
})
export class ChangesDialogComponent {
    public title: string;
    public message: string;

    constructor(public activeModal: NgbActiveModal) {

    }
}
