<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div *ngIf="activeTab !== 2" class="row">
        <div class="col">
            <app-control label='Stevedore Company' type="codelist" codelist="Organization"
                [(ngModel)]="filter.freightForwarderId"></app-control>
        </div>
        <div class="col">
            <app-control label='Vessel Name' type="codelist" codelist="Vessel"
                [(ngModel)]="filter.vesselId"></app-control>
        </div>
        <div class="col">
            <app-control label='Contract Number' [(ngModel)]="filter.contractNbr"></app-control>
        </div>
        <div class="col">
            <app-control label='Type Of Goods' type="codelist" codelist="CargoType"
                [(ngModel)]="filter.cargoTypeId"></app-control>
        </div>
        <div class="col">
            <app-control label="{{activeTab === 0 ? 'Yard' : 'Warehouse'}}"
                type="codelist" codelist="Warehouse"
                [filter]="warehouseFilterOnly.bind(this)"
                [(ngModel)]="filter.warehouseId"></app-control>
        </div>
    </div>
    <div *ngIf="activeTab !== 2" class="row">
        <div class="col">
            <app-control label='Contract Date From' type="datetime" [time]="false"
                [(ngModel)]="filter.contractDateFrom"></app-control>
        </div>
        <div class="col">
            <app-control label='Contract Date To' type="datetime" [time]="false"
                [(ngModel)]="filter.contractDateTo"></app-control>
        </div>
        <div class="col">
            <app-control label='Date Of Yard Occupation From' type="datetime" [time]="false"
                [(ngModel)]="filter.createdDateFrom"></app-control>
        </div>
        <div class="col">
            <app-control label='Date Of Yard Occupation To' type="datetime" [time]="false"
                [(ngModel)]="filter.createdDateTo"></app-control>
        </div>
        <div class="col">
            <app-control label='Area (m²)' type="number" [(ngModel)]="filter.surface"></app-control>
        </div>
    </div>
    <div *ngIf="activeTab === 0" class="row">
        <div class="col">
            <app-control label='Start Date From' type="datetime" [time]="false"
                [(ngModel)]="filter.operationStartFrom"></app-control>
        </div>
        <div class="col">
            <app-control label='Start Date To' type="datetime" [time]="false"
                [(ngModel)]="filter.operationStartTo"></app-control>
        </div>
        <div class="col">
            <app-control label='Finish Date From' type="datetime" [time]="false"
                [(ngModel)]="filter.operationEndFrom"></app-control>
        </div>
        <div class="col">
            <app-control label='Finish Date To' type="datetime" [time]="false"
                [(ngModel)]="filter.operationEndTo"></app-control>
        </div>
        <div class="col"></div>
    </div>
    <div *ngIf="activeTab === 2" class="row">
        <div class="col">
            <app-control label='Tugbouat Name' type="codelist" codelist="Vessel"
                [(ngModel)]="filter.vesselId"></app-control>
        </div>
        <div class="col">
            <app-control label='Flag' type="codelist" codelist="Country"
                [(ngModel)]="filter.countryId"></app-control>
        </div>
        <div class="col">
            <app-control label='Berth' type="codelist" codelist="Berth"
                [(ngModel)]="filter.berthId"></app-control>
        </div>
        <div class="col">
            <app-control label='Contract Number' [(ngModel)]="filter.contractNbr"></app-control>
        </div>
        <div class="col">
            <app-control label='Name' [(ngModel)]="filter.ownerCompany"></app-control>
        </div>
    </div>
    <div *ngIf="activeTab === 2" class="row">
        <div class="col">
            <app-control label='Contract Start Date From' type="datetime" [time]="false"
                [(ngModel)]="filter.contractStartDateFrom"></app-control>
        </div>
        <div class="col">
            <app-control label='Contract Start Date To' type="datetime" [time]="false"
                [(ngModel)]="filter.contractStartDateTo"></app-control>
        </div>
        <div class="col">
            <app-control label='Contract End Date From' type="datetime" [time]="false"
                [(ngModel)]="filter.contractEndDateFrom"></app-control>
        </div>
        <div class="col">
            <app-control label='Contract End Date To' type="datetime" [time]="false"
                [(ngModel)]="filter.contractEndDateTo"></app-control>
        </div>
        <div class="col">
            <app-control label='Subject Representative' [(ngModel)]="filter.subjectRepresentative"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <kendo-tabstrip (tabSelect)="onTabSelect($event)">
            <kendo-tabstrip-tab title="{{'Transit Yard' | translate}}" [selected]="true">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="yardGrid"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'Transit Warehouse' | translate}}">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="yardGrid"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'Watercrafts' | translate}}">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="watercraftsGrid"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>
</div>

<ng-template #yardGrid>
    <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()">
        <kendo-grid-column field="freightForwarderName" title="{{'Stevedore' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="vesselName" title="{{'Vessel' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="warehouseName" title="{{(activeTab === 0 ? 'Yard' : 'Warehouse') | translate}}"></kendo-grid-column>
        <kendo-grid-column field="contractNbr" title="{{'Contract Nbr' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="cargoTypeName" title="{{'Type Of Goods' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="contractDate" title="{{'Contract Date' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                {{row.contractDate | date:'dd.MM.yyyy'}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="createdDate" title="{{'Date Of Yard Occupation' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                {{row.createdDate | date}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="surface" title="{{'Area (m²)' | translate}}"></kendo-grid-column>
        <kendo-grid-column *ngIf="activeTab === 0" field="operationsStart" title="{{'Start Date' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                {{row.operationsStart | date}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column *ngIf="activeTab === 0" field="operationsEnd" title="{{'Finish Date' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                {{row.operationsEnd | date}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column *ngIf="activeTab === 0" field="totalDays" title="{{'Total Days' | translate}}"></kendo-grid-column>
        <kendo-grid-column *ngIf="activeTab === 0" field="yardState" title="{{'State Of The Yard' | translate}}"></kendo-grid-column>
    </app-grid>
</ng-template>

<ng-template #watercraftsGrid>
    <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()">
        <kendo-grid-column field="vesselName" title="{{'Tugboats' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="countryName" title="{{'Flag' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="berthNames" title="{{'Berth' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="contractNbr" title="{{'Contract Number' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="contractStartDate" title="{{'Contract Start Date' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                {{row.contractStartDate | date:'dd.MM.yyyy'}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="contractEndDate" title="{{'Contract End Date' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                {{row.contractEndDate | date:'dd.MM.yyyy'}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="ownerCompany" title="{{'Owner Company' | translate}}"></kendo-grid-column>
        <kendo-grid-column field="subjectRepresentative" title="{{'Subject Representative' | translate}}"></kendo-grid-column>
    </app-grid>
</ng-template>