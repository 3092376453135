import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRouteSnapshot, ActivationStart } from '@angular/router';
import { UserService } from './common/services/user.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { SplitterComponent } from '@progress/kendo-angular-layout';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { User } from '@common/models/User';
import * as _ from 'lodash';
import { WebsocketService } from '@common/services/websocket.service';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    isAuthenticated = false;
    currentUser: User;
    @ViewChild('consoleSplitter', { static: false }) consoleSplitter: SplitterComponent;

    constructor(
        private router: Router,
        userService: UserService,
        private title: Title,
        private toastrService: ToastrService,
        private translate: TranslateService,
        public consoleWindowService: ConsoleWindowService,
        websocketService: WebsocketService) {

        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                websocketService.info(`Navigated to ${event.url}`);
                const title = this.getDeepestTitle(this.router.routerState.snapshot.root);
                this.title.setTitle(title ? `${this.translate.instant(title)} · ${environment.title}` : `${environment.title}`);
            }
        });

        router.events.pipe(filter(event =>
            event instanceof ActivationStart &&
            event.snapshot.data.permissions &&
            (!_.isArray(event.snapshot.data.permissions) || event.snapshot.data.permissions.length > 0))
        ).subscribe((event) => {
            const { permissions } = (event as ActivationStart).snapshot.data;

            if (!this.currentUser.hasPermission(permissions)) {
                console.dir(event);
                console.error('Authorization failed.', this.currentUser, permissions);
                websocketService.error(`Authorization for ${this.getResolvedUrl((event as ActivationStart).snapshot)} failed.`);
                this.toastrService.error(this.translate.instant('Unauthorized'));

                this.router.navigate(['/401'], { queryParams: { returnUrl: this.getResolvedUrl((event as ActivationStart).snapshot) } });
            }
        });

        userService.isAuthenticatedSubject.subscribe((value) => {
            this.isAuthenticated = value;
        });

        userService.currentUserSubject.subscribe((value) => {
            this.currentUser = value;

            if (this.currentUser) {
                try {
                    websocketService.info('Client connected');
                    const lang = this.currentUser.language.id.toLowerCase();
                    this.translate.use(lang);
                } catch { }
            }
        });
    }

    private getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }

    private getDeepestTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title = routeSnapshot.data ? routeSnapshot.data.title : '';

        if (routeSnapshot.firstChild) {
            title = this.getDeepestTitle(routeSnapshot.firstChild) || title;
        }

        return title;
    }

    ngAfterViewInit() {
        this.consoleWindowService.initialize(this.consoleSplitter);
    }
}
