<div class="card" style="margin-top: 30px; text-align: center;">
    <div class="card-body" style="height: 100%">
        <div class="row">
            <div class="col" style="text-align: middle">
                <img *ngIf="logo" [src]="logo" style="height: 125px"/>
                <h2 class="mt-4" *ngIf="welcomeMessage" translate>{{welcomeMessage}}</h2>
            </div>
        </div>
    </div>
</div>
