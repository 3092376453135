import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';

@Component({
    selector: 'app-vessel-visit-grid',
    templateUrl: './vessel-visit-grid.component.html',
    styleUrls: ['./vessel-visit-grid.component.css']
})
export class VesselVisitGridComponent {

    @Input() gridData: GridDataResult;
    @Output() pageChange = new EventEmitter<PageChangeEvent>();
    @ViewChild(AppGridComponent) public appGrid: AppGridComponent;
}
