<div class="modal-header">
    <h4 class="modal-title" translate>{{ options.title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelAction()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="message" translate>
        <h6> {{message}} </h6>
    </div>
    <ng-container *ngIf="options.properties?.length > 0">
        <div class="row">
            <ng-template ngFor [ngForOf]="options.properties" let-property let-i="index">
                <div class="col col-{{property.colSpan ?? '12'}}">
                    <app-control
                        [type]="property.type"
                        [(ngModel)]="model[getName(property)]"
                        [property]="getName(property)"
                        [label]="property.label"
                        [codelist]="property.codelist"
                        [time]="property.time === false ? false : true"
                        [multi]="property.multi"
                        [pattern]="property.pattern"
                        [valueField]="property.valueField || 'value'"
                        [allowedExtensions]="property.allowedExtensions"
                        [isDisabled]="!property.isDisabled ? false : property.isDisabled(model)"
                        [filter]="property.filter?.bind(this)"
                        [required]="property.required"
                        [prepend]="property.prepend"
                        [options]="property.options ?? []"
                        [disabledDates]="property.disabledDates">
                    </app-control>
                </div>
        </ng-template>
        </div>
    </ng-container>
    <ng-template #template></ng-template>
</div>
<div class="modal-footer">
    <button class="btn btn-danger" (click)="cancelAction()" translate>{{ options.cancelText || ('Cancel' | translate) }}</button>
    <button class="btn btn-primary" (click)="confirmAction()" [disabled]="!canConfirmPredicate(model)" translate>{{ options.confirmText || ('Confirm' | translate) }}</button>
</div>
