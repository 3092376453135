import { environment as base } from './environment.base';
import * as _ from 'lodash';

const baseEnv = _.cloneDeep(base);

export const environment = _.merge(baseEnv, {
    production: false,
    name: 'development',
    serverUrl: `${window.location.protocol}//${window.location.hostname}/be`,
    apiUrl: `${window.location.protocol}//${window.location.hostname}/be/api`,
    title: 'PCS Durres Development',

    settings: {
        header: {
            environmentColor: 'red'
        }
    }
});
