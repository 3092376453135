<div class="fixed-background" [ngStyle]="{'background-image': 'url(/assets/img/Durres_Banner.jpg)' }">
    <div class="login-wrap">
        <div class="login-html">
            <h1 class="main-header" translate> PCS DURRËS</h1>
            <h1 class="main-header" translate> PORT COMMUNITY SYSTEM</h1>
            <br> <br>
            <input id="tab-1" type="radio" name="tab" class="sign-in" checked><label for="tab-1" class="tab">{{'Sign In' |translate}}</label>
            <input id="tab-2" type="radio" name="tab" class="sign-up"><label for="tab-2" class="tab"></label>
            <div class="login-form">
                <div class="sign-in-htm">
                    <form *ngIf="!showChangePassword">
                        <div class="alert alert-danger" role="alert" *ngIf="errorMessage" [textContent]="errorMessage">
                        </div>
                        <div class="group">
                            <label for="user" class="label">{{'Username' |translate}}</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="text-align: center">
                                    <fa-icon icon="user"></fa-icon>
                                </span>
                                <input type="text" id="username" name="username"
                                    [ngClass]="{'isInvalid': !!errorMessage}" [(ngModel)]="username" class="input"
                                    placeholder="{{'Username' |translate}}" required autofocus>
                            </div>
                        </div>
                        <div class="group">
                            <label for="pass" class="label">{{'Password' |translate}}</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon icon="key"></fa-icon>
                                </span>
                                <input type="password" id="password" name="password"
                                    [ngClass]="{'isInvalid': !!errorMessage}" [(ngModel)]="password" class="input"
                                    placeholder="{{'Password' |translate}}" required>
                            </div>
                        </div>
                        <div class="group">
                            <input type="submit" class="button" value="{{'Sign in' |translate}}" (click)="signIn()"
                                [disabled]="isBusy || !username || !password">
                                <a class="download-link" (click)="downloadForm()">Download registration form</a>
                        </div>
                        <div class="hr"></div>
                        <div style="text-align: center;">
                            <img src="assets/img/EFINTIS.jpeg"
                                style="width: 50%; background-color: #fff;margin-top: 20px;margin-right: 20px;">
                        </div>
                    </form>
                    <form *ngIf="showChangePassword">
                        <label for="tab-1" class="tab">Potrebno je ažurirati/promijeniti lozinku.</label>
                        <div class="alert alert-danger" role="alert" *ngIf="errorMessage" [textContent]="errorMessage">
                        </div>
                        <div class="group">
                            <label for="user" class="label">{{'New password'|translate}}</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon icon="key"></fa-icon>
                                </span>
                                <input type="password" name="newPassword" [ngClass]="{'isInvalid': !!errorMessage}"
                                    [(ngModel)]="newPassword" class="input" required autofocus>
                            </div>
                        </div>
                        <div class="group">
                            <label for="pass" class="label">{{'Repeat new password'|translate}}</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon icon="key"></fa-icon>
                                </span>
                                <input type="password" name="repeatNewPassword"
                                    [ngClass]="{'isInvalid': !!errorMessage}" [(ngModel)]="repeatNewPassword"
                                    class="input" required>
                            </div>
                        </div>
                        <div class="group">
                            <input type="submit" class="button" value="{{'Change password' |translate}}"
                                (click)="changePassword()" [disabled]="isBusy || !newPassword || !repeatNewPassword">
                        </div>
                        <div class="hr"></div>
                        <div style="text-align: center;">
                            <img src="/assets/img/ADRIPASS_sm.png"
                                style="background-color: #fff;margin-top: 20px;margin-right: 20px;">
                            <img src="/assets/img/adriaticIpa_sm.jpg"
                                style="background-color: #fff;margin-top: 20px;">
                            <img src="/assets/img/interreg-italy-croatia_sm.jpg"
                                style="background-color: #fff;margin-top: 20px;margin-right: 20px;">
                            <img src="/assets/img/EU-logo_sm.png"
                                style="background-color: #fff;margin-top: 20px;">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
