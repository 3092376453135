import { CommonService } from '@common/services/common.service';
import { BaseListComponent } from '@common/classes/base-list';
import { Component, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { environment } from '@environments/environment';
import { VesselVisitGridComponent } from '@vessel-visit/components/vessel-visit-grid/vessel-visit-grid.component';
import _ from 'lodash';
import { ExportType } from '@common/classes/export-type';

@Component({
    selector: 'app-vessel-schedule',
    templateUrl: './vessel-schedule.component.html',
    styleUrls: ['./vessel-schedule.component.scss'],
    providers: [CommonService]
})
export class VesselScheduleComponent extends BaseListComponent {
    queryName = 'VesselSchedule';
    breadcrumb = [
        {
            title: 'Reporting',
            icon: 'file-lines'
        },
        {
            icon: 'calendar',
            title: 'Vessel Schedule'
        }
    ];

    activeTab = 0;
    scheduleData: any;
    dayMilliSec = 86_400_000;
    dateNow = new Date().setHours(0, 0, 0, 0);
    dateRange = [this.getDate(-3), this.getDate(3)];
    exportExcelCommand = 'ExportVesselSchedule';

    public title = (value) => new Date(value).toDateString();

    @ViewChild(VesselVisitGridComponent) visitGrid: VesselVisitGridComponent;

    constructor(protected common: CommonService) { super(common); }

    activateTab(tab: number) {
        this.activeTab = tab;
        this.search();
    }

    override canCreateNew = () => false;

    searchDebounced = _.debounce(this.search, 200);

    search() {
        this.saveFilter();
        const grid = this.visitGrid.appGrid.grid as GridComponent;
        grid.loading = true;
        const { skip, pageSize } = grid;
        const { field, dir } = (grid.sort || environment.settings.grid.sort)?.[0] || {};
        this.filter = {
            ...this.filter, skip, take: pageSize, orderBy: field, orderDirection: dir,
            activeTab: this.activeTab,
            dateFrom: new Date(this.dateRange[0]),
            dateTo: new Date(this.dateRange[1])
        };

        return this.dataQuery()
            .then(scheduleData => {
                this.scheduleData = scheduleData;
                const { results: data, inlineCount: total } = scheduleData.results;
                this.gridData = { data, total };
            })
            .catch((err) => this.toastrService.error(err.message || err))
            .finally(() => grid.loading = false);
    }

    getDate(daysToAdd: number) {
        return new Date(this.dateNow + daysToAdd * this.dayMilliSec).getTime();
    }

    override export(exportType: ExportType): Promise<void> {
        return super.export(exportType, this.visitGrid.appGrid.grid);
    }
}
