import { FormProperty } from './../dialog-form/dialog-form.component';
import { CommonService } from '@services/common.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppControlType } from '../app-control/app-control.component';
import _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { UploadActionButtonsComponent } from '../upload-action-buttons.component';
import { environment } from '@environments/environment';
import { User } from '@common/models/User';
import { VesselVisitPermissions } from '@common/classes/permissions';

@Component({
    selector: 'app-documents-grid',
    templateUrl: './documents-grid.component.html',
    styleUrls: ['./documents-grid.component.scss']
})
export class DocumentsGridComponent implements OnInit {

    @Input() model: any;
    @Input() editMode: boolean;
    @Input() showVersionColumn = false;
    @Input() isVesselVisit = false;
    @Input() collectionName = 'documents';
    @Input() user: User;
    @Input() documentTypeFilter: (documentType: any) => boolean = () => true;
    @Output() documentStatusChanged = new EventEmitter<{ documentId: number, action: 'approve' | 'reject' }>();
    data = [];
    documentTypes = [];
    availableDocumentTypes = [];

    constructor(private common: CommonService) {
    }

    async ngOnInit() {
        if (this.isVesselVisit) {
            const userOrgId = this.user.organizationId;
            const directionId = userOrgId === this.model.createdBy?.organizationId ? 'A' : userOrgId === this.model.departureAgentId ? 'D' : null;
            this.documentTypes = await this.common.cqrs.query('DocumentTypes', { directionId }) as any;
        }
        this.applyData();
    }

    async addDocuments() {
        const properties: FormProperty[] = [{
            name: 'documents',
            type: AppControlType.File,
            label: this.common.translateService.instant('Attachment'),
            multi: true,
        }];
        if (this.isVesselVisit) properties.push({
            label: 'Document Type',
            name: 'documentTypeId',
            type: AppControlType.Select,
            options: this.availableDocumentTypes,
        });
        const data = await this.common.dialogService.form(
            {
                title: 'Add Documents',
                properties,
                canConfirm: (model) => model.documents && (this.isVesselVisit ? !!model.documentTypeId : true)
            }, { size: 'md' });

        if (!data?.documents) return;
        this.model[this.collectionName].unshift(...data.documents.map(doc => ({ name: doc.name, content: doc.content, documentTypeId: data.documentTypeId })));
        this.model[this.collectionName] = _.uniqBy(this.model[this.collectionName], 'name');
        this.applyData();
    }

    removeDocument(rowId) {
        this.model[this.collectionName] = [...this.model[this.collectionName].filter(d => d.id !== rowId)];
        this.applyData();
    }

    private applyData() {
        this.data = [...this.model[this.collectionName]];
        if (this.isVesselVisit) this.availableDocumentTypes = this.documentTypes
            .filter(d => !this.data.map(doc => doc.documentTypeId).includes(d.id))
            .filter(this.documentTypeFilter)
            .map(({ id: value, name }) => ({ value, label: `${value} - ${name}` }));
    }

    protected async downloadAttachment(file: any) {
        const attachment: any = file.id > 0
            ? await firstValueFrom(this.common.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.fileId}`))
            : file;
        UploadActionButtonsComponent.preview(attachment.name, attachment.content);
    }

    canChangeDocumentStatus(): boolean {
        return !this.editMode && this.isVesselVisit && this.user?.hasPermission(VesselVisitPermissions.Documents.ChangeStatus);
    }
}
