<div class="row mt-3">
    <div class="col">

        <app-grid [data]="warehouseDocuments">
            <kendo-grid-column field="type.id" title="{{'Document Type' | translate}}">
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.type?.id | codelist:'WarehouseDocumentType' | async}}</span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="id" title="{{'Document Number' | translate}}">
                <ng-template kendoGridCellTemplate let-row>
                    <div [routerLink]="['/warehouse-document/view', row.id]" target="_blank">
                        <a class="text-primary link-box"> {{ row?.yearNumber }} </a>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="createdDate" title="{{'Warehouse Document Date' | translate}}">
                <ng-template kendoGridCellTemplate let-row>
                    <span> {{row.createdDate | date}}</span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="customsProcedureType.id" title="{{'Customs Procedure Type' | translate}}">
                <ng-template kendoGridCellTemplate let-row>
                    <span>{{row.customsProcedureType?.id | codelist:'CustomsProcedureType' | async}}</span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="customsProcedureNumber" title="{{'Customs Procedure Number' | translate}}">
            </kendo-grid-column>

            <ng-template kendoGridDetailTemplate let-dataItem>
                <div class="row">
                    <app-grid [data]="dataItem.items">

                        <kendo-grid-column field="cargoType.id" title="{{'Cargo Type' | translate}}">
                            <ng-template kendoGridCellTemplate let-row>
                                <span>{{row.cargoType?.id | codelist:'CargoType' | async}}</span>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="packagingType.id" title="{{'Packaging Type' | translate}}">
                            <ng-template kendoGridCellTemplate let-row>
                                <span>{{row.packagingType?.id | codelist:'PackagingType' | async}}</span>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}">
                        </kendo-grid-column>

                        <kendo-grid-column field="grossWeight" title="{{'Gross Weight' | translate}}">
                        </kendo-grid-column>

                        <kendo-grid-column field="netWeight" title="{{'Net Weight' | translate}}">
                        </kendo-grid-column>

                        <kendo-grid-column field="warehouse.id" title="{{'Warehouse' | translate}}">
                            <ng-template kendoGridCellTemplate let-row>
                                <span>{{row.warehouse?.id | codelist:'Warehouse' | async}}</span>
                            </ng-template>
                        </kendo-grid-column>
                    </app-grid>
                </div>
            </ng-template>
        </app-grid>




    </div>
</div>
