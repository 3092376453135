import { AdministrationPermissions } from '@common/classes/permissions';
import { CodeList, CodelistService } from '@common/services/codelist.service';
import { Component, OnInit } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { CommonService } from '@common/services/common.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-user-view',
    templateUrl: './user-view.component.html',
    styleUrls: ['./user-view.component.scss'],
    providers: [CommonService]
})
export class UserViewComponent extends BaseViewComponent implements OnInit {
    entityName = 'User';
    editPermission = AdministrationPermissions.User.Edit;
    createPermission = AdministrationPermissions.User.Create;
    roles: CodeList[];
    currentLanguageId: string;
    breadcrumb = [
        {
            title: 'Administration',
            icon: 'cog'
        },
        {
            icon: 'users',
            title: 'Users',
            route: '/administration/user/list'
        },
        {
            icon: 'user',
            title: 'User View',
        }
    ];
    override arrayProperties = ['roles'];

    constructor(common: CommonService, private codelistService: CodelistService) {
        super(common);
    }

    override async modelLoaded() {
        super.modelLoaded();
        this.currentLanguageId = this.model.languageId;
    }

    override getIdentifier = () => this.model.userName;

    async ngOnInit() {
        super.ngOnInit();
        this.roles = await this.codelistService.getCodelist({ name: 'Role' });
    }

    addRole(roleId: number) {
        this.model.roles = [
            { roleId, role: { name: this.roles.find(p => p.id === roleId).name, id: roleId } },
            ...this.model.roles
        ];
    }

    override async saveChanges() {
        if (this.model.languageId !== this.currentLanguageId) {
            await super.saveChanges();
            await this.common.userService.login(this.user.userName, null, true);
            return this.common.userService.getCurrentUser();
        }
        return super.saveChanges();
    }

    roleExists(roleId: number) {
        return this.model.roles.some(role => role.roleId === roleId);
    }

    removeRole(roleId: number) {
        this.model.roles = this.model.roles.filter(modelRole => modelRole.roleId !== roleId);
    }

    get permissions() {
        const permissions = this.model.roles.flatMap(r => r.role.permissions).map(r => r?.permission);
        return _.uniq(permissions.filter(Boolean).map(p => `${p.module}.${p.namespace}.${p.name}`));
    }
}
