import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserViewComponent } from './components/user-view/user-view.component';
import { ViewMode } from '@common/models/view-mode';
import { AdministrationPermissions } from '@common/classes/permissions';

export const USER_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: UserListComponent
    },
    {
        path: 'edit/:id',
        component: UserViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'view/:id',
        component: UserViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'create',
        component: UserViewComponent,
        data: {
            mode: ViewMode.create
        }
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(USER_ROUTES)
    ],
    exports: [],
    declarations: [
        UserListComponent,
        UserViewComponent
    ],
    providers: [],
})
export class UserModule {

}
