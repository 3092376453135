import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { PilotEditorComponent } from './components/pilot-editor/pilot-editor.component';

export const CODELIST_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'pilots',
        pathMatch: 'full'
    },
    {
        path: 'pilots',
        component: PilotEditorComponent
    },
    {
        path: '**',
        redirectTo: 'pilots'
    }
];


@NgModule({
    imports: [
        CommonModule, RouterModule.forChild(CODELIST_ROUTES)
    ],
    declarations: [PilotEditorComponent]
})
export class CodelistModule { }
