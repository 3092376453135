<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-3">
            <app-control label="Vessel Name" [(ngModel)]="filter.vesselId" type="codelist"
                codelist="ApprovedVessel"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Berth" [(ngModel)]="filter.berthId" type="codelist" codelist="Berth"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Date From" [(ngModel)]="filter.dateFrom" type="datetime"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Date To" [(ngModel)]="filter.dateTo" type="datetime"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <app-control *ngIf="activeTab === 0" label="Terminal Operator" [(ngModel)]="filter.terminalOperatorId"
                type="codelist" codelist="PortFacility"></app-control>
            <app-control *ngIf="activeTab !==0" label="Call Purpose" [(ngModel)]="filter.callPurposeId" type="codelist"
                codelist="CallPurpose"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Arrival Agency" [(ngModel)]="filter.arrivalAgencyId" type="codelist"
                codelist="Organization"></app-control>
        </div>
        <div class="col-3">
            <app-control label="Departure Agency" [(ngModel)]="filter.departureAgencyId" type="codelist"
                codelist="Organization"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <kendo-tabstrip (tabSelect)="onTabSelect($event)">
            <kendo-tabstrip-tab title="{{'Cargo Vessels'|translate}}" [selected]="true">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="shipGrid"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'Yachts'|translate}}">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="shipGrid"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'Cruises'|translate}}">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="shipGrid"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>
</div>

<ng-template #shipGrid>
    <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()"
        detailItems="cargoPlans" [detailParams]="detailParams" exportFileName="CargoVessels">
        <kendo-grid-column field="eta" title="{{'Date' | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.eta | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="vesselName" title="{{vesselNameTitle | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="countryName" title="{{'Flag' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="dwt" title="{{'DWT Tons' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="grossTonnage" title="{{'GT Ton' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="lengthOverall" title="{{'Length' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="maxDraft" title="{{'Max / Arrival Draft' | translate}}" *ngIf="activeTab === 0">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.maxDraft}} / {{row.arrivalDraft}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="maxDraft" title="{{'Max Draft' | translate}}" *ngIf="activeTab !== 0">
        </kendo-grid-column>
        <kendo-grid-column field="terminalOperatorName" title="{{'Operator' | translate}}" *ngIf="activeTab !== 0">
        </kendo-grid-column>
        <kendo-grid-column field="callPurposeName" title="{{'Call Purpose' | translate}}" *ngIf="activeTab === 0">
        </kendo-grid-column>
        <kendo-grid-column field="berthNames" title="{{'Berth' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="numberOfCranes" title="{{'EL/V' | translate}}" *ngIf="activeTab === 0">
        </kendo-grid-column>
        <kendo-grid-column field="createdByName" title="{{'Arrival Agency' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="departureAgentName" title="{{'Departure Agency' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="generalRemarks" title="{{'Remarks' | translate}}">
        </kendo-grid-column>

        <ng-template kendoGridDetailTemplate let-dataItem *ngIf="activeTab === 0">
            <div class="row">
                <app-grid [data]="dataItem.cargoPlans" [sortable]="true" [pageable]="false"
                    [sort]="[{ field: 'operationTypeName', dir: 'asc' }]">
                    <kendo-grid-column field="operationTypeName" title="{{'Process' | translate}}">
                    </kendo-grid-column>

                    <kendo-grid-column field="cargoTypeName" title="{{'Type Of Goods' | translate}}">
                    </kendo-grid-column>

                    <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}">
                    </kendo-grid-column>

                    <kendo-grid-column field="weight" title="{{'Weight' | translate}}">
                    </kendo-grid-column>
                </app-grid>
            </div>
        </ng-template>
    </app-grid>
</ng-template>
