<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-3">
            <app-control label='Vessel Name' [(ngModel)]="filter.vesselId" type="codelist"
                codelist="ApprovedVessel"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Berth' [(ngModel)]="filter.berthId" type="codelist" codelist="Berth"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Arrival Agency' [(ngModel)]="filter.arrivalAgencyId" type="codelist"
                codelist="Organization"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Departure Agency' [(ngModel)]="filter.departureAgencyId" type="codelist"
                codelist="Organization"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <app-control label='Position' [(ngModel)]="filter.positionId" type="codelist"
                codelist="Position" *ngIf="activeTab === 0"></app-control>
            <app-control label='Flag' [(ngModel)]="filter.countryId" type="codelist"
                codelist="Country" *ngIf="activeTab !== 0"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Mooring From' [(ngModel)]="filter.dateFrom" type="datetime"
                *ngIf="activeTab === 0"></app-control>
            <app-control label='Docking Date From' [(ngModel)]="filter.dateFrom" type="datetime"
                *ngIf="activeTab !== 0"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Mooring To' [(ngModel)]="filter.dateTo" type="datetime" *ngIf="activeTab === 0"></app-control>
            <app-control label='Docking Date To' [(ngModel)]="filter.dateTo" type="datetime" *ngIf="activeTab !== 0"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Leaving the pier Date' [(ngModel)]="filter.atd" type="datetime" [time]="false" *ngIf="activeTab === 0"></app-control>
            <app-control label='Departure Date' [(ngModel)]="filter.atd" type="datetime" [time]="false" *ngIf="activeTab !== 0"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <kendo-tabstrip (tabSelect)="onTabSelect($event)">
            <kendo-tabstrip-tab title="{{'Vessels' | translate}}" [selected]="true">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="planGrid"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'Yachts' | translate}}">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="planGrid"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="{{'Cruises' | translate}}">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="planGrid"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>
</div>

<ng-template #planGrid>
    <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()">
        <kendo-grid-column field="vesselName" title="{{getShipLabel() | translate}}">
        </kendo-grid-column>
        <kendo-grid-column title="{{'Flag' | translate}}" field="countryId" *ngIf="activeTab !== 0">
            <ng-template kendoGridCellTemplate let-row>
                <app-flag-icon [code]="row.countryId" codelist="Country"></app-flag-icon>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="positionName" title="{{'Position' | translate}}" *ngIf="activeTab === 0">
        </kendo-grid-column>
        <kendo-grid-column field="anchorageTime" title="{{'Anchorage Time' | translate}}" *ngIf="activeTab === 0">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.anchorageTime | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="ata" title="{{(activeTab !== 0 ? 'Docking Date' : 'Mooring') | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.ata | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="operationsEnd" title="{{'End' | translate}}" *ngIf="activeTab === 0">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.operationsEnd | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="atd" title="{{(activeTab !== 0 ? 'Departure Date' : 'Leaving The Pier') | translate}}">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.atd | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="exitTurn" title="{{'Exit Turn' | translate}}" *ngIf="activeTab === 0">
            <ng-template kendoGridCellTemplate let-row>
                <span> {{row.exitTurn | date}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="daysInPort" title="{{'Days In Port' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="berthNames" title="{{'Berth' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="createdByName" title="{{'Arrival Agency' | translate}}">
        </kendo-grid-column>
        <kendo-grid-column field="departureAgentName" title="{{'Departure Agency' | translate}}">
        </kendo-grid-column>
    </app-grid>
</ng-template>
