<div class="filter-container">
    <div *ngFor="let filter of filters; index as i" class="filter" [ngClass]="{'active' : activeIndex === i}" (click)="toggle(i)">
        <div class="icon-container">
            <fa-icon class="icon" [icon]="filter.icon"></fa-icon>
        </div>
        <div class="text">
            {{ filter.text }}
        </div>
    </div>
</div>
