<app-loader [isBusy]="!model || isBusy">
    <ng-template>
        <app-action-bar [items]="actionBar" [collapsed]="false"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>

        <div class="card card-border-top">
            <h4 class="card-title"> {{'GENERAL DATA' | translate}}</h4>
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <app-control label='Service Request Type' [(ngModel)]="model.typeId" type="codelist"
                            codelist="ServiceRequestType" [isDisabled]="true">
                        </app-control>
                    </div>
                    <div class="col-1">
                        <label class="col-form-label mb-2" translate> Status </label>
                        <span [pill]="{ status: model.statusId, module: 'ServiceRequest' }">
                            {{model.statusId | codelist:'ServiceRequestStatus' | async}}
                        </span>
                    </div>
                    <div class="col">
                        <app-control label='Source' [(ngModel)]="model.sourceId" type="codelist"
                            codelist="SourceDestination" [isDisabled]="true">
                        </app-control>
                    </div>
                    <div class="col">
                        <app-control label='Destination' [(ngModel)]="model.destinationId" type="codelist"
                            codelist="SourceDestination" [isDisabled]="true">
                        </app-control>
                    </div>
                    <div class="col">
                        <app-control label='Service Request Date' [(ngModel)]="model.serviceRequestDate"
                            type="datetime">
                        </app-control>
                    </div>
                    <div class="col">
                        <app-control label='Stevedore/Freight Forwarder' [(ngModel)]="model.freightForwarderId"
                            type="codelist" codelist="Organization"></app-control>
                    </div>
                    <div class="col" *ngIf="!isDirect">
                        <app-control label='Stevedore Equipment Used' type="boolean" [(ngModel)]="model.stevedoreEquipment"></app-control>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <div class="card card-border-top">
                    <h4 class="card-title"> {{'RELEVANT CARGO DATA' | translate}}</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <app-control label='Direction of Goods' [(ngModel)]="model.directionId" type="codelist"
                                    codelist="DirectionOfGoods" [required]="true">
                                </app-control>
                            </div>
                            <div class="col">
                                <app-control label='Importer' [(ngModel)]="model.importer">
                                </app-control>
                            </div>
                            <div class="col">
                                <app-control label='Cargo Owner' [(ngModel)]="model.cargoOwner">
                                </app-control>
                            </div>
                            <div class="col">
                                <app-control label='Contract Number' [(ngModel)]="model.contractNbr">
                                </app-control>
                            </div>
                            <div class="col">
                                <app-control label='Contract Date' type="datetime" [time]="false"
                                    [(ngModel)]="model.contractDate"></app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div [ngClass]="{ 'col': isVessel, 'col-3': !isVessel }">
                <div class="card card-border-top">
                    <h4 class="card-title"> {{'TRANSPORT' | translate}}</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <app-control label='Vehicle Description' [(ngModel)]="model.vehicleDescription">
                                </app-control>
                            </div>
                            <div class="col" *ngIf="isVessel">
                                <app-control label='Vessel Declarations' [(ngModel)]="model.vesselVisitId"
                                    type="codelist" codelist="VesselVisit" [required]="vesselVisitRequired"
                                    [useCache]="false">
                                </app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col" *ngIf="isVessel">
                <div class="card card-border-top">
                    <h4 class="card-title"> {{'VESSEL OPERATION DATA' | translate}}</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <app-control *ngIf="model.vesselVisit; else emptyStartBox" label='Operations Start' type="datetime"
                                    [time]="false" [isDisabled]="true" [(ngModel)]="model.vesselVisit.operationsStart"></app-control>
                                <ng-template #emptyStartBox>
                                    <app-control label='Operations Start' type="datetime"[time]="false" [isDisabled]="true"></app-control>
                                </ng-template>
                            </div>
                            <div class="col">
                                <app-control *ngIf="model.vesselVisit; else emptyEndBox" label='Operations End' type="datetime"
                                    [time]="false" [isDisabled]="true" [(ngModel)]="model.vesselVisit.operationsEnd"></app-control>
                                <ng-template #emptyEndBox>
                                    <app-control label='Operations End' type="datetime"[time]="false" [isDisabled]="true"></app-control>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'col': isVessel, 'col-3': !isVessel }">
                <div class="card card-border-top">
                    <h4 class="card-title"> {{'TERMINAL OPERATOR' | translate}}</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <app-control label='Terminal Operator' [(ngModel)]="model.terminalOperatorId"
                                    type="codelist" codelist="PortFacility" [required]="true">
                                </app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'col-12': isVessel, 'col-6': !isVessel }">
                <div class="card card-border-top" [ngClass]="{ 'mt-4': isVessel }">
                    <h4 class="card-title"> {{'REMARKS' | translate}}</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <app-control label='Remarks' [(ngModel)]="model.remarks" type="textarea">
                                </app-control>
                            </div>
                            <div class="col-6">
                                <app-control label='Cancellation Remarks' [(ngModel)]="model.cancelRemarks"
                                    type="textarea" [isDisabled]="true">
                                </app-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                    <kendo-tabstrip-tab title="{{'Cargo Items' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab1Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Transportation Documents' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab2Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Terminal Data' | translate}}" [cssClass]="{'hidden-tab': createMode}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab3Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Attachments' | translate}}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab4Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'Warehouse Documents' | translate}}"
                        [cssClass]="{'hidden-tab': createMode}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab5Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="{{'User Changes' | translate}}"
                        [cssClass]="{'hidden-tab': createMode}">
                        <ng-template kendoTabContent>
                            <ng-container *ngTemplateOutlet="tab6Content"></ng-container>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>
    </ng-template>
</app-loader>

<ng-template #tab1Content>
    <div class="row mt-3">
        <div class="col">
            <app-editable-grid [data]="model.cargoItems" [editMode]="editMode">
                <kendo-grid-column field="operationTypeId" title="{{'Operation Type' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="codelist" codelist="OperationType"
                            [(ngModel)]="dataItem.operationTypeId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.operationTypeId | codelist:'OperationType' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="cargoTypeId" title="{{'Cargo Type' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control type="codelist" codelist="CargoType" [required]="true"
                            [(ngModel)]="dataItem.cargoTypeId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.cargoTypeId | codelist:'CargoType' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="quantity" title="{{'Quantity' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control type="number" format="n2"
                            [(ngModel)]="dataItem.quantity"></app-control>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="weight" title="{{'Weight' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="number" format="n2"
                            [(ngModel)]="dataItem.weight"></app-control>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="packagingTypeId" title="{{'Packaging Type' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="codelist" codelist="PackagingType"
                            [(ngModel)]="dataItem.packagingTypeId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.packagingTypeId | codelist:'PackagingType' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="cargoFromId" title="{{'Cargo From' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="codelist" codelist="Port"
                            [(ngModel)]="dataItem.cargoFromId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.cargoFromId | codelist:'Port' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="cargoTo" title="{{'Cargo To' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" type="codelist" codelist="Port"
                            [(ngModel)]="dataItem.cargoToId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.cargoToId | codelist:'Port' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="imdgId" title="{{'IMDG' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control type="codelist" codelist="IMDG" [(ngModel)]="dataItem.imdgId"></app-control>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-row>
                        <span>{{row.imdgId | codelist:'IMDG' | async}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="vehicleNumber" title="{{'Vehicle Number' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [(ngModel)]="dataItem.vehicleNumber"></app-control>
                    </ng-template>
                </kendo-grid-column>

                <ng-template kendoGridDetailTemplate let-dataItem>
                    <div class="row">
                        <div class="col-3">
                            <app-control label="Customs Procedure" type="codelist" codelist="CustomsProcedureType"
                                [(ngModel)]="dataItem.customsProcedureTypeId"></app-control>
                        </div>
                        <div class="col-3">
                            <app-control label="Customs Procedure Number"
                                [(ngModel)]="dataItem.customsProcedureNumber"></app-control>
                        </div>
                        <div class="col-3">
                            <app-control label="Customs Procedure Date" type="datetime"
                                [(ngModel)]="dataItem.customsProcedureDate"></app-control>
                        </div>
                    </div>
                </ng-template>
            </app-editable-grid>
        </div>
    </div>
</ng-template>

<ng-template #tab2Content>
    <div class="row mt-3">
        <div class="col">
            <app-editable-grid [data]="model.documents" [editMode]="editMode">
                <kendo-grid-column field="type" title="{{'Type' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" [(ngModel)]="dataItem.type"></app-control>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="number" title="{{'Number' | translate}}">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                        <app-control [required]="true" [(ngModel)]="dataItem.number"></app-control>
                    </ng-template>
                </kendo-grid-column>
            </app-editable-grid>
        </div>
    </div>
</ng-template>

<ng-template #tab3Content>
    <div class="row mt-3">
        <div class="col-8">
            <app-control label="Terminal Remarks" [(ngModel)]="model.terminalRemarks" type="textarea"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col-2">
            <app-control label="Terminal Acceptance Date" [(ngModel)]="model.terminalAcceptanceDate" type="datetime"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col-2">
            <app-control label="Terminal Decline Date" [(ngModel)]="model.terminalDeclineDate" type="datetime"
                [isDisabled]="true"></app-control>
        </div>
    </div>
</ng-template>

<ng-template #tab4Content>
    <app-documents-grid [model]="model" [editMode]="editMode" collectionName="attachments"></app-documents-grid>
</ng-template>

<ng-template #tab5Content>
    <app-warehouse-documents-tab [serviceRequestId]="model.id"></app-warehouse-documents-tab>
</ng-template>

<ng-template #tab6Content>
    <div class="row mt-3">
        <div class="col">
            <app-control label='Created By' [(ngModel)]="model.createdById" type="codelist" codelist="User"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col">
            <app-control label='Created Date' [(ngModel)]="model.createdDate" type="datetime"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col">
            <app-control label='Last Modified By' [(ngModel)]="model.lastModifiedById" type="codelist" codelist="User"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col">
            <app-control label='Last Modified Date' [(ngModel)]="model.lastModifiedDate" type="datetime"
                [isDisabled]="true"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <app-control label='Cancellation Date' [(ngModel)]="model.cancellationDate" type="datetime"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Completed Date' [(ngModel)]="model.completedDate" type="datetime"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col-3" *ngIf="!isDirect">
            <app-control label='Equipment Confirmed By' [(ngModel)]="model.equipmentConfirmedById " type="codelist" codelist="User"
                [isDisabled]="true"></app-control>
        </div>
        <div class="col-3" *ngIf="!isDirect">
            <app-control label='Equipment Confirmed Date' [(ngModel)]="model.equipmentConfirmedDate" type="datetime"
                [isDisabled]="true"></app-control>
        </div>
    </div>
</ng-template>
