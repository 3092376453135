import { Component } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { ServiceRequestPermissions } from '@common/classes/permissions';
import { CommonService } from '@common/services/common.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ServiceRequestCreateComponent } from '../service-request-create/service-request-create.component';
import { StockRecordItemSelectorComponent } from './../../../stock-records/components/stock-record-item-selector/stock-record-item-selector.component';

@Component({
    selector: 'app-service-request',
    templateUrl: './service-request-list.component.html',
    styleUrls: ['./service-request-list.component.scss'],
    providers: [CommonService]
})
export class ServiceRequestListComponent extends BaseListComponent {
    queryName = 'ServiceRequests';
    override createPermission = ServiceRequestPermissions.Action.Create;

    breadcrumb = [
        {
            title: 'Cargo',
            icon: 'boxes-stacked',
        },
        {
            icon: 'boxes-stacked',
            title: 'Service Request List'
        }
    ];

    constructor(common: CommonService) { super(common); }

    override async createNew() {
        const data = await this.dialogService.open(ServiceRequestCreateComponent, {});
        if (data.typeId === 'EXPORT') {
            const stockRecordItemIds = await this.dialogService.open(StockRecordItemSelectorComponent, { size: 'xl' },
                dialogRef => dialogRef.sourceDestination = data.sourceDestination);
            if (!stockRecordItemIds?.length) return;
            const sr = await this.cqrs.command('CreateExitServiceRequest', { ...data, stockRecordItemIds }) as any;
            this.router.navigate(['/service-request/edit', sr.id]);
        } else {
            this.router.navigate(['service-request/create/'], { queryParams: { createData: JSON.stringify(data) } });
        }
    }
}
