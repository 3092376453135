import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@common/models/User';
import { DialogService } from '@services/dialog.service';
import { UserService } from '@services/user.service';
import { SignalrMessage, WebsocketService } from '@common/services/websocket.service';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AdministrationPermissions } from '@common/classes/permissions';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent  implements OnInit {
    @Output() toggleSidebar = new EventEmitter();
    user: User;

    notifications: SignalrMessage[] = [];
    environment = environment.name;
    environmentColor = environment.settings.header.environmentColor;
    title = environment.settings.header.title;
    logo = environment.settings.header.logo;

    constructor(
        private router: Router,
        private dialogService: DialogService,
        private translateService: TranslateService,
        private userService: UserService,
        websocketService: WebsocketService,
        private toastrService: ToastrService
    ) {
        userService.currentUserSubject.subscribe((user) => {
            this.user = user;
        });

        websocketService.notifications$.subscribe((notification: SignalrMessage) => {
            this.notifications = [notification, ...this.notifications];
            this.toastrService[notification.type](this.translateService.instant(notification.message));
        });
    }

    ngOnInit() {
        // if (JSON.parse(localStorage.getItem('dark'))) {
        //     document.getElementById('dark-theme-toggle').click();
        // }
        this.translateService.use(localStorage.getItem('language') || 'en');
    }

    onToggleSidebar() {
        this.toggleSidebar.emit();
    }

    login() {
        return this.router.navigate(['/login']);
    }

    logout() {
        this.dialogService.confirm(this.translateService.instant('Sign Out') as string, this.translateService.instant('Are you sure you want to sign out?') as string)
            .then((result) => {
                if (result === true) {
                    this.userService.logout(false);
                }
            });
    }

    clearNotifications() {
        this.notifications = [];
    }

    isBellVisible() {
        return false; //this.user?.hasPermission(AdministrationPermissions.Notification.view);
    }

    changeDisplayLanguage(language) {
        this.translateService.use(language);
        localStorage.setItem('language', language);
    }
}
