import { CodelistService } from '@common/services/codelist.service';
import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
    name: 'codelist'
})
export class CodelistPipe implements PipeTransform {

    constructor(private codelistService: CodelistService) {
    }

    async transform(value: string, ...args: string[]): Promise<string> {
        const codelist = args[0];
        if (_.isString(codelist)) {
            if (!value) {
                return Promise.resolve('-');
            }

            const data = await this.codelistService.getCodelist({ name: codelist, selectedIds: [value], take: 1, filter: null, useCache: true });
            return this.getText(data?.[0] || data, value, codelist, args);
        }

    }

    private getText(item: any, id: string, codelist: string, args: string[]) {
        if (!item || (item?.length === 0)) {
            if (id) console.error(`${id} not found in codelist ${codelist}`);
            return '-';
        }

        return `${item[args[1]] || item.customText || (/*`${item.code || item.id} - */`${item.name}`)}`;
    }

}
