<div class="filter-wrapper">
    <ngb-accordion activeIds="ngb-panel-0">
        <ngb-panel>
            <ng-template ngbPanelHeader let-panel>
                <app-accordion-header [panel]="panel">
                    <h4 style="margin-top: 5px">
                        <a class="nav-link active" style="color: black"> <fa-icon icon="filter" style="color: #012f57 !important" ></fa-icon> {{'Filters'|translate}}</a>
                    </h4>
                </app-accordion-header>
            </ng-template>
            <ng-template ngbPanelContent>
                <ng-content></ng-content>
                <div class="row mt-3">
                    <div class="col">
                        <div class="float-right">
                            <button class="btn btn-sm btn-danger mr-2" (click)="clearFilter.emit()">
                                <fa-icon class="ml-1" icon="trash"></fa-icon>&nbsp;<span class="btn-text"
                                    translate>Clear</span>
                            </button>
                            <button class="btn btn-sm btn-primary" (click)="search.emit()">
                                <fa-icon class="ml-1" icon="search"></fa-icon>&nbsp;<span class="btn-text"
                                    translate>Search</span>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
