<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
<app-filter (search)="search()" (clearFilter)="clearFilter()">
    <div class="row">
        <div class="col-3">
            <app-control label='Vessel' type="codelist" codelist="ApprovedVessel"
                [(ngModel)]="filter.vesselId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Berth' type="codelist" codelist="Berth"
                [(ngModel)]="filter.berthId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Docking Date From' type="datetime"
                [(ngModel)]="filter.dockingTimeFrom"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Docking Date To' type="datetime"
                [(ngModel)]="filter.dockingTimeTo"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Type Of Goods' type="codelist"
                codelist="CargoType" [(ngModel)]="filter.cargoTypeId"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Departure Date From' type="datetime"
                [(ngModel)]="filter.departureDateFrom"></app-control>
        </div>
        <div class="col-3">
            <app-control label='Departure Date To' type="datetime"
                [(ngModel)]="filter.departureDateTo"></app-control>
        </div>
    </div>
</app-filter>
<div class="card card-border-top mt-3 mb-5">
    <div class="card-body">
        <app-grid [data]="gridData" [sortable]="true" (pageChange)="search()">
            <kendo-grid-column title="{{'Vessel' | translate}}" field="vesselName"></kendo-grid-column>
            <kendo-grid-column title="{{'Berth' | translate}}" field="berthNames"></kendo-grid-column>
            <kendo-grid-column title="{{'Cargo' | translate}}" field="cargoTypeNames"></kendo-grid-column>
            <kendo-grid-column title="{{'Process' | translate}}" field="operationTypeNames"></kendo-grid-column>
            <kendo-grid-column title="{{'Docking Date' | translate}}" field="dockingTime">
                <ng-template kendoGridCellTemplate let-row>
                    {{row.dockingTime | date}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{'Departure Date' | translate}}" field="departureDate">
                <ng-template kendoGridCellTemplate let-row>
                    {{row.departureDate | date}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{'Remarks' | translate}}" field="remarks"></kendo-grid-column>
        </app-grid>
    </div>
</div>